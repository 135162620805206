<template>
  <v-card>
    <v-card-text>
      <scatter
          :chart-data="dataset"
          :chart-options="options"
          :styles="styles"
      />
    <v-select
        v-model="criterion1"
        :items="criteria"
        :item-text="'label'"
        return-object
        @change="create"
    />
    <v-select
        v-model="criterion2"
        :items="criteria"
        :item-text="'label'"
        return-object
        @change="create"
    />
    <div>
      {{ criterion1 ? criterion1.label : "" }} - {{ criterion2 ? criterion2.label : "" }}
    </div>

    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
          fab
          small
          elevation="1"
          @click="onDeleteClicked">
        <v-icon color="red">mdi-delete</v-icon>
      </v-btn>

      <v-btn
          v-if="chartIsModified"
          elevation="2"
          fab
          dark
          color="green"
          @click="onSaveClicked"
      >
        <v-icon dark>mdi-cloud-upload</v-icon>
      </v-btn>
      <v-btn v-else fab disabled elevation="0">
        <v-icon>mdi-check-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import 'chart.js/auto';
import { Scatter } from 'vue-chartjs/legacy';
import { groupBy } from 'lodash';
import { mapGetters, mapState } from 'vuex';

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'auto';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'opacity .1s ease 1s';

    const table = document.createElement('section');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

export default {
  components: { Scatter },
  name: 'EngagementChart',
  watch: {
    selectedProject() {
      this.loadRatings();
    },
    group_selected() {
      this.loadRatings();
    },
  },
  props: ['item'],
  data: () => ({
    criterion1: null,
    criterion2: null,
    dataset: {},
    styles: {
      height: '300px',
      position: 'relative',
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
    },
    chartIsModified: false,
  }),
  computed: {
    ...mapState({
      selectedProject: (state) => state.app.selectedProject,
    }),
    ...mapGetters('socioeco', ['group_selected']),
    ...mapGetters('ratings', ['criteria', 'ratings', 'partie_prenantes']),
    ...mapGetters(['defaultcolor']),
  },
  mounted() {
    this.loadRatings();
  },
  methods: {
    onSaveClicked() {
      this.chartIsModified = false;
      this.$emit('save', { criterion1: this.criterion1, criterion2: this.criterion2 });
    },
    onDeleteClicked() {
      this.$emit('delete', { criterion1: this.criterion1, criterion2: this.criterion2 });
    },
    loadRatings() {
      if (this.item) {
        this.$store.dispatch('ratings/LOAD_RATING')
          .then(() => {
            this.criterion1 = this.item.criterion1;
            this.criterion2 = this.item.criterion2;
            this.create();
            this.chartIsModified = false;
          });
      }
    },
    create() {
      if (!this.criterion1 || !this.criterion2) {
        return;
      }
      const self = this;
      const data = [];
      const byPps = groupBy(this.ratings, 'partie_prenante_id');
      for (const key in byPps) {
        const ppId = parseInt(key, 10);
        const x = byPps[key].find((c) => c.rating_criterion_id === this.criterion1.id).current_value;
        const y = byPps[key].find((c) => c.rating_criterion_id === this.criterion2.id).current_value;
        const pp = self.partie_prenantes.find((p) => p.id === ppId);
        data.push({ x, y, pp });
      }
      this.dataset = {
        datasets: [{
          label: `${this.criterion1.label} vs ${this.criterion2.label}`,
          data,
          backgroundColor: self.defaultcolor[2],
        }],
      };

      this.options = {
        ...this.options,
        interaction: {
          mode: 'nearest',
          intersect: false,
        },
        scales: {
          y: {
            title: {
              display: true,
              text: this.criterion2.label,
            },
            grid: {
              drawBorder: false,
              color(context) {
                if (context.tick.value === 5) {
                  return self.defaultcolor[0];
                }
              },
            },
          },
          x: {
            ticks: {
              autoSkip: false,
            },
            title: {
              display: true,
              text: this.criterion1.label,
            },
            grid: {
              drawBorder: false,
              color(context) {
                if (context.tick.value === 5) {
                  return self.defaultcolor[0];
                }
              },
            },
          },
        },
        plugins: {
          tooltip: {
            enabled: false,
            position: 'nearest',
            external: (context) => {
              // Tooltip Element
              const { chart, tooltip } = context;
              const tooltipEl = getOrCreateTooltip(chart);

              // Hide if no tooltip
              if (tooltip.opacity === 0) {
                tooltipEl.style.opacity = 0;
                return;
              }

              // Set Text
              if (tooltip.body) {
                const section = tooltipEl.querySelector('section');
                // Remove old children
                while (section.firstChild) {
                  section.firstChild.remove();
                }

                for (const dp of tooltip.dataPoints) {
                  const { pp } = dp.raw;
                  const linePP = document.createElement('div');
                  const link = document.createElement('a');
                  linePP.appendChild(link);
                  // Create the text node for anchor element.
                  const text = document.createTextNode(pp.nom);
                  // Append the text node to anchor element.
                  link.appendChild(text);
                  // Set the title.
                  link.title = pp.nom;
                  // Set the href property.
                  link.href = `/pp/${pp.id}`;
                  section.append(linePP);
                }
              }

              const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

              // Display, position, and set styles for font
              tooltipEl.style.opacity = 1;
              tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
              tooltipEl.style.top = `${positionY + tooltip.caretY}px`;
              tooltipEl.style.font = tooltip.options.bodyFont.string;
              tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`;
            },
          },
        },
      };
      this.chartIsModified = true;
    },
  },
};
</script>

<style scoped>

</style>
