import Vue from 'vue';
import Vuex from 'vuex';
import activites from './activites';
import audit from './audit';
import auditQuestion from './auditQuestion';
import auditResponse from './auditResponse';
import administration from './administration';
import app from './app';
import bien from './bien';
import budget from './budget';
import compensation from './compensation';
import contrat from './contrat';
import document from './document';
import engagment from './engagment';
import formulaire from './formulaire';
import loader from './loader';
import message from './message';
import module from './module';
import parcelle from './parcelle';
import pap from './pap';
import paiement from './paiement';
import project from './project';
import prices from './prices';
import ratings from './ratings';
import report from './report';
import sandbox from './sandbox';
import socioeco from './socioeco';
import user from './user';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    audit,
    auditQuestion,
    auditResponse,
    activites,
    administration,
    app,
    bien,
    budget,
    contrat,
    compensation,
    document,
    engagment,
    formulaire,
    loader,
    message,
    module,
    paiement,
    pap,
    parcelle,
    prices,
    project,
    ratings,
    report,
    sandbox,
    socioeco,
    user,
  },
});
