export const global_en = {
  colonnes: 'Columns',
  variable: 'Variable',
  affichage: 'Display',
  ajouter_un_champ: 'Add a field',
  ok: 'Ok',
  fermer: 'Close',
  annuler: 'Cancel',
  label: 'Label',
  back: 'Back',
};
