export const donnees_es = {
  ajouter_un_template: 'Añadir una modelo',
  selectionnez_votre_fichier: 'Seleccione su archivo',
  nom: 'Nombre',
  type_de_template: 'Tipo de modelo',
  fiche_de_bien: 'Ficha de la propiedad',
  contrat: 'Contrato',
  afficher_les_prix_unitaires: 'Mostrar precios por unidad',
  fichier: 'Archivo',
  ok: 'OK',
  annuler: 'Cancelar',
  formulaires: 'Formularios',
  ajouter_un_formulaire: 'Añadir un formulario',
  creation_de_formulaire: 'Creación de formularios',
  nom_du_formulaire: 'Nombre del formulario',
  type: 'Tipo',
  default: 'Por defecto',
  erreur_creation_type: 'Se ha producido un error durante la creación del tipo {type}',
  type_cree: 'El tipo {type} ha sido creado',
  templates: 'Modelos',
  matrice_de_prix: 'Matriz de precios',
  parcellaire: 'Parcela de terreno',
  template: 'Template',
  sandbox: 'Modelo',
  ajouter_une_source: 'AÑADIR UNA FUENTE',
  importer: 'Importar',
  exporter: 'Exportar',
  import_parcellaire: 'Importación de parcelas',
  impossible_de_lier_la_source: 'No se puede enlazar con la fuente',
  socioeco: 'Análisis',
  user: 'Usuario',
  password: 'Contraseña',
  api_key: 'Clave API',
  nombre_enquetes: 'Número de encuestas',
  importer_enquetes: 'IMPORTAR ENCUESTAS',
  importer_questionnaire: 'IMPORTAR EL CUESTIONARIO',
  telecharger_xform: 'DESCARGAR XFORM',
  import_parametres: 'IMPORTAR PARÁMETROS',
  taux_de_change: 'Tipos de cambio',
  revenu_par_uc: 'Ingresos por unidad',
  vente_gros_animaux_type_1: 'Venta de ganado mayor (tipo 1)',
  vente_gros_animaux_type_2: 'Venta de ganado mayor (tipo 2)',
  vente_gros_animaux_type_3: 'Venta de ganado mayor (tipo 3)',
  vente_gros_animaux_type_4: 'Venta de ganado mayor (tipo 4)',
  vente_petits_animaux: 'Venta de ganado menor',
  nombre_sous_region_geo: 'Número de subregiones geográficas',
  afficher_quantites_indemnisees: '(ES)Afficher les quantités indemnisées',
};
