export const partie_prenantes_es = {
  creation_registre: 'Creación de un registro',
  titre: 'Título',
  type: 'Tipo',
  ok: 'OK',
  annuler: 'Cancelar',
  gravites: 'Gravedades',
  ajouter_une_gravite: 'Añadir la gravedad',
  gravite: 'Gravedad',
  jours: 'Días',
  categories: 'Categorías',
  ajouter_une_categorie: 'Añadir una categoría',
  etapes: 'Pasos',
  ajouter_une_etape: 'Añadir un paso',
  nom: 'Nombre',
  etats: 'Estados',
  champs: 'Campos',
  supprimer: 'Borrar',
  affichage: '(ES)Colonnes',
  activer_colonnes_personanlisees: '(ES)Activer la personnalisation des colonnes affichées',
  suppression_report_type_warning_message: '(ES)La suppression de ce type de rapport est définitive. Soyez sûr de ce que vous faites.',
  warning: '(ES)Attention',
  options: '(ES)Options',
};
