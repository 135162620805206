export const engagement_en = {
  pouvoir: 'Power',
  pouvoir_legitime_par_position: "Legitimate power by position. Ability to make decisions that affect (or not) the company's decision",
  puissance_faible: '1 - Weakest power',
  pouvoir_complet: '10 - Full and dedicated decision-making power',
  flexibilite: 'Flexibility',
  capacite_a_etre_influence: 'Ability to be influenced by external or internal agents to assume a specific position',
  impossible_de_l_influencer: '1 - It is almost impossible to influence him',
  totalement_influencable: '10 - Is totally influenceable',
  influence: 'Influence',
  capacite_a_provoquer_changement: 'Ability of the actor to bring about a change in the attitudes of other actors',
  aucune_capacite_d_influence: '1 - No power to influence',
  capacite_d_influence_totale: '10 - His power to influence is total',
  position: 'Position',
  position_des_pp_vis_a_vis_de_l_organisation: "Stakeholders' position towards the organisation and/or its projects",
  fortement_contre: '1 - Strongly against',
  fortement_en_faveur: '10 - Strongly in favour',
  vulnerabilite: 'Vulnerability',
  vulnerabilite_acteur_aux_decisions: "Stakeholder's vulnerability to company decisions",
  moins_vulnerable: '1 - Less vulnerable',
  plus_vulnerable: '10 - More vulnerable',
  cooperation: 'Cooperation',
  degre_collaboration_des_pp_dans_le_developpement_du_projet: 'Degree of collaboration of stakeholders in the project development',
  il_n_aide_pas_du_tout: '1 - He is no help at all',
  est_un_leader: '10 - Is a visible leader in relation to the project objectives',
  impact_acteur_vis_a_vis_de_l_entreprise: "Actor's impact on the company",
  capacite_a_influencer_les_decisions: 'Ability to influence business decisions',
  ses_decisions_n_auront_aucun_impact: '1 - His decisions (even if he has power) will have no impact',
  ses_decisions_sont_traduites_en_decisions_d_entreprise: '10 - His decisions are translated into business decisions',
  impact_entreprise_vis_a_vis_de_l_acteur: "Company's impact on the stakeholder",
  capacite_a_influencer_les_decisions_des_pp_avec_les_decisions_d_entreprise: "Ability to influence stakeholders' decisions with the company's decisions",
  les_decisions_de_l_entreprise_ne_l_impactent_pas: '1 - Company decisions or projects do not impact him',
  ajouter_un_graphique: 'Add a graph',
};
