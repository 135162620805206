<template>
  <div v-if="!chartIsDisplayed" class="text-center ma-5">
    <v-btn color="primary" @click="onNewChartClicked()"
      >{{ $t('donnees.socio_eco.ajouter_un_graphique') }}</v-btn
    >
  </div>
  <div v-else>
    <bar
      v-if="selectedChartType === ChartType.BAR"
      :chart-data="formattedDataForChartjs"
      :chart-options="chartOptions"
      :styles="chartStyles"
    ></bar>

    <pie
      v-else-if="selectedChartType === ChartType.PIE"
      :chart-data="formattedDataForChartjs"
      :chart-options="chartOptions"
      :styles="chartStyles"
    ></pie>

<!--    <v-data-table-->
<!--      v-else-if="selectedChartType === ChartType.TABLE"-->
<!--      :headers="tableHeaders"-->
<!--      :items="formattedDataForDataTable"-->
<!--      show-group-by-->
<!--      :items-per-page="20"-->
<!--      class="elevation-1"-->
<!--    ></v-data-table>-->

    <v-card-actions class="ma-0">
      <v-combobox
        clearable
        outlined
        dense
        persistent-hint
        hide-details="auto"
        :label="$t('donnees.socio_eco.question_principale')"
        :items="questionsLabelAndValues"
        v-model="firstSelectedQuestion"
        :hint="firstSelectedQuestionHint"
      ></v-combobox>
    </v-card-actions>
    <v-card-actions class="ma-0">
      <v-combobox
        clearable
        outlined
        dense
        persistent-hint
        hide-details="auto"
        :label="$t('donnees.socio_eco.question_secondaire')"
        :items="questionsLabelAndValues"
        v-model="secondSelectedQuestion"
        :hint="secondSelectedQuestionHint"
      ></v-combobox>
    </v-card-actions>

    <v-card-actions>
      <v-radio-group v-model="selectedChartType" row class="mx-auto my-0">
        <v-radio :label="$t('donnees.socio_eco.bar_chart')" :value="ChartType.BAR"></v-radio>
        <v-radio :label="$t('donnees.socio_eco.pie_chart')" :value="ChartType.PIE"></v-radio>
        <v-radio
          v-if="false"
          :label="$t('donnees.socio_eco.tableau')"
          :value="ChartType.TABLE"
        ></v-radio>
      </v-radio-group>

      <v-btn fab small elevation="1" @click="onDeleteClicked">
        <v-icon color="red">mdi-delete</v-icon>
      </v-btn>

      <v-btn
        v-if="chartIsModified"
        elevation="2"
        fab
        dark
        color="green"
        :loading="chartIsUpdating"
        @click="onSaveClicked"
      >
        <v-icon dark>mdi-cloud-upload</v-icon>
      </v-btn>
      <v-btn v-else fab disabled elevation="0">
        <v-icon>mdi-check-bold</v-icon>
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import 'chart.js/auto';
import { Bar, Pie } from 'vue-chartjs/legacy';

import { ChartState } from '@/store/socioeco';
import { countBy, groupBy, intersectionWith } from 'lodash';

const colors = [
  '#3853A1',
  '#28A179',
  '#ED7B5F',
  '#EDB82F',
  '#114654',
  '#5FCEED',
  '#ab1a1a',
  '#207e3a',
  '#ff74b0',
  '#0a4cc7',
  '#4b831e',
  '#A1405F',
];

const ChartType = {
  BAR: 'bar-chart',
  PIE: 'pie-chart',
  TABLE: 'data-table',
};

const DatasetKey = {
  PARTIE_PRENANTE_ID: 'partie_prenante_id',
  QUESTION: 'question',
  REPONSE: 'reponse',
};

// const tableHeaders = [
//   { text: "Partie Prenante", value: "partie_prenante_id" },
//   { text: "Catégorie", value: "classe" },
//   { text: "Question", value: "question" },
//   { text: "Réponse", value: "reponse" },
// ];

function createQuestionDatasetFromGlobalDataset(questionValue, globalDataset) {
  const dataset = globalDataset.filter((item) => item[DatasetKey.QUESTION] === questionValue);

  //  Returns object of type:
  //  {
  //    "responseValue1": 23,
  //    "responseValue2": 12,
  //    "responseValue3": 34,
  //  }
  const counterByResponse = countBy(dataset, DatasetKey.REPONSE);

  // Extract the counter values, ex: [23, 12, 34]
  const counterValues = Object.values(counterByResponse);

  // Extract the response values, ex: ["homme", "femme", "indéfini"]
  const responseValues = Object.keys(counterByResponse);

  const datasetByResponseValues = groupBy(dataset, DatasetKey.REPONSE);

  return {
    dataset,
    label: questionValue,
    counterByResponse,
    counterValues,
    responseValues,
    datasetByResponseValues,
  };
}

/**
 * Return object of type:
 * {
 * }
 */
function createFormattedDataForChartjs(
  firstQuestionValue,
  secondQuestionValue,
  globalDataset,
) {
  if (!secondQuestionValue) {
    const firstQuestionDataset = createQuestionDatasetFromGlobalDataset(
      firstQuestionValue,
      globalDataset,
    );

    return {
      labels: firstQuestionDataset.responseValues,
      datasets: [
        {
          backgroundColor: colors,
          label: firstQuestionDataset.label,
          data: firstQuestionDataset.counterValues,
        },
      ],
    };
  }

  globalDataset = globalDataset.filter((item) => (
    item[DatasetKey.QUESTION] === firstQuestionValue
      || item[DatasetKey.QUESTION] === secondQuestionValue
  ));

  const secondQuestionDataset = createQuestionDatasetFromGlobalDataset(
    secondQuestionValue,
    globalDataset,
  );

  const firstQuestionDataset = createQuestionDatasetFromGlobalDataset(
    firstQuestionValue,
    globalDataset,
  );

  const chartDatasets = secondQuestionDataset.responseValues.map(
    (secondQuestionValue, index) => {
      const secondDataset = secondQuestionDataset.dataset.filter((item) => item[DatasetKey.REPONSE] === secondQuestionValue);

      const counterValues = firstQuestionDataset.responseValues.map(
        (firstQuestionValue) => {
          const firstDataset = firstQuestionDataset.datasetByResponseValues[firstQuestionValue];
          const dataset = intersectionWith(
            secondDataset,
            firstDataset,
            (secondItem, firstItem) => (
              secondItem[DatasetKey.PARTIE_PRENANTE_ID]
                === firstItem[DatasetKey.PARTIE_PRENANTE_ID]
            ),
          );

          return dataset.length;
        },
      );

      return {
        backgroundColor: colors[index % colors.length],
        label: secondQuestionValue,
        data: counterValues,
      };
    },
  );

  return {
    labels: firstQuestionDataset.responseValues,
    datasets: chartDatasets,
  };
}

export default {
  components: {
    Bar, Pie,
  },

  props: {
    questionsAndAnswers: Array,
    questionsLabelAndValues: Array,
    chartState: String,
    chartIndex: Number,
    chartType: {
      type: String,
      default: ChartType.BAR,
    },
    firstQuestionValue: String,
    secondQuestionValue: String,
  },

  data() {
    return {
      formattedDataForChartjs: {},
      formattedDataForDataTable: [],

      ChartType,
      chartIsInitialized: false,

      localChartState: this.chartState,
      selectedChartType: this.chartType,
      firstSelectedQuestion: undefined,
      secondSelectedQuestion: undefined,

      chartStyles: {
        height: '300px',
        position: 'relative',
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  },

  computed: {
    chartIsDisplayed() {
      return (
        this.firstQuestionValue !== undefined
        || this.secondQuestionValue !== undefined
        || this.chartIsInitialized
      );
    },

    chartIsModified() {
      return (
        this.localChartState === ChartState.MODIFIED
        || this.localChartState === ChartState.UPDATING
      );
    },

    chartIsUpdating() {
      return this.chartState === ChartState.UPDATING;
    },

    initialFirstQuestion() {
      return this.questionsLabelAndValues.find(
        (item) => item.value === this.firstQuestionValue,
      );
    },

    initialSecondQuestion() {
      return this.questionsLabelAndValues.find(
        (item) => item.value === this.secondQuestionValue,
      );
    },

    firstSelectedQuestionHint() {
      return this.firstSelectedQuestion?.hint;
    },

    secondSelectedQuestionHint() {
      return this.secondSelectedQuestion?.hint;
    },
  },

  methods: {
    onFormInputsChange() {
      if (
        this.firstQuestionValue !== this.firstSelectedQuestion?.value
        || this.secondQuestionValue !== this.secondSelectedQuestion?.value
        || this.chartType !== this.selectedChartType
      ) {
        this.localChartState = ChartState.MODIFIED;
      } else {
        this.localChartState = ChartState.UPDATED;
      }

      if (this.selectedChartType === ChartType.TABLE) {
        this.formattedDataForDataTable = [];
      } else {
        this.formattedDataForChartjs = createFormattedDataForChartjs(
          this.firstSelectedQuestion?.value,
          this.secondSelectedQuestion?.value,
          this.questionsAndAnswers,
        );
      }
    },

    onNewChartClicked() {
      this.chartIsInitialized = true;
    },

    onSaveClicked() {
      const chart = {
        index: this.chartIndex,
        firstQuestion: this.firstSelectedQuestion?.value,
        secondQuestion: this.secondSelectedQuestion?.value,
        chartType: this.selectedChartType,
      };

      this.$emit('save-chart', chart);
    },

    onDeleteClicked() {
      if (this.chartState !== ChartState.NEW) {
        this.$emit('delete-chart', { index: this.chartIndex });
      }

      this.chartIsInitialized = false;
    },
  },

  watch: {
    selectedProject() {
      this.fetchData();
    },
    initialFirstQuestion(question) {
      this.firstSelectedQuestion = question;
    },
    initialSecondQuestion(question) {
      this.secondSelectedQuestion = question;
    },
    firstSelectedQuestion() {
      this.onFormInputsChange();
    },
    secondSelectedQuestion() {
      this.onFormInputsChange();
    },
    selectedChartType() {
      this.onFormInputsChange();
    },
    chartState() {
      this.localChartState = this.chartState;
    },
  },
};
</script>
