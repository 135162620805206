<template>
  <v-snackbar :color="getColor" :value="message != null">
    {{ message }}
  </v-snackbar>
</template>
<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      type: (state) => state.message.type,
      message: (state) => state.message.message,
    }),
    getColor() {
      let color;
      switch (this.type) {
        case 'error':
          color = 'red';
          break;
        case 'success':
          color = 'green';
          break;
        case 'dev':
          color = 'brown';
          break;
        default:
          color = 'blue';
      }
      return color;
    },
  },
};
</script>
