const state = {
  computing: {},
  loading: false,
};

const getters = {};

const actions = {};

const mutations = {
  START_COMPUTE(state, module) {
    state.computing[module] = module;
    state.loading = Object.keys(state.computing).length > 0;
  },
  STOP_COMPUTE(state, module) {
    delete state.computing[module];
    state.loading = Object.keys(state.computing).length > 0;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
