<template>
  <v-sheet
      id="dropzone"
      ref="dzone"
      tabindex="0"
      title="Click to grap a file from your PC!"
      color="indigo lighten-4"
      width="100%"
      style="cursor:pointer;"
      height="200"
      class="pa-2"
  >
    <v-file-input
        type="file"
        accept="text/xml"
        style="display:none"/>
    <v-row justify="center">
      <v-icon
          v-if="!dragover"
          color="indigo darken-2"
          size="75"
      >mdi-cloud-upload-outline</v-icon>
      <v-icon
          v-if="dragover"
          color="indigo darken-2"
          size="75"
      >mdi-book-plus</v-icon>
    </v-row>
    <v-row justify="center">
      <span class="title indigo--text text--darken-2">Faites glisser les fichiers à importer ici</span>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: 'FileDrop',
  data: () => ({
    dragover: false,
  }),
  mounted() {
    const dropzone = this.$el;
    // const fileupload = this.$el.firstElementChild;
    // register listeners on your dropzone / v-sheet
    if (dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener('dragenter', (e) => {
        e.preventDefault();
        this.dragover = true;
      });
      dropzone.addEventListener('dragleave', (e) => {
        e.preventDefault();
        this.dragover = false;
      });
      dropzone.addEventListener('dragover', (e) => {
        e.preventDefault();
        this.dragover = true;
      });
      dropzone.addEventListener('drop', (e) => {
        const dragevent = e;
        const items = dragevent.dataTransfer.items;
        dragevent.preventDefault();
        this.$emit('selected', items);
        this.dragover = false;
      });
    }
  },
  methods: {

  },
};
</script>

<style scoped>

</style>
