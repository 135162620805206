export const sandbox_es = {
  dates: 'Fechas',
  exporter_donnees: 'Exportar datos',
  export_en_cours: 'Exportación en curso...',
  ok: 'Ok',
  nb_enquete: 'Número de encuestas',
  enqueteur: 'Investigador',
  annuler: 'Cancelar',
  exporter_kml: 'Exportar KML',
  sandbox: 'Sandbox',
};
