const state = {
  states: [],
};

const getters = {
  states: (state) => state.states,
  stateName: (state) => state.states.reduce((a, b) => (((a[b.id] = b.name), a)), {}),
  stateColor: (state) => state.states.reduce((a, b) => (((a[b.id] = b.color), a)), {}),
};

const actions = {
  async INIT(context) {
    const states = [
      {
        id: 'waiting_pap_choice',
        name: 'Attente des choix',
        color: '#5dacde',
        cumulColor: '#a362bf',
      },
      {
        id: 'in_progress',
        name: 'Contrat en création',
        color: '#ef4e0c',
        cumulColor: '#ef4e0c',
      },
      {
        id: 'created',
        name: 'Contrat créé',
        color: '#5dacde',
        cumulColor: '#a362bf',
      },
      {
        id: 'signed',
        name: 'Contrat signé',
        color: '#1172af',
        cumulColor: '#7f18ab',
      },
    ];
    context.commit('UPDATE_STATES', states);
  },
};

const mutations = {
  UPDATE_STATES(state, data) {
    state.states = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
