import Vue from 'vue';
import Vuetify from 'vuetify';
// import "vuetify/dist/vuetify.min.css";
// import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: '#3189A1',
        secondary: '#416C78',
        accent: '#76C5DB',
        info: '#3853A1',
        warning: '#E25F58',
        error: '#AB073C',
        success: '#1FDE9E',
      },
    },
  },
};

export default new Vuetify(opts);
