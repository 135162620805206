const state = {
  templateTypes: [
    { id: 'fiche_de_bien', label: 'administration.donnees.fiche_de_bien' },
    { id: 'contrat', label: 'administration.donnees.contrat' },
  ],
};
const getters = {
  templateTypesLabel: (state) => state.templateTypes.reduce((a, b) => (((a[b.id] = b.label), a)), {}),
};

const actions = {
  CREATE_TEMPLATE(context, data) {
    const { file, type, ...admin } = data;
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('admin', JSON.stringify(admin));
    return this.$axios
      .post(
        `/api/administration/upload/${context.rootState.app.selectedProject.id}/template/${type}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((r) => {
        context.commit('ADD_PARAMETER', r.data, { root: true });
        return r.data;
      });
  },
  UPDATE_PARAMETER(context, p) {
    const param = context.rootState.app.administrations.find((a) => a.id === p.id);
    let promise = null;
    if (param) {
      promise = this.$axios.post(`/api/administration/options/${param.id}`, {
        value: p.value,
      });
    } else {
      if (!p.project_id) {
        p.project_id = context.rootState.app.selectedProject.id;
      }
      promise = this.$axios.put('/api/administration/options', p);
    }
    return promise.then((result) => {
      context.commit('SET_PARAMETER', result.data, { root: true });
      return result.data;
    });
  },
  REMOVE_PARAMETER(context, parameter) {
    return this.$axios
      .delete(`/api/administration/options/${parameter.id}`)
      .then(() => {
        context.commit('REMOVE_PARAMETER', parameter, { root: true });
      });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
