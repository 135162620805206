export const activites_es = {
  activites: 'Actividades',
  mes_activites: 'Mis actividades',
  total: 'Total',
  en_cours: 'En curso',
  en_retard: 'Tarde',
  cloture: 'Cerrar',
  mes_objectifs: 'Mis objetivos',
  mon_budget: 'Mi presupuesto',
  activites_a_venir: 'Próximas actividades',
  activites_en_retard: 'Actividades tardías',
  aucune_activite_en_retard: 'No hay actividades atrasadas',
  plan_action: 'Plan de acción',
  fin: 'Finalizar',
  la_reponse_doit_etre_de_type_numerique: 'La respuesta debe ser numérica',
  reponse: 'Respuesta',
  non: 'No',
  oui: 'Sí',
  cible: 'Objetivo',
  fermer: 'Cerrar',
  nom: 'Nombre',
  equipe: 'Equipo',
  modification_effectuee: 'Cambio realizado',
  erreur_pendant_la_sauvegarde: 'Error durante la copia de seguridad',
  modification_sauvegardee: 'Cambio guardado',
  edit_item: 'Editar artículo',
  echec_enregistrement: 'Fallo de registro',
  creation_faite: 'Creación realizada',
  annuler: 'Cancelar',
  creation: 'Creación',
  import_success: 'Importación completada con éxito',
  responsables_manquants: 'de los funcionarios no están informados',
  dates_maquantes: 'de las fechas no se rellenan',
  validation_impossible: 'No es posible la validación',
  retour_vue_activite: 'Volver a la vista de actividades',
  import_fichier: 'Importación de archivos',
  debut: 'iniciar',
  description: 'Descripción',
  echec_cloture_audit: 'No cerrar la auditoría',
  audit_cloture: 'Su auditoría se ha completado con éxito',
  echec_creation_audit: 'No crear su auditoría',
  votre_audit_a_ete_cree: 'Su auditoría ha sido creada con éxito',
  en_creation: 'En la creación',
  question: 'Pregunta',
  question_pour_audit: 'Pregunta para la auditoría',
  type_de_question: 'Tipo de pregunta',
  ouverte: 'Abrir',
  fermee: 'Cerrado',
  la_question_ne_peut_pas_depasser_255_caracteres: 'La pregunta no puede superar los 255 caracteres',
  exporter_les_audits: 'Exportación de auditorías',
  ajout_paiement: '(ES)Ajouter un paiement',
  associer_au_budget: '(ES)Associer à un budget',
  montant: '(ES)Montant',

};
