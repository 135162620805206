export const gestion_du_par_en = {
  choix_du_template: 'Choice of template',
  templates: 'Templates',
  parcelle_n: 'Plot No.',
  superficie: 'Area',
  compensation: 'Compensation',
  etat_contrat: 'State contract',
  import_parcellaire: 'Parcel import',
  import_parcellaire_explications: 'Select a file to import containing the geometry of the plots reviewed by the cartographer. Check the presence of the sycosur_id field to make the link with the surveys.',
  fichiers: 'Files',
  selectionnez_votre_fichier: 'Select your file',
  importer: 'IMPORT',
  import_realise_avec_succes: 'Import successfully completed',
  erreur_lors_de_l_import: 'An error occurred while importing the file',
  creer_section: 'Create section',
  affecter_a_une_section: 'Assign to a section',
  nommer_la_section: 'Name the section that will be created from the selected plots',
  nom_de_la_section: 'Name of the section',
  ok: 'Ok',
  annuler: 'Cancel',
  fiche_de_synthese: 'Summary sheet',
  fiche_de_bien: 'Property sheet',
  contrat: 'Contract',
  liste_pap: 'PAP list',
  liste_de_paie: 'Payroll',
  fichier_cree: 'File created',
  erreur_creation_fichier: 'Error during file creation',
  afficher_le_parcellaire: 'Display the parcel',
  afficher_le_releve_mobile: 'View the mobile statement',
  afficher_le_centre: 'View the centre',
  afficher_la_parcelle: 'Display the plot',
  legende: 'Legend',
  sans_section: 'Without section',
  sans_pk: 'Without PK',
  sections: 'Sections',
  cartographie: 'Mapping',
  voir_les_parcelles_vides: 'See the empty plots',
  inventaire: 'Inventory',
  validation: 'Validation',
  negociation: 'Negotiation',
  contractualise: 'Contracted',
  paye: 'Paid',
  aucun: 'No',
  export_parcellaire: 'Plots',
  supprimer: 'Delete',
};
