/* eslint-disable */
import moment from 'moment';

export function addPieGraph(values, colors) {
  const dataset = { data: [], backgroundColor: [], labels: [] };
  let index = 0;
  const keys = Object.keys(values);
  keys.sort((a, b) => values[b] - values[a]);
  for (const d of keys) {
    dataset.data.push(values[d]);
    const color = colors[d] ?? colors[index++ % colors.length];
    dataset.backgroundColor.push(color);
    dataset.labels.push(d.charAt(0).toUpperCase() + d.slice(1).toLowerCase());
  }
  return {
    labels: dataset.labels,
    datasets: [dataset],
  };
}

export function addBarGraph(values, colors) {
  const datasets = {};
  const labels = [];
  let index = 0;
  for (const key1 in values) {
    labels.push(key1);
    for (const key2 in values[key1]) {
      (datasets[key2] = datasets[key2] || new Array(index).fill(0)).push(
        values[key1][key2],
      );
    }
    const diff = Object.keys(datasets).filter(
      (x) => !Object.keys(values[key1]).includes(x),
    );
    for (const dDiff of diff) {
      datasets[dDiff].push(0);
    }
    index++;
  }
  index = 0;
  return {
    labels,
    datasets: Object.entries(datasets).map(([x, y]) => ({
      label: x.charAt(0).toUpperCase() + x.slice(1).toLowerCase(),
      data: y,
      stack: 1,
      backgroundColor: colors[x] ?? colors[index++ % colors.length],
    })),
  };
}

export function getMontant(item, addOrCount) {
  return item[addOrCount];
}

export function computeInterval(
  dataCollection,
  interval,
  addOrCount,
  statuses,
) {
  // Review 10.11.2020: add a new parameter to the function: "montant" to be able to add also surfaces
  const countStrategy = addOrCount === 'montant' ? getMontant : null;
  statuses.push('errors');
  const dates = dataCollection.map((d) => moment(d.date));
  const minDate = moment.min(dates).startOf(interval);
  const maxDate = moment.max(dates).endOf(interval);
  const intervalDate = [];
  if (minDate === maxDate) {
    intervalDate.push(minDate.format('yyyy-MMM'));
    minDate.add(1, interval);
  }
  while (minDate < maxDate) {
    intervalDate.push(minDate.format('yyyy-MMM'));
    minDate.add(1, interval);
  }
  const datasets = statuses.reduce((d, l) => {
    d[l] = new Array(intervalDate.length).fill(0);
    return d;
  }, {});

  dataCollection.forEach((item) => {
    const dateStartInterval = moment(item.date)
      .startOf(interval)
      .format('yyyy-MMM');
    const indexOfInterval = intervalDate.indexOf(dateStartInterval);
    if (datasets[item.state]) {
      if (indexOfInterval === -1) {
        datasets[item.state][0] += countStrategy
          ? countStrategy(item, addOrCount)
          : 1;
      } else {
        datasets[item.state][indexOfInterval] += countStrategy
          ? countStrategy(item, addOrCount)
          : 1;
      }
    } else {
      datasets.errors[indexOfInterval] += 1;
    }
  });

  Object.values(datasets).forEach((data, index) => {
    const cumulValue = cumul(data, true);
    const cumulKey = `cumul_${Object.keys(datasets)[index]}`;
    datasets[cumulKey] = cumulValue;
  });

  const datasetCollection = {
    data: Object.keys(datasets).map((d) => datasets[d]),
    labels: intervalDate,
    keysId: Object.keys(datasets),
  };
  return datasetCollection;
}

export function cumul(data, positive = true) {
  const new_array = [];
  data.reduce((a, b, i) => (new_array[i] = positive ? a + b : a - b), 0);
  return new_array;
}

export function groupBy(xs, keyFunc) {
  return xs.reduce((rv, x) => {
    (rv[keyFunc(x)] = rv[keyFunc(x)] || []).push(x);
    return rv;
  }, {});
}

export function normalizeLastState(dataCollection) {
  if (!dataCollection) return;
  dataCollection.forEach((data) => {
    data.lastState = data.states ? data.states[data.states.length - 1] : null;
  });
  return dataCollection;
}
