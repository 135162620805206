<template>
<v-card>
  <file-drop
    @selected="mapFiles">
  </file-drop>
  <v-card>
    <v-data-table
        :headers="headers"
        :items="missings"
        :options.sync="options"
        :server-items-length="total_missing"
    >
      <template v-slot:item.actions="{ item }">
      <v-icon :color="droppedFiles[item.id] ? 'green' : 'red'" small class="mr-2">
        {{ droppedFiles[item.id] ? 'mdi-check' : 'mdi-close-thick'}}
      </v-icon>
    </template>

    </v-data-table>
    <v-card-actions class="d-flex justify-end">
      <v-btn
          :disabled="!droppedFiles || Object.keys(droppedFiles).length == 0"
          color="primary"
          @click="sendDocument"
      >
        Valider</v-btn>
    </v-card-actions>
  </v-card>
</v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { toLower } from 'lodash/string';
import FileDrop from './FileDrop';

export default {
  name: 'DocumentMissing',
  components: { FileDrop },
  watch: {
    selectedProject() {
      this.load();
    },
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },
  data: () => ({
    droppedFiles: {},
    options: {},
    headers: [
      { value: 'id', text: 'Id' },
      { value: 'name', text: 'Name' },
      { value: 'actions', text: '' },
    ],
  }),
  mounted() {
    this.load();
  },
  computed: {
    ...mapGetters('document', ['missings', 'total_missing']),
    ...mapState({
      selectedProject: (state) => state.app.selectedProject,
    }),
  },
  methods: {
    sendDocument() {
      const filter = [];
      for (const d in this.droppedFiles) {
        if (!filter.includes(this.droppedFiles[d].name)) {
          filter.push(this.droppedFiles[d].name);
          const fd = new FormData();
          fd.append('file', this.droppedFiles[d]);
          this.$store.dispatch('document/PUSH_DOCUMENT', { document_id: d, payload: fd });
        }
      }
    },
    async mapFiles(files) {
      const allFiles = await this.getFilesAsync(files);
      const result = {};
      for (let i = 0; i < allFiles.length; i += 1) {
        const documents = this.missings.filter((d) => toLower(allFiles[i].name) === toLower(d.name));
        for (const doc of documents) {
          result[doc.id] = allFiles[i];
        }
      }
      this.droppedFiles = result;
    },
    async getFilesAsync(items) {
      const files = [];
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (item.kind === 'file') {
          if (typeof item.webkitGetAsEntry === 'function') {
            const entry = item.webkitGetAsEntry();
            // eslint-disable-next-line
            const entryContent = await this.readEntryContentAsync(entry);
            files.push(...entryContent);
            // continue;
          } else {
            const file = item.getAsFile();
            if (file) {
              files.push(file);
            }
          }
        }
      }
      return files;
    },
    readEntryContentAsync(entry) {
      return new Promise((resolve) => {
        let reading = 0;
        const contents = [];
        function readReaderContent(reader) {
          reading += 1;
          reader.readEntries((entries) => {
            reading -= 1;
            for (const entry of entries) {
              // eslint-disable-next-line
              readEntry(entry);
            }
            if (reading === 0) {
              resolve(contents);
            }
          });
        }
        function readEntry(entry) {
          if (entry.isFile) {
            reading += 1;
            entry.file((file) => {
              reading -= 1;
              contents.push(file);
              if (reading === 0) {
                resolve(contents);
              }
            });
          } else if (entry.isDirectory) {
            readReaderContent(entry.createReader());
          }
        }

        readEntry(entry);
      });
    },
    load() {
      if (this.selectedProject) {
        const params = {
          ...this.options,
          project_id: this.selectedProject.id,
        };
        this.$store.dispatch(
          'document/LOAD_MISSING_DOCUMENT',
          params,
        );
      }
    },
  },
};
</script>

<style scoped>

</style>
