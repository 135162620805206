import { forceFileDownload } from '@/helper/file';

const state = {
  prices: [],
};

const getters = {};

const actions = {
  IMPORT_PRICES(context, data) {
    const project_id = data.project_id ?? context.rootState.app.selectedProject.id;
    return this.$axios.post(
      `/api/administration/import/prices/${project_id}`,
      data.form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  },
  EXPORT_PRICES(context) {
    const project_id = context.rootState.app.selectedProject.id;
    return this.$axios.get(
      `/api/administration/export/prices/${project_id}`,
      { responseType: 'arraybuffer' },
    ).then((response) => {
      forceFileDownload(response, 'export_prices.xlsx');
    });
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
