<template>
  <div>
    <div class="d-flex align-stretch flex-wrap" v-if="charts">
      <v-card class="flex-grow-1 pa-3 ma-3" style="max-width: 490px">
          <v-card-title class="label-title justify-space-between">
              <span>{{ $t('donnees.socio_eco.pyramide_age_title') }}</span>
              <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs"
                            v-on="on">mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <v-card flat class="stat-tooltip-description">{{ $t('donnees.socio_eco.pyramide_age_explanation') }}</v-card>
              </v-tooltip>
          </v-card-title>
        <v-card-subtitle class="label-info"><i>{{ $t('donnees.socio_eco.pyramide_age_description') }}</i></v-card-subtitle>
        <pyramid-age :values="pyramide" />
      </v-card>
      <v-card
        class="flex-grow-1 pa-3 ma-3"
        style="max-width: 490px"
        v-for="(chart, index) in charts"
        :key="index"
      >
        <v-card-title class="label-title justify-space-between"><span class="d-inline">{{ $t(`donnees.socio_eco.${chart.dataset.idChart}_title`) }}</span>
            <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs"
                            v-on="on">mdi-help-circle-outline
                    </v-icon>
                </template>
                <v-card flat class="stat-tooltip-description">{{ $t(`donnees.socio_eco.${chart.dataset.idChart}_explanation`) }}</v-card>
            </v-tooltip>
        </v-card-title>
        <v-card-subtitle class="label-info"><i>{{ $t(`donnees.socio_eco.${chart.dataset.idChart}_description`)}}</i></v-card-subtitle>
        <bar
          :chart-data="chart.dataset"
          :chart-options="options"
          :styles="chartStyles"
        />
      </v-card>
    </div>
  </div>
</template>

<script>
import 'chart.js/auto';
import { Bar } from 'vue-chartjs/legacy';
import { mapGetters, mapState } from 'vuex';
import PyramidAge from '../Charts/PyramidAge';

export default {
  name: 'Stats',
  props: ['choices'],
  watch: {
    group_selected() {
      this.updateGraphs();
    },
  },
  components: { Bar, PyramidAge },
  data: () => ({
    pyramide: {},
    charts: undefined,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    chartStyles: {
      height: '300px',
      position: 'relative',
    },
  }),
  computed: {
    ...mapGetters('socioeco', ['group_selected', 'stats_selected', 'headers_selected']),
    ...mapState({
      defaultcolor: (state) => state.app.defaultcolor,
    }),
  },
  methods: {
    updateGraphs() {
      this.pyramide = JSON.parse(this.stats_selected.pyramide);
      this.updateAllCharts();
    },
    updateAllCharts() {
      this.charts = [
        'rdd',
        'rde',
        'rcsi',
        'oxford',
        'isvb',
        'emploi_femmes',
      ].map((c) => ({ dataset: this.createChart(c) }));
    },
    createChart(name) {
      const tmp = this.headers_selected.filter((h) => h.record_type === name);
      const labels = JSON.parse(tmp[0].libelle);
      const values = Object.values(JSON.parse(this.stats_selected[name]));
      return {
        idChart: name,
        labels: Object.values(labels),
        datasets: [
          {
            label: name,
            backgroundColor: this.defaultcolor,
            data: values,
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.stat-tooltip-description {
    max-width: 400px;
    background-color: transparent;
    color: white;
}
</style>
