export const matrice_en = {
  bareme_de_compensation: 'Compensation scale',
  zone_de_prix: 'Price zone',
  recherche: 'Search',
  ligne_non_valides: 'Invalid lines',
  importer_matrice: 'Import matrix',
  code: 'Code',
  type: 'Type',
  libelle: 'Wording',
  prix: 'Awards',
  unite: 'Unit',
  zone: 'Zone',
  pas_de_donnees_dans_la_matrice_de_prix: 'There is no data in the price matrix',
  matrice_de_prix: 'Price matrix',
  selectionnez_fichier_excel: 'Select an excel file containing a price matrix definition. Only sheets starting with the characters M_ will be imported.',
  importer: 'Import',
  import_realise_avec_succes: 'Import successfully completed',
  erreur_lors_de_l_import: 'An error occurred while importing the file',
  fichiers: 'Files',
};
