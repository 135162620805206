<template>
  <v-card flat>
    <v-card-title class="label-title"><span class="d-inline">Répartition ISVB</span></v-card-title>
    <v-card-subtitle class="label-info"><i>Répartition des scores ISVB sur les menages</i></v-card-subtitle>
    <line-chart
        ref="linec"
        :chart-data="dataset"
        :chart-options="options"
        :styles="styles"/>
  </v-card>
</template>

<script>
import 'chart.js/auto';
import { Line as LineChart } from 'vue-chartjs/legacy';

export default {
  components: { LineChart },
  props: ['values', 'headers'],
  name: 'IsvbRepartition',
  watch: {
    values() {
      this.create();
    },
    headers() {
      this.create();
    },
  },
  mounted() {
    this.create();
  },
  data: () => ({
    dataset: {},
    styles: {
      height: '300px',
      position: 'relative',
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  }),
  methods: {
    create() {
      const data = [];
      for (const k in this.values) {
        data.push(this.values[k]);
      }
      const labels = this.headers.libelle
        ? Object.values(JSON.parse(this.headers.libelle))
        : [];
      this.dataset = {
        labels,
        datasets: [
          {
            label: 'Isvb Repartition',
            data,
            borderColor: '#416C78',
            backgroundColor: '#416C78',
            tension: 0.25,
          },
        ],
      };
      this.$refs.linec.renderChart(this.dataset, this.options);
    },
  },

};
</script>

<style scoped>

</style>
