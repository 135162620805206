<template>
    <v-row>
      <v-col>
      <v-card flat>
        <v-card-title> Mise en oeuvre</v-card-title>
        <v-card-text>
          <v-row flat class="full-width d-flex">
            <v-col cols="6" class="ma-auto justify-center"><b>Description</b></v-col>
            <v-spacer/>
            <v-col cols="1" class="recue">Effecuée</v-col>
            <v-col cols="1" class="non-recue">Non Effectuée</v-col>
          </v-row>
          <node-dashboard
              v-for="node in dashboard"
              :key="node"
              :node="node"
              :complete="'task_complete'"
              :count="'task_count'"
              :level="1"/>
        </v-card-text>
      </v-card>
      </v-col>
      <v-col>
        <v-card flat>
          <v-card-title> Delivrance au PAP</v-card-title>
          <v-card-text>
            <v-row flat class="full-width d-flex">
              <v-col cols="6" class="ma-auto justify-center"><b>Description</b></v-col>
              <v-spacer/>
              <v-col cols="1" class="recue">Recue</v-col>
              <v-col cols="1" class="non-recue">Non Recue</v-col>
            </v-row>
            <node-dashboard
                v-for="node in dashboard"
                :key="node"
                :node="node"
                :complete="'completed'"
                :count="'count'"
                :level="1"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
</template>

<script>
import { mapState } from 'vuex';
import NodeDashboard from '@/components/Dashboard/NodeDashboard';

export default {
  name: 'Activites',
  components: {
    NodeDashboard,
  },
  data: () => ({
    dashboard: [],
  }),
  watch: {
    selectedProject() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {

    load() {
      if (this.selectedProject) {
        this.axios.get(`/api/dashboard/activites/${this.selectedProject.id}`)
          .then((r) => this.dashboard = r.data);
      }
    },
  },
  computed: {
    ...mapState({
      selectedProject: (state) => state.app.selectedProject,
      colors: (state) => state.app.defaultcolor,
    }),
  },
};
</script>

<style scoped>
.non-recue {
  color: darkred;
}
.recue {
  color: darkgreen;
}
</style>
