export const partie_prenantes_fr = {
  gravites: 'Gravites',
  categories: 'Categories',
  etapes: 'Etapes',
  champs: 'Champs',
  nom: 'Nom',
  type: 'Type',
  supprimer: 'Supprimer',
  creation_registre: 'Creation Registre',
  titre: 'Titre',
  ok: 'Ok',
  annuler: 'Annuler',
  ajouter_une_gravite: 'Ajouter une gravité',
  gravite: 'Gravité',
  jours: 'Jours',
  ajouter_une_categorie: 'Ajouter une catégorie',
  ajouter_une_etape: 'Ajouter une etape',
  etats: 'Etats',
  affichage: 'Colonnes',
  activer_colonnes_personanlisees: 'Activer la personnalisation des colonnes affichées',
  suppression_report_type_warning_message: 'La suppression de ce type de rapport est définitive. Soyez sûr de ce que vous faites.',
  warning: 'Attention',
  options: 'Options',
};
