import { menu_fr } from '@/translations/menu_fr';
import { menu_en } from '@/translations/menu_en';
import { menu_es } from '@/translations/menu_es';
import { activites_fr } from '@/translations/monitoring/activites_fr';
import { dashboard_fr } from '@/translations/monitoring/dashboard_fr';
import { gestion_du_par_fr } from '@/translations/reinstallation/gestion_du_par_fr';
import { fiches_pap_fr } from '@/translations/reinstallation/fiches_pap_fr';
import { budget_previsionel_fr } from '@/translations/reinstallation/budget_previsionel_fr';
import { budget_fr } from '@/translations/reinstallation/budget_fr';
import { formulaires_fr } from '@/translations/donnees/formulaires_fr';
import { sandbox_fr } from '@/translations/donnees/sandbox_fr';
import { documentation_fr } from '@/translations/donnees/documentation_fr';
import { documentation_en } from '@/translations/donnees/documentation_en';
import { documentation_es } from '@/translations/donnees/documentation_es';
import { socio_eco_fr } from '@/translations/donnees/socio_eco_fr';
import { matrice_fr } from '@/translations/donnees/matrice_fr';
import { partie_prenantes_fr } from '@/translations/partie_prenantes/partie_prenantes_fr';
import { organisation_fr } from '@/translations/administration/organisation_fr';
import { reinstallation_fr } from '@/translations/administration/reinstallation_fr';
import { partie_prenantes_fr as admin_partie_prenantes_fr } from '@/translations/administration/partie_prenantes_fr';
import { donnees_fr } from '@/translations/administration/donnees_fr';
import { activites_en } from '@/translations/monitoring/activites_en';
import { dashboard_en } from '@/translations/monitoring/dashboard_en';
import { gestion_du_par_en } from '@/translations/reinstallation/gestion_du_par_en';
import { fiches_pap_en } from '@/translations/reinstallation/fiches_pap_en';
import { budget_previsionel_en } from '@/translations/reinstallation/budget_previsionel_en';
import { sandbox_en } from '@/translations/donnees/sandbox_en';
import { socio_eco_en } from '@/translations/donnees/socio_eco_en';
import { formulaires_en } from '@/translations/donnees/formulaires_en';
import { matrice_en } from '@/translations/donnees/matrice_en';
import { partie_prenantes_en } from '@/translations/partie_prenantes/partie_prenantes_en';
import { organisation_en } from '@/translations/administration/organisation_en';
import { reinstallation_en } from '@/translations/administration/reinstallation_en';
import { donnees_en } from '@/translations/administration/donnees_en';
import { partie_prenantes_en as admin_partie_prenantes_en } from '@/translations/administration/partie_prenantes_en';
import { activites_es } from '@/translations/monitoring/activites_es';
import { dashboard_es } from '@/translations/monitoring/dashboard_es';
import { gestion_du_par_es } from '@/translations/reinstallation/gestion_du_par_es';
import { fiches_pap_es } from '@/translations/reinstallation/fiches_pap_es';
import { budget_previsionel_es } from '@/translations/reinstallation/budget_previsionel_es';
import { formulaires_es } from '@/translations/donnees/formulaires_es';
import { sandbox_es } from '@/translations/donnees/sandbox_es';
import { socio_eco_es } from '@/translations/donnees/socio_eco_es';
import { matrice_es } from '@/translations/donnees/matrice_es';
import { partie_prenantes_es } from '@/translations/partie_prenantes/partie_prenantes_es';
import { organisation_es } from '@/translations/administration/organisation_es';
import { reinstallation_es } from '@/translations/administration/reinstallation_es';
import { partie_prenantes_es as admin_partie_prenantes_es } from '@/translations/administration/partie_prenantes_es';
import { donnees_es } from '@/translations/administration/donnees_es';
import { datatable_en } from '@/translations/datatable_en';
import { datatable_fr } from '@/translations/datatable_fr';
import { datatable_es } from '@/translations/datatable_es';
import { global_en } from '@/translations/administration/global_en';
import { global_fr } from '@/translations/administration/global_fr';
import { global_es } from '@/translations/administration/global_es';
import { login_en } from '@/translations/login_en';
import { login_fr } from '@/translations/login_fr';
import { login_es } from '@/translations/login_es';
import { forgot_your_password_en } from '@/translations/forgot_your_password_en';
import { forgot_your_password_fr } from '@/translations/forgot_your_password_fr';
import { forgot_your_password_es } from '@/translations/forgot_your_password_es';
import { database_en } from '@/translations/database_en';
import { database_fr } from '@/translations/database_fr';
import { database_es } from '@/translations/database_es';
import { engagement_es } from '@/translations/donnees/engagement_es';
import { engagement_en } from '@/translations/donnees/engagement_en';
import { engagement_fr } from '@/translations/donnees/engagement_fr';
import { states_fr } from '@/translations/partie_prenantes/states_fr';
import { states_en } from '@/translations/partie_prenantes/states_en';
import { monitoring_fr } from '@/translations/administration/monitoring_fr';
import { monitoring_en } from '@/translations/administration/monitoring_en';
import { monitoring_es } from '@/translations/administration/monitoring_es';
import { budget_es } from '@/translations/reinstallation/budget_es';
import { budget_en } from '@/translations/reinstallation/budget_en';

export const messages = {
  en: {
    ...database_en,
    menu: menu_en,
    login: login_en,
    forgot_your_password: forgot_your_password_en,
    datatable: datatable_en,
    monitoring: {
      activites: activites_en,
      dashboard: dashboard_en,
    },
    reinstallation: {
      gestion_du_par: gestion_du_par_en,
      fiches_pap: fiches_pap_en,
      budget_previsionel: budget_previsionel_en,
      budget: budget_en,
    },
    donnees: {
      engagement: engagement_en,
      sandbox: sandbox_en,
      documentation: documentation_en,
      socio_eco: socio_eco_en,
      formulaires: formulaires_en,
      matrice: matrice_en,
    },
    partie_prenantes: {
      ...partie_prenantes_en,
      states: states_en,
    },
    administration: {
      organisation: organisation_en,
      reinstallation: reinstallation_en,
      donnees: donnees_en,
      partie_prenantes: admin_partie_prenantes_en,
      global: global_en,
      monitoring: monitoring_en,
    },
  },
  fr: {
    menu: menu_fr,
    login: login_fr,
    forgot_your_password: forgot_your_password_fr,
    datatable: datatable_fr,
    ...database_fr,
    monitoring: {
      activites: activites_fr,
      dashboard: dashboard_fr,
    },
    reinstallation: {
      gestion_du_par: gestion_du_par_fr,
      fiches_pap: fiches_pap_fr,
      budget_previsionel: budget_previsionel_fr,
      budget: budget_fr,
    },
    donnees: {
      engagement: engagement_fr,
      sandbox: sandbox_fr,
      documentation: documentation_fr,
      socio_eco: socio_eco_fr,
      formulaires: formulaires_fr,
      matrice: matrice_fr,
    },
    partie_prenantes: {
      ...partie_prenantes_fr,
      states: states_fr,
    },
    administration: {
      organisation: organisation_fr,
      reinstallation: reinstallation_fr,
      donnees: donnees_fr,
      partie_prenantes: admin_partie_prenantes_fr,
      global: global_fr,
      monitoring: monitoring_fr,
    },
  },
  es: {
    menu: menu_es,
    login: login_es,
    forgot_your_password: forgot_your_password_es,
    datatable: datatable_es,
    ...database_es,
    monitoring: {
      activites: activites_es,
      dashboard: dashboard_es,
    },
    reinstallation: {
      gestion_du_par: gestion_du_par_es,
      fiches_pap: fiches_pap_es,
      budget_previsionel: budget_previsionel_es,
      budget: budget_es,
    },
    donnees: {
      engagement: engagement_es,
      sandbox: sandbox_es,
      documentation: documentation_es,
      socio_eco: socio_eco_es,
      formulaires: formulaires_es,
      matrice: matrice_es,
    },
    partie_prenantes: partie_prenantes_es,
    administration: {
      organisation: organisation_es,
      reinstallation: reinstallation_es,
      donnees: donnees_es,
      partie_prenantes: admin_partie_prenantes_es,
      global: global_es,
      monitoring: monitoring_es,
    },
  },
};
