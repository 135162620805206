const state = {
  ratings: null,
  criteria: [],
  partie_prenantes: [],
};

const getters = {
  criteria: (state) => state.criteria,
  ratings: (state) => state.ratings,
  partie_prenantes: (state) => state.partie_prenantes,
};

const actions = {
  LOAD_RATING(context) {
    const pCriteria = this.$axios
      .get(`/api/rating_criteria/all/${context.rootState.app.selectedProject.id}`)
      .then((r) => context.commit('SET_CRITERIA', r.data));
    const pRatings = this.$axios
      .get(`/api/ratings/all/${context.rootState.app.selectedProject.id}`)
      .then((r) => {
        context.commit('SET_RATINGS', r.data.ratings);
        context.commit('SET_PARTIE_PRENANTES', r.data.partie_prenantes);
        return r;
      });
    return Promise.all([pCriteria, pRatings]);
  },
};

const mutations = {
  SET_CRITERIA(state, criteria) {
    state.criteria = criteria;
  },
  SET_RATINGS(state, ratings) {
    state.ratings = ratings;
  },
  SET_PARTIE_PRENANTES(state, pps) {
    state.partie_prenantes = pps;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
