<template>

  <div class="d-flex flex-column full-height">

    <div v-if="projects.length > 1">
      <p class="ma-2 text-center white--text">
        {{ $t('menu.operation') }}
      </p>

      <v-select
        dense
        :value="selectedProject"
        @change="updateProject"
        :items="projects"
        :return-object="true"
        item-value="id"
        item-text="name"
        placeholder="Selection de l'operation"
      />
    </div>

    <v-divider />

    <v-list dense v-if="menuList">
      <div v-for="menu in menuList" :key="menu.label">

        <v-subheader class="subHeader-drawer">
          <v-icon left>{{ menu.icon }}</v-icon>
          {{ $t(menu.label) }}
        </v-subheader>

        <v-list-item
          v-for="submenu in menu.mods"
          :class="{'selected' : menuItem === submenu.path , 'pl-10' : true }"
          :key="submenu.label"
          @click="goTo(submenu)"
          :input-value="menuItem === menu.path"
        >
          <v-list-item-content>
            <v-list-item-title v-text="$t(submenu.label)"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider :inset="inset"></v-divider>
      </div>
      <v-divider :inset="inset"></v-divider>
    </v-list>

    <v-spacer />

    <span class="caption white--text mr-4 mb-2 d-flex justify-end">
      <i>Version : {{ version }}</i>
    </span>

  </div>

</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  data: () => ({
    inset: false,
    menuItem: '',
  }),
  watch: {
    $route() {
      this.menuItem = this.$route.path;
    },
  },
  mounted() {
    this.menuItem = this.$route.path;
  },
  computed: {
    ...mapState({
      user: (state) => state.app.user,
      menuList: (state) => state.app.menu,
      projects: (state) => state.app.projects,
      selectedProject: (state) => state.app.selectedProject,
      report_types: (state) => state.report.report_types,
    }),
    ...mapGetters({
      formulaires: 'formulaire/formulairesByProjectMenu',
    }),
    version() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    updateProject(value) {
      this.$store.dispatch('UPDATE_PROJECT', value);
      this.$store.dispatch('UPDATE_MENU', value);
    },
    goTo(menu) {
      this.$router.push(menu.path);
    },
  },
};
</script>

<style>
.v-divider {
  border-color:#416C78 !important;
}
.full-height {
  height:100%;
}
.subHeader-drawer {
  font-size:1.1rem !important;
  color:white !important;
  font-weight:600 !important;
}
</style>
