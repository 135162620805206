<template>
<v-card v-if="group_selected">
  <v-card flat class="d-flex justify-space-around">
    <population-main-indicators class="justify-center" style="max-width: 50%"/>
    <isvb-repartition
        ref="isvbr"
        :values="isvb" :headers="isvb_headers"  style="max-width: 50%" />
  </v-card>
    <v-card flat>
      <v-card-title class="d-flex justify-center  full-width">
        <span class="label-title">
          Importance de chaque indicateurs danns la composition de l'ISV
        </span>
      </v-card-title>
      <v-card-text  class="d-flex justify-space-around">
        <isv-phi-chart :phi="phi" :indicators="indicators" style="max-width: 450px">
          <template v-slot:title>
            <span class="label-info text-center full-width"><i>Pour la population totale (phi)</i></span>
          </template>
        </isv-phi-chart>
        <isv-phi-chart  :phi="phik" :indicators="indicators" style="max-width: 450px">
          <template v-slot:title>
            <span class="label-info text-center full-width"><i>Pour la population vulnérable (phi(k))</i></span>
          </template>
        </isv-phi-chart>
      </v-card-text>
    </v-card>
</v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import IsvPhiChart from '../Charts/IsvPhiChart';
import IsvbRepartition from '../Charts/IsvbRepartition';

import PopulationMainIndicators from './PopulationMainIndicators';

export default {
  name: 'Isvb',
  components: { PopulationMainIndicators, IsvPhiChart, IsvbRepartition },
  watch: {
    group_selected() {
      this.updateGraphs();
    },
  },
  computed: {
    ...mapGetters('socioeco', ['group_selected', 'stats_selected', 'headers_selected']),
  },
  mounted() {
    this.$nextTick(function () {
      this.updateGraphs();
    });
  },
  methods: {
    updateGraphs() {
      const isvbResult = this.stats_selected.isvb_result;
      if (isvbResult) {
        this.indicators = isvbResult.indicators;
        this.phi = isvbResult.phi;
        this.phik = isvbResult.phik;
      }
      if (this.stats_selected.isvb) {
        this.isvb = JSON.parse(this.stats_selected.isvb);
        this.isvb_headers = this.headers_selected.find((h) => h.record_type === 'isvb');
      }
    },
  },
  data: () => ({
    phi: {},
    phik: {},
    indicators: {},
    isvb_headers: {},
    isvb: {},
  }),
};
</script>

<style scoped>

</style>
