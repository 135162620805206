export const global_es = {
  colonnes: 'Columnas',
  variable: 'Variable',
  affichage: 'Mostrar',
  ajouter_un_champ: 'Añadir un campo',
  ok: 'Ok',
  fermer: 'Cerrar',
  annuler: 'Cancelar',
  label: 'Etiqueta',
  back: '(ES)Retour',
};
