<template>
  <app-logged>
    <v-container fluid>

      <portal to="toolbarTitle">
          <v-icon color="white" large left>mdi-chart-pie</v-icon>
          {{ $t('donnees.documentation.documentation') }}
      </portal>
      <v-tabs v-model="tab">
        <v-tab to="#signfdb">Signature</v-tab>
        <v-tab to="#project">Projet</v-tab>
        <v-tab to="#missing">Manquants</v-tab>
      </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item value="project">
        <document-liste></document-liste>
      </v-tab-item>
      <v-tab-item value="missing">
        <document-missing></document-missing>
      </v-tab-item>
      <v-tab-item value="signfdb">
        <mass-fdb-sign></mass-fdb-sign>
      </v-tab-item>
    </v-tabs-items>

    </v-container>
  </app-logged>
</template>
<script>

import DocumentListe from '../components/Documentation/DocumentListe';
import DocumentMissing from '../components/Documentation/DocumentMissing';
import MassFdbSign from '../components/Documentation/MassFdbSign';

export default {
  components: { MassFdbSign, DocumentMissing, DocumentListe },
  data: () => ({
    tab: 'project',
  }),
};
</script>
<style>

</style>
