<template>
  <v-card tile>
    <v-card-text class="d-flex">
      <v-card
          class="file-card ma-2 d-flex flex-column"
          v-for="document in documents"
          :key="document.id"
      >
        <v-card-title>
          {{ document.name }}
        </v-card-title>
        <v-card-subtitle>{{ document.created_at | date_format }}</v-card-subtitle>
        <v-card-text class="ma-auto d-flex">
          <v-icon
              x-large
              class="justify-self-center"
              color="primary"
              @click="download(document)"
          >mdi-file</v-icon>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="error"
              @click="deleteDocument(document.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card
          class="file-card ma-2 d-flex"
          v-if="!enableAddDocument">
        <v-card-text
            class="align-self-center justify-center">
          <v-btn
              color="primary"
              tile
              @click="enableAddDocument = !enableAddDocument"
          >
            {{ $t('donnees.documentation.add_document') }}
          </v-btn>
        </v-card-text>
      </v-card>
      <v-card
          class="file-card ma-2"
          v-if="enableAddDocument">
        <v-card-text>
          <v-file-input
              v-model="file"
              dense
              :label="$t('donnees.documentation.fichier')"
              :placeholder="$t('donnees.documentation.select_file')"
              prepend-icon="mdi-paperclip"
              outlined
              :show-size="1000"></v-file-input>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              color="primary"
              tile
              @click="addDocument"
          > VALIDER</v-btn>
          <v-btn
              color="error"
              tile
              @click="enableAddDocument = !enableAddDocument"
          > ANNULER</v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { forceFileDownload } from '../../helper/file';
import { i18n } from '../../main';

export default {
  name: 'DocumentListe',
  data: () => ({
    enableAddDocument: false,
    file: null,
  }),
  watch: {
    selectedProject() {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  computed: {
    ...mapState({
      selectedProject: (state) => state.app.selectedProject,
      documents: (state) => state.project.documents,
    }),
  },
  methods: {
    download(document) {
      this.axios({
        method: 'get',
        url: `/api/document/${document.id}`,
        responseType: 'arraybuffer',
      })
        .then((response) => {
          forceFileDownload(response, document.name);
        })
        .catch(() => {
          this.$store.dispatch('FIRE_ERROR', i18n.t('donnees.documentation.error_access_file'));
        });
    },
    load() {
      if (this.selectedProject) {
        this.$store.dispatch('project/LOAD_DOCUMENTS', this.selectedProject.id);
      }
    },
    addDocument() {
      this.$store.dispatch('project/ADD_DOCUMENT', {
        file: this.file,
        project_id: this.selectedProject.id,
      }).then(() => {
        this.enableAddDocument = !this.enableAddDocument;
      });
    },
    deleteDocument(document_id) {
      this.$store.dispatch('project/REMOVE_DOCUMENT', document_id);
    },
  },
};
</script>

<style scoped>
.file-card {
  width: 250px;
  height: 250px;
  max-width: 250px;
  max-height: 250px;

}
</style>
