import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import VueAxios from 'vue-axios';
import VueI18n from 'vue-i18n';
import { messages } from '@/translations/messages';
import PortalVue from 'portal-vue';
import { merge } from 'lodash';
import App from './App';
import router from './router';
import store from './store';
import axios from './services/axios';
import './services/numeral';
import './services/common-filters';

import AppLogged from './layouts/AppLogged';
import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(require('vue-moment'));

Vue.use(VueI18n);
Vue.use(PortalVue);

Vue.component('app-logged', AppLogged);

store.$axios = axios;

export const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
});

i18n.overrideLanguage = function (values) {
  const updated = merge(messages, values);
  for (const lang in values) {
    i18n.setLocaleMessage(lang, updated[lang]);
  }
};

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
