/* eslint-disable */
export function traverseTree(node, callback) {
  for (const n of node.children) {
    callback(n);
    traverseTree(n, callback);
  }
}

export function recursiveFind(node, id) {
  if (node.id === id) {
    return node;
  } if (node.children && node.children.length > 0) {
    let i = 0;
    let elt = null;
    do {
      elt = recursiveFind(node.children[i++], id);
      if (elt) {
        return elt;
      }
    } while (i < node.children.length);
  } else {
    return null;
  }
}
