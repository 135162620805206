<template>
  <bar
    :chart-data="dataset"
    :chart-options="options"
    :styles="styles"
  ></bar>
</template>

<script>
import 'chart.js/auto';
import { Bar } from 'vue-chartjs/legacy';

export default {
  name: 'PyramidAge',
  components: { Bar },
  props: ['values'],
  watch: {
    values() {
      this.createPyramid();
    },
  },
  mounted() {
    this.createPyramid();
  },
  data: () => ({
    dataset: {},
    styles: {
      height: '300px',
      position: 'relative',
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      indexAxis: 'y',
      scales: {
        x: {
          grid: {
            display: true,
          },
          stacked: true,
        },
        y: {
          grid: {
            display: false,
          },
          stacked: true,
        },
      },
    },
  }),
  methods: {
    createPyramid() {
      const pyramide = this.values;
      const { male: hKeys, female: fKeys } = this.getKeys(pyramide);
      this.options.scales.x.ticks = {
        beginAtZero: true,
        stepSize: 1,
        callback: (v) => (v < 0 ? -v : v),
      };
      this.options.plugins = {
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              let { label } = tooltipItem.dataset;
              if (label) {
                label += ': ';
              }
              label
                  += tooltipItem.dataset.data[tooltipItem.dataIndex] < 0 ? -tooltipItem.dataset.data[tooltipItem.dataIndex] : tooltipItem.dataset.data[tooltipItem.dataIndex];
              return label;
            },
          },
        },
      };

      this.dataset = {
        labels: fKeys.map((k) => k.substr(1)),
        datasets: [
          {
            stack: 'Stack 0',
            label: 'Male',
            backgroundColor: '#3765b0',
            data: hKeys.map((k) => -1 * pyramide[k]),
          },
          {
            stack: 'Stack 0',
            label: 'Female',
            backgroundColor: '#d41111',
            data: fKeys.map((k) => pyramide[k]),
          },
        ],
      };
    },
    getKeys(pyramide) {
      const fKeys = Object.keys(pyramide).filter((k) => k.startsWith('f'));
      const hKeys = Object.keys(pyramide).filter((k) => k.startsWith('h'));
      return { male: hKeys, female: fKeys };
    },
  },
};
</script>

<style scoped></style>
