export const documentation_en = {
  documentation: 'Documentation',
  documentation_project: 'Documentation Project',
  add_document: 'Add a document',
  document_description: 'Description',
  fichier: 'File',
  select_file: 'Select a file',
  remove_document: 'Delete a file',
  error_access_file: 'Cannot access to the file',
};
