export const fiches_pap_fr = {
  analyse: 'Analyse',
  engagement: 'Engagement',
  eval_pertes: 'Eval. Pertes',
  documents: 'Documents',
  paiements: 'Paiements',
  ok: 'Ok',
  annuler: 'Annuler',
  evaluation: 'Evaluation',
  numero: 'N°',
  feuille_de_bien: 'Feuille de bien',
  contrat: 'Contrat',
  supprimer: 'Supprimer',
  erreur_acces_fichier: "Erreur d'accès au fichier",
  type: 'Type',
  nom: 'Nom',
  quantite: 'Quantite',
  montant: 'Montant',
  description: 'Description',
  type_de_bien: 'Type de Bien',
  type_de_perte: 'Type de perte',
  en_nature: 'En nature',
  genre: 'Genre',
  date_de_naissance: 'Date de naissance',
  localite: 'Localite',
  telephone: 'Telephone',
  email: 'Email',
  creer_le_contrat: 'Créer le contrat',
  uploader_le_contrat_signe: 'Uploader le contrat signé',
  le_contrat_a_ete_cree: 'Le contrat a été créé',
  selectionnez_un_pap: 'Selectionnez une Partie Prenante',
  creer_un_pap: 'Creer une Partie Prenante',
  isPap: 'Est un PAP',
  cni: 'CNI',
  reference: 'reference',
  prenom: 'Prénom',
  identite: 'Identité',
  socio_eco: 'socio-éco',
  compensation: 'compensation',
  categorie: 'Categorie',
  numero_enquete: 'Numero Enqueté',
  inventaires: 'Inventaires',
  parcelles: 'Parcelles',
  personne_affectee_par_le_projet: 'Personne affectée par le projet',
  partie_prenante: 'Partie prenante',
  activite_principale: 'Activite Principale',
  revenu_moyen: 'Revenu Moyen',
  statut: 'Statut',
  nombre_membre_menage: 'Nombre Membre Menage',
  vulnerable: 'Vulnérable',
  signer: 'Signer',
  signature: 'Signature',
  fichier_signe: 'Fichier signé',
  selectionnez_votre_fichier: 'Selectionnez votre fichier',
  signature_importee: 'Signature importée',
  erreur_upload_signature: "Erreur a l'upload de la signature",
  ajouter_quittance: 'Ajouter Quittance',
  fichier_quittance: 'Fichier de quittance',
  quittance_enregistree: 'Quittance enregistrée',
  regle_le: 'Réglé le',
  quittance: 'Quittance',
  activites: 'Activites',
};
