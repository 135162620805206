export const formulaires_fr = {
  inventaires: 'Inventaires',
  nombre_inventaire: 'Nombre d\'inventaire',
  nombre_enquete_se: 'Nombre d\'enquête socio-economique',
  impossible_ouvrir_nouveau_formulaire: "Impossible d'ouvrir un nouveau formulaire, une erreure inconnue s'est produite",
  import_enquetes: "Import d'enquetes",
  selectionnez_fichier_enquetes: 'Selectionnez un fichier contenant des enquêtes',
  celles_ci_seront: 'Celles-ci seront',
  ajoutee_si_non_existante: 'ajoutée si non-existante',
  mise_a_jour_si_deja_presente: 'mise à jour si déja présente',
  fichiers: 'Fichiers',
  selectionnez_votre_fichier: 'Selectionnez votre fichier',
  import_termine: 'Import terminé',
  erreur_import_fichier: "Une erreur s'est produite lors de l'import du fichier",
  selectionnez_fichier_questionnaire: 'Selectionnez le fichier contenant le questionnaire à importer',
  attention_nommage: "Attention la modification du nommage de variables et de certains champs peut altérer l'interpretation des données",
  importer_donnees: 'IMPORTER DONNEES',
  importer_questionnaire: 'IMPORTER QUESTIONNAIRE',
  telecharger_xform: 'TELECHARGER XFORM',
  preview: 'Previsualisation',
  import: 'Import',

};
