export const global_fr = {
  colonnes: 'Colonnes',
  variable: 'variable',
  affichage: 'Affichage',
  ajouter_un_champ: 'Ajouter un champ',
  ok: 'Ok',
  fermer: 'Fermer',
  annuler: 'Annuler',
  label: 'Label',
  back: 'Retour',
};
