<template>
  <v-app>
    <v-overlay :value="loading > 0">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <router-view></router-view>
    <message></message>
    <overlay-batch/>
  </v-app>
</template>

<script>
import Message from '@/components/Message';
import { mapState } from 'vuex';
import OverlayBatch from '@/components/Generics/OverlayBatch';

export default {
  components: {
    OverlayBatch,
    Message,
  },
  computed: {
    ...mapState('loader', ['loading']),
  },
};
</script>

<style lang="scss">
@import "styles/app";
html { overflow-y: auto !important;}
</style>
