const state = {
  budgets: null,
  currencies: null,
};
const getters = {
  budgets: (state) => state.budgets,
  currencies: (state) => state.currencies,
};

const actions = {
  LOAD_BUDGET(context) {
    if (context.rootState.app.selectedProject) {
      return this.$axios
        .get(`/api/budgets/all/${context.rootState.app.selectedProject.id}`)
        .then((r) => {
          context.commit('SET_BUDGETS', r.data);
          return r.data;
        });
    }
  },
  LOAD_CURRENCIES(context) {
    return this.$axios.get('/api/currencies')
      .then((r) => context.commit('SET_CURRENCIES', r.data));
  },
  ASSOCIATE_PAIEMENT(context, { id, paiements }) {
    return this.$axios.post(`/api/budgets/${id}/associate`, paiements.map((p) => p.id))
      .then((r) => context.commit('UPDATE_BUDGET', r.data));
  },
  CREATE_BUDGET(context, budget) {
    budget.project_id = context.rootState.app.selectedProject.id;
    return this.$axios.put('/api/budgets', budget)
      .then((r) => {
        context.commit('ADD_BUDGET', r.data);
        return r.data;
      });
  },
  DETACH_PAIEMENT(context, { id, paiements }) {
    return this.$axios.post(`/api/budgets/${id}/dissociate`, [paiements.map((p) => p.id)])
      .then((r) => context.commit('UPDATE_BUDGET', r.data));
  },
};

const mutations = {
  SET_BUDGETS(state, budgets) {
    state.budgets = budgets;
  },
  ADD_BUDGET(state, budget) {
    state.budgets.push(budget);
  },
  UPDATE_BUDGET(state, budget) {
    const index = state.budgets.findIndex((b) => b.id === budget.id);
    if (index >= 0) {
      state.budgets.splice(index, 1, budget);
    } else {
      state.budgets.push(budget);
    }
  },
  SET_CURRENCIES(state, currencies) {
    state.currencies = Object.keys(currencies).map((k) => ({ currency_id: k, name: currencies[k] }));
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
