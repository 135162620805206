const state = {
  message: null,
  type: 'info',
  batch: null,
  enabledBatch: false,
  timeoutBatch: 2000,
};
const getters = {
  batch: (state) => state.batch,
  enabledBatch: (state) => state.enabledBatch,
  timeoutBatch: (state) => state.timeoutBatch,
};

const mutations = {
  CLEAR_BATCH(state) {
    state.timeoutBatch = 2000;
    state.enabledBatch = false;
    state.batch = null;
  },
  SET_BATCH(state, batch) {
    state.batch = batch;
    state.timeoutBatch = -1;
    state.enabledBatch = true;
  },
  UPDATE_NOTIFICATION(state, data) {
    state.message = data.message;
    state.type = data.type;
  },
  CLEAR_NOTIFICATION(state) {
    state.message = null;
  },
};

const actions = {
  FIRE_SUCCESS(context, message) {
    context.dispatch('FIRE_NOTIFICATION', {
      message,
      type: 'success',
    });
  },
  FIRE_ERROR(context, message) {
    context.dispatch('FIRE_NOTIFICATION', {
      message,
      type: 'error',
    });
  },
  FIRE_DEV(context, message) {
    context.dispatch('FIRE_NOTIFICATION', {
      message: `DEV MESSAGE : ${message}`,
      type: 'dev',
    });
  },
  FIRE_NOTIFICATION(context, data) {
    context.commit('UPDATE_NOTIFICATION', data);
    setTimeout(() => {
      context.commit('CLEAR_NOTIFICATION');
    }, 5000);
  },
  WAITING_BATCH(context, batchId) {
    setTimeout(() => {
      this.$axios.get(`/api/batch/${batchId}`)
        .then((r) => {
          context.commit('SET_BATCH', r.data);
          if (r.data.pendingJobs > 0) {
            context.dispatch('WAITING_BATCH', r.data.id);
          } else {
            context.commit('CLEAR_BATCH');
          }
        });
    }, 1000);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
