export const forgot_your_password_es = {
  reset_my_password: 'Restablecer mi contraseña',
  there_was_a_problem_validating_your_password_reset_request: 'Hubo un problema al validar su solicitud de restablecimiento de contraseña',
  an_email_containing_a_password_reset_link_has_been_sent_to_you: 'Si su cuenta existe, se le ha enviado un correo electrónico que contiene un enlace para restablecer la contraseña',
  set_my_new_password: 'Establecer mi nueva contraseña',
  the_link_in_your_email_is_expired_please_try_to_reset_your_password_again: 'El enlace en su correo electrónico ha caducado. Intente restablecer su contraseña nuevamente.',
  validation_required: 'Necesitas establecer tu nueva contraseña',
  validation_same: 'Las contraseñas ingresadas no coinciden',
  validation_email: 'Debes ingresar un correo electrónico válido',
  your_password_has_been_updated_successfully: 'Su contraseña se ha actualizado correctamente',
};
