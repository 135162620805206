const state = {
  auditResponses: [],
};

const getters = {};

const actions = {
  CREATE_AUDIT_RESPONSE(context, data) {
    data.value = data.value.toString().replace(',', '.');
    return this.$axios.post('api/audit-responses', data);
  },
  GET_AUDIT_RESPONSES(context, auditId) {
    return this.$axios.get(`/api/audit-responses/audit/${auditId}`).then((r) => {
      r.data.forEach((auditResponse) => {
        context.commit('SET_AUDIT_RESPONSE', auditResponse);
      });
    });
  },
  UPDATE_AUDIT_RESPONSE(context, auditResponse) {
    auditResponse.value = auditResponse.value.toString().replace(',', '.');
    return this.$axios.put(
      `/api/audit-responses/${auditResponse.id}`,
      auditResponse,
    );
  },
  CLEAN_BY_AUDIT(context, auditId) {
    context.commit('CLEAN_BY_AUDIT', auditId);
  },
};

const mutations = {
  SET_AUDIT_RESPONSE(state, auditResponse) {
    auditResponse.value = auditResponse.value.toString().replace('.', ',');
    const entries = new Map([[auditResponse.audit_question_id, auditResponse]]);
    const entry = Object.fromEntries(entries);
    state.auditResponses = { ...state.auditResponses, ...entry };
  },
  CLEAN_BY_AUDIT(state, auditId) {
    let auditResponsesAsArray = Object.entries(state.auditResponses);
    auditResponsesAsArray = auditResponsesAsArray.filter((element) => element[1].audit_id !== auditId);
    state.auditResponses = Object.fromEntries(auditResponsesAsArray);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
