export const documentation_es = {
  documentation: '(ES)Documentation',
  documentation_project: '(ES)Documentation Projet',
  add_document: '(ES)Ajouter un document',
  document_description: '(ES)Description',
  fichier: '(ES)Fichier',
  select_file: '(ES)Selectionner votre fichier',
  remove_document: '(ES)Supprimer le fichier',
  error_access_file: '(ES)Le fichier n\' a pas pu etre trouvé',
};
