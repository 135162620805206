<template>
  <div>
    <v-row no-gutters>
      <v-col cols="6">
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
          <div class="d-flex justify-center">
            <b>{{ $t('partie_prenantes.state_graph_title', {type: type}) }}</b>
          </div>
          <pie :chart-data="plaintesByState"></pie>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
          <div class="d-flex justify-center">
            <b>{{ $t('partie_prenantes.state_graph_evol_title', {type: type}) }}</b>
          </div>
         <bar :chart-options="optionsStack" :chart-data="plainteInTime"></bar>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="6">
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
          <div class="d-flex justify-center">
            <b>{{ $t('partie_prenantes.categorie_graph_title', {type: type}) }}</b>
          </div>
          <pie :chart-data="reportByCategorie"></pie>
        </div>
      </v-col>
      <v-col cols="6">
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
          <div class="d-flex justify-center">
            <b>{{ $t('partie_prenantes.level_graph_title', {type: type}) }}</b>
          </div>
          <pie :chart-data="reportByLevel"></pie>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import 'chart.js/auto';
import { Pie, Bar } from 'vue-chartjs/legacy';
import { mapGetters, mapState } from 'vuex';
import { i18n } from '../../main';

export default {
  components: {
    Bar,
    Pie,
  },

  props: {
    type: {
      String,
    },
    switchMonthTriYear: {
      type: String,
    },
    dates: {
      type: Array,
    },
  },

  data() {
    return {
      plaintesKeyValueColor: null,
      plainteCollectionMon: null,
      plainteCollectionTri: null,
      plainteCollectionYear: null,

      plainteInTime: {},
      plaintesByState: {},
      reportByLevel: {},
      reportByCategorie: {},
      optionsStack: {
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  },

  watch: {
    dates() {
      this.recompute();
    },
    switchMonthTriYear() {
      this.recompute();
    },
    selectedProject() {
      this.recompute();
    },
    type() {
      this.recompute();
    },
  },
  mounted() {
    this.recompute();
  },
  computed: {
    ...mapGetters({
      statesByReport: 'report/statesByReport',
      stateColorsByReport: 'report/stateColorsByReport',
      categorieNameByReport: 'report/categorieNameByReport',
      levelNameByReport: 'report/levelNameByReport',
    }),
    states() {
      return this.statesByReport(this.type);
    },
    stateColor() {
      return this.stateColorsByReport(this.type);
    },
    categorieName() {
      return this.categorieNameByReport(this.type);
    },
    levelName() {
      return this.levelNameByReport(this.type);
    },
    ...mapState({
      selectedProject: (state) => state.app.selectedProject,
      defaultcolor: (state) => state.app.defaultcolor,
    }),
  },

  methods: {
    recompute() {
      if (this.selectedProject) {
        const config = { params: { interval: this.switchMonthTriYear } };
        if (this.dates) {
          config.params.dates = this.dates;
        }
        this.axios.get(
          `/api/report/${this.type}/dashboard/${this.selectedProject.id}`,
          config,
        )
          .then((r) => {
            this.plaintesByState = this.createPieDataset(r.data.current, this.stateColor, (k) => i18n.t(`partie_prenantes.states.${k}`));
            this.reportByCategorie = this.createPieDataset(r.data.byCategorie, null, (k) => this.categorieName[k] ?? k);
            this.reportByLevel = this.createPieDataset(r.data.byLevel, null, (k) => this.levelName[k] ?? k);

            const data2 = {
              labels: [],
              datasets: [],
            };

            for (const date in r.data.evolution) {
              data2.labels.push(date);
              for (const state of this.states) {
                let dataset = data2.datasets.find((d) => d.key === state.state);
                if (!dataset) {
                  dataset = {
                    stack: '1',
                    data: [],
                    label: i18n.t(`partie_prenantes.states.${state.state}`),
                    backgroundColor: state.color,
                    key: state.state,
                  };
                  data2.datasets.push(dataset);
                }
                const value = r.data.evolution[date][state.state] ?? 0;
                dataset.data.push({ x: date, y: value });
              }
            }
            this.plainteInTime = data2;
          });
      }
    },
    createPieDataset(data, colors, translationFunction = (k) => k) {
      const dataset = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };
      let i = 0;
      for (const key in data) {
        dataset.datasets[0].data.push(data[key]);
        const label = translationFunction(key);

        dataset.labels.push(label);

        if (colors) {
          dataset.datasets[0].backgroundColor.push(colors[key]);
        } else {
          dataset.datasets[0].backgroundColor.push(this.defaultcolor[i % this.defaultcolor.length]);
        }
        i += 1;
      }
      return dataset;
    },
  },
};
</script>
