<template>
  <app-logged>
    <v-container fluid>

      <v-card tile>

        <v-toolbar color="primary" dark>
          {{ $t("donnees.socio_eco.socio_eco") }}
        </v-toolbar>

        <v-card-text>
          <SocioEcoGroupSelect :choices="statChoice" @update="updateSelect($event)"/>
          <v-tabs v-model="tab">
            <v-tab key="key-indic" href="#key-indic"  to="#key-indic">{{
              $t("donnees.socio_eco.indicateurs_cles")
            }}</v-tab>
            <v-tab key="custom-graph" href="#custom-graph" to="#custom-graph">{{
              $t("donnees.socio_eco.graphiques_personnalises")
            }}</v-tab>
            <v-tab key="isvb" href="#isvb" to="#isvb">{{
              $t("donnees.socio_eco.isvb")
            }}</v-tab>
            <v-tab
                v-if="enabledEngagement"
                key="engagement" href="#engagement" to="#engagement">{{
                $t("donnees.socio_eco.engagement")
              }}</v-tab>
          </v-tabs>
        </v-card-text>

        <v-card-text>
          <v-tabs-items v-model="tab">
            <v-tab-item value="key-indic">
              <div v-if="statChoice" class="d-flex align-stretch flex-wrap">
                <Stats :choices="statChoice"></Stats>
              </div>
            </v-tab-item>
            <v-tab-item value="engagement">
              <Engagement />
            </v-tab-item>
            <v-tab-item value="isvb">
              <Isvb />
            </v-tab-item>
            <v-tab-item value="custom-graph">
              <div class="d-flex align-stretch flex-wrap">
                <v-card
                  class="flex-grow-1 pa-3 ma-3"
                  style="max-width: 450px"
                  v-for="(chart, index) in displayedCharts"
                  :key="index"
                >
                  <chart-card
                    v-bind:chartIndex="index"
                    v-bind:chartState="chart.state"
                    v-bind:chartType="chart.chartType"
                    v-bind:firstQuestionValue="chart.firstQuestion"
                    v-bind:secondQuestionValue="chart.secondQuestion"
                    v-bind:questionsAndAnswers="listOfAnswers"
                    v-bind:questionsLabelAndValues="listOfQuestionsLabelAndValues"
                    @save-chart="onSaveChart"
                    @delete-chart="onDeleteChart"
                  ></chart-card>
                </v-card>
              </div>
            </v-tab-item>
          </v-tabs-items>

        </v-card-text>

      </v-card>

    </v-container>
  </app-logged>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { intersectionWith } from 'lodash';
import ChartCard from '@/components/SocioEco/SocioEcoChartCard';
import { ChartState } from '@/store/socioeco';
import Stats from '@/components/SocioEco/Stats';
import { i18n } from '@/main';
import Isvb from '../components/SocioEco/Isvb';
import SocioEcoGroupSelect from '../components/SocioEco/SocioEcoGroupSelect';
import Engagement from '../components/SocioEco/Engagement';

export default {
  components: {
    Engagement,
    SocioEcoGroupSelect,
    Isvb,
    Stats,
    ChartCard,
  },

  data() {
    return {
      tab: null,
      keyIndic: false,
      tableHeaders: [
        {
          text: i18n.t('donnees.socio_eco.partie_prenante'),
          value: 'partie_prenante_id',
        },
        { text: i18n.t('donnees.socio_eco.categorie'), value: 'classe' },
        { text: i18n.t('donnees.socio_eco.question'), value: 'question' },
        { text: i18n.t('donnees.socio_eco.reponse'), value: 'reponse' },
      ],
      listOfAnswers: [],
      listOfQuestions: [],
      questionsTranslations: [],
      charts: [],
      chartsAdministrationId: undefined,
      statChoice: undefined,
    };
  },

  watch: {
    selectedProject() {
      this.fetchData();
    },

  },

  computed: {
    ...mapState({
      selectedProject: (state) => state.app.selectedProject,
      administrations: (state) => state.app.administrations,
    }),
    ...mapGetters(['enabledEngagement']),
    displayedCharts() {
      return this.charts.concat({ state: ChartState.NEW });
    },

    questionsKeys() {
      const answers = this.listOfAnswers;
      return [...new Set(answers.map((answer) => answer.question))];
    },
    listOfQuestionsLabelAndValues() {
      if (!this.questionsKeys || !this.listOfQuestions) {
        return [];
      }

      return intersectionWith(
        this.listOfQuestions,
        this.questionsKeys,
        (question, questionKey) => question.name === questionKey,
      ).map((question) => ({
        text: question.name,
        value: question.name,
        hint: question.label,
      }));
    },
  },

  methods: {
    fetchData() {
      if (this.selectedProject === null) {
        return;
      }

      this.listOfAnswers = [];

      this.axios
        .get(`/api/socioeco/data/${this.selectedProject.id}`)
        .then((result) => {
          this.listOfAnswers = result.data;
        });
      this.axios
        .get(`/api/socioeco/stat/choices/${this.selectedProject.id}`)
        .then((result) => {
          this.statChoice = result.data;
        });

      this.listOfQuestions = [];

      let tmp = this.administrations.find(
        (a) => a.category === 'formulaire' && a.parameter_name === 'survey',
      );
      if (tmp) {
        this.listOfQuestions = tmp.value;
      }
      tmp = this.administrations.find(
        (a) => a.category === 'socioeco' && a.parameter_name === 'charts',
      );
      if (tmp) {
        this.chartsAdministrationId = tmp?.id;
        const { value } = tmp;
        if (value) {
          this.charts = JSON.parse(value).map((chart) => Object.assign(chart, { state: ChartState.UPDATED }));
        }
      }
    },

    saveCharts() {
      // Ensure we save only necessary chart data, no more.
      const chartsToUpdate = this.charts.map((chart) => ({
        chartType: chart.chartType,
        firstQuestion: chart.firstQuestion,
        secondQuestion: chart.secondQuestion,
      }));

      if (this.chartsAdministrationId) {
        return this.axios.post(
          `/api/administration/options/${this.chartsAdministrationId}`,
          {
            value: JSON.stringify(chartsToUpdate),
          },
        );
      }
      return this.axios
        .put('/api/administration/options', {
          project_id: this.selectedProject.id,
          category: 'socioeco',
          parameter_name: 'charts',
          value: JSON.stringify(chartsToUpdate),
        })
        .then((result) => {
          this.chartsAdministrationId = result?.data?.id;
        });
    },

    onSaveChart(chart) {
      const existingChart = this.charts[chart.index] || {};

      Object.assign(existingChart, chart, {
        state: ChartState.UPDATING,
      });

      // By updating the array with the `slice` method,
      // we force Vue to observe the properties of our new chart object.
      this.charts.splice(chart.index, 1, existingChart);

      this.saveCharts().then(() => {
        existingChart.state = ChartState.UPDATED;
      });
    },

    onDeleteChart(chart) {
      this.charts.splice(chart.index, 1);

      this.saveCharts();
    },
    updateSelect(value) {
      this.$store.dispatch('socioeco/SET_SE_GROUP_SELECTED', value);
    },
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style></style>
