export const documentation_fr = {
  documentation: 'Documentation',
  documentation_project: 'Documentation Projet',
  add_document: 'Ajouter un document',
  document_description: 'Description',
  fichier: 'Fichier',
  select_file: 'Selectionner votre fichier',
  remove_document: 'Supprimer le fichier',
  error_access_file: 'Le fichier n\' a pas pu etre trouvé',
};
