export const menu_es = {
  operation: 'Operación',
  dashboard: 'Tablero de mandos',
  activites: 'Actividades',
  gestion_du_par: 'Gestión del RAP',
  fiches_pap: 'Tarjetas de partes interesadas',
  budget_previsionel: 'Presupuesto del RAP',
  budget: 'Presupuesto',
  reinstallation: 'Reinstalación',
  partie_prenantes: 'Partes interesadas',
  monitoring: 'Monitorización',
  socio_eco: 'Análisis',
  engagement: 'Compromiso',
  sandbox: 'Sandbox',
  documentation: 'Documentación',
  matrice: 'Matriz',
  formulaires: 'Formularios',
  donnees: 'Datos',
  organisation: 'Organización',
  administration: 'Administración',
  deconnexion: 'Desconexión',
  locale_francais: 'Français',
  locale_anglais: 'English',
  locale_espagnol: 'Español',
  add_usercomment: {
    card_title: '(ES)Envoyez un commentaire',
    title: '(ES)Titre',
    comment: '(ES)Commentaire',
    category: '(ES)Categorie',
    ok: 'OK',
    cancel: 'Cancelar',
    success: '(ES)Le commentaire a bien été enregistré',
  },
};
