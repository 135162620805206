import { numberToColorHsl } from '../helper/color';

export const ChartState = {
  NEW: 'new',
  MODIFIED: 'modified',
  UPDATING: 'updating',
  UPDATED: 'updated',
};

export const getIsvbColor = (value) => {
  let normed = Math.max(0, value - 0.25);
  normed = Math.min(normed * 8, 1);
  const color = numberToColorHsl((1 - normed) * 100, 'hex') + (150).toString(16);
  return color;
};

export const createRadialGradient3 = (context, c1, c2, c3) => {
  const { chartArea } = context.chart;
  if (!chartArea) {
    // This case happens on initial chart load
    return null;
  }
  // Create the gradient because this is either the first render
  // or the size of the chart has changed

  const centerX = (chartArea.left + chartArea.right) / 2;
  const centerY = (chartArea.top + chartArea.bottom) / 2;
  const r = Math.min(
    (chartArea.right - chartArea.left) / 2,
    (chartArea.bottom - chartArea.top) / 2,
  );
  const { ctx } = context.chart;
  const gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
  gradient.addColorStop(0, c1);
  gradient.addColorStop(0.25, c2);
  gradient.addColorStop(0.75, c3);
  return gradient;
};

const state = {
  group_selected: null,
  stats_selected: {},
  headers_selected: {},
};

const getters = {
  group_selected: (state) => state.group_selected,
  stats_selected: (state) => state.stats_selected,
  headers_selected: (state) => state.headers_selected,
  params: (state, getter, rootState) => {
    const adm = rootState.app.administrations.find(
      (a) => a.category === 'socioeco'
            && a.parameter_name === 'params',
    );
    return adm ? adm.value : null;
  },
};

const actions = {
  IMPORT_PARAM_SE(context, { form }) {
    return this.$axios.post(
      `/api/socioeco/parameter/import/${context.rootState.app.selectedProject.id}`,
      form,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    ).then((r) => {
      if (r.data) {
        context.commit('SET_PARAMETER', r.data, { root: true });
        return r.data;
      }
    });
  },
  SET_SE_GROUP_SELECTED(context, value) {
    return this.$axios
      .get(`/api/socioeco/stat/${value.id}`)
      .then((r) => {
        context.commit('SET_STATS', r.data);
        context.commit('SET_SE_GROUP_SELECTED', value);
        return r;
      });
  },
};

const mutations = {
  SET_SE_GROUP_SELECTED(state, value) {
    state.group_selected = value;
  },
  SET_STATS(state, stats) {
    state.stats_selected = stats.value;
    state.headers_selected = stats.headers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
