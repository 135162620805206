export const socio_eco_es = {
  engagement: 'Compromiso',
  ajouter_un_graphique: 'Añadir un gráfico',
  question_principale: 'Tema principal',
  question_secondaire: 'Cuestión secundaria',
  bar_chart: 'Gráfico de barras',
  socio_eco: 'Análisis',
  partie_prenante: 'Partes interesadas',
  question: 'Pregunta',
  reponse: 'Respuesta',
  categorie: 'Categoría',
  indicateurs_cles: 'Indicadores clave',
  graphiques_personnalises: 'Gráficos personalizados',
  isvb: 'Índice sintético de vulnerabilidad (ISV)',
  pie_chart: 'Gráfico circular',
  tableau: 'Tabla',
  import_realise_avec_success: 'Importación completada con éxito',
  erreur_lors_de_l_import: 'Se ha producido un error al importar el',
  selectionnez_fichier_excel: 'Seleccione un archivo Excel que contenga los parámetros necesarios para el análisis',
  importer: 'Importar',
  pyramide_age_title: 'Pirámide de edad',
  pyramide_age_description: 'Edad y sexo de las personas',
  rdd_title: 'Relación de dependencia demográfica',
  rdd_description: 'Relación entre el número de individuos < 15 y > 64 años y el número de individuos entre 15 y 64 años',
  rde_title: 'Ratio de dependencia económica',
  rde_description: 'Relación entre el número de personas inactivas y el número de personas activas',
  rcsi_title: 'Reduced Coping Score Index',
  rcsi_description: 'Indicador sintético con 5 indicadores genéricos de vulnerabilidad alimentaria',
  oxford_title: 'Indicador de Oxford',
  oxford_description: 'Distribución de los hogares por unidades de consumo',
  isvb_title: 'Índice sintético de vulnerabilidad',
  isvb_description: 'Puntuación de vulnerabilidad de los hogares (basada en el método de Alkire y Foster para el PNUD)',
  emploi_femmes_title: 'Tasa de empleo femenino en el hogar',
  emploi_femmes_description: 'Porcentaje de mujeres en edad de trabajar con una actividad generadora de ingresos',
  pyramide_age_explanation: 'La pirámide de población representa la distribución por edades de los miembros de los hogares afectados por el proyecto. Las barras azules representan el número de hombres en cada grupo de edad (0-5 años, 5-15 años, 15-25 años, etc.) y las barras rojas representan el número de mujeres en cada grupo de edad',
  // eslint-disable-next-line max-len
  rdd_explanation: 'La  tasa  de  dependencia  demográfica  representa  la  proporción  de  miembros  del  hogar  afectados  por  el proyecto  que  son  dependientes  porque  son  demasiado  jóvenes  o  mayores  para  trabajar,  respecto  a  la proporción de miembros en edad de trabajar. Cuanto menor es la tasa de dependencia, menor es el número de personas dependientes. Así, una tasa de 0 significa que todos los miembros del hogar están en edad de trabajar y una tasa de 1 significa que todos los miembros del hogar son demasiado jóvenes o mayores para trabajar.',
  rde_explanation: 'La  tasa  de  dependencia  económica  representa  la  proporción  de  miembros  del  hogar  afectados  que  no trabajan,  respecto  a  la  proporción  de  miembros  que  trabajan.  Cuanto  menor  es  la  tasa  de  dependencia económica, menor es el número de personas inactivas. Así, una tasa de 0 significa que todos los miembros del hogar trabajan y una tasa de 1 significa que todos los miembros del hogar no trabajan.',
  rcsi_explanation: 'El  rCSI  es  un  indicador  desarrollado  por  USAID  para  reflejar  la  inseguridad  alimentaria  de  los  hogares afectados por el proyecto. Cuanto mayor sea el rCSI, mayor será la inseguridad alimentaria del hogar (con un máximo de 56).',
  oxford_explanation: 'El  indicador  Oxford  (o  unidad  de  consumo  Oxford)  es  una  modelización  de  los  hogares  afectados  por  el proyecto según la edad de los miembros del hogar y su consumo. El primer miembro del hogar se contabiliza como  1  unidad,  los  siguientes  miembros  mayores  de  14  años  se  contabilizan  como  0,5  unidades  y  los miembros menores de 14 años se contabilizan como 0,3 unidades.',
  isvb_explanation: 'El Indicador de Vulnerabilidad es un indicador derivado del método Alkire Foster de medición multidimensional de la "vulnerabilidad a la pobreza". Este indicador se basa en una evaluación de la educación, la salud, las condiciones materiales de vida y los medios de subsistencia de los hogares afectados por el proyecto para reflejar  su  vulnerabilidad.  Cuanto  más  se  acerque  el  resultado  a  0,  menos  vulnerable  será  el  hogar,  y viceversa.',
  emploi_femmes_explanation: 'La tasa de empleo femenino representa la proporción de mujeres que trabajan en los hogares afectados por el proyecto. Cuanto más se acerque a 0, menos mujeres trabajan en el hogar, y viceversa.',
};
