export const budget_en = {
  name: 'Budget name',
  created_at: 'Creation Date',
  amount: 'Amount',
  budget: 'Budget',
  informations: 'Informations',
  summary: 'Summary',
  paiements: 'Payments',
  add_paiements: 'Add payments',
  associate_paiement: 'Add',
  annuler: 'Cancel',
  creer_budget: 'Create',
  devise: 'Currency',
  total_paiements: 'Total Payments',
  depassement_budget: 'Exceeding budget',

};
