<template>
  <v-select
      v-model="select"
      :items="choices"
      :item-text="displayText"
      label="Select"
      persistent-hint
      return-object
      single-line
      @change="$emit('update',select)"
  ></v-select>
</template>

<script>
export default {
  name: 'SocioEcoGroupSelect',
  watch: {
    choices() {
      if (!this.select) {
        this.select = this.choices[0];
        this.$emit('update', this.select);
      }
    },
  },
  props: ['choices'],
  data: () => ({
    select: null,
  }),
  methods: {
    displayText(value) {
      return `${value.localite} - ${value.commune} - ${value.departement}  - (${value.population})`;
    },
  },
};
</script>

<style scoped>

</style>
