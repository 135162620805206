<template>
  <v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <file-drop
    @selected="mapFiles">
    </file-drop>
    <v-card>
      <v-data-table
          :headers="headers"
          :items="elements_to_sign"
          :options.sync="options"
          :server-items-length="total_elements_to_sign"
      >
        <template v-slot:item.type="{ item }">
          <span>{{ item.contrat == null ? 'Fdb' : 'Contrat'}}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon :color="droppedFiles[item.id] ? 'green' : 'red'" small class="mr-2">
            {{ droppedFiles[item.id] ? 'mdi-check' : 'mdi-close-thick'}}
          </v-icon>
        </template>
      </v-data-table>
      <v-card-actions class="d-flex justify-end">
        <v-btn
            :disabled="!droppedFiles || Object.keys(droppedFiles).length == 0"
            color="primary"
            @click="sendDocument"
        >
          Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { toLower } from 'lodash/string';
import FileDrop from './FileDrop';

export default {
  name: 'MassFdbSign',
  components: { FileDrop },
  watch: {
    selectedProject() {
      this.load();
    },
    options: {
      handler() {
        this.load();
      },
      deep: true,
    },
  },
  mounted() {
    this.load();
  },
  data: () => ({
    loading: false,
    droppedFiles: {},
    options: {},
    headers: [
      { value: 'type', text: 'Type de document' },
      { value: 'document.name', text: 'Document à signer' },
      { value: 'pp.nom', text: 'Nom PAP' },
      { value: 'actions', text: '' },
    ],
  }),
  computed: {
    ...mapGetters('document', ['elements_to_sign', 'total_elements_to_sign']),
    ...mapState({
      selectedProject: (state) => state.app.selectedProject,
    }),
  },
  methods: {
    sendDocument() {
      this.loading = true;
      this.$store.dispatch('document/PUSH_ELEMENT_SIGN_DOCUMENT', Object.values(this.droppedFiles))
        .then(() => {
          this.$store.dispatch('FIRE_SUCCESS', this.$t('partie_prenantes.success_signed'));
        }).catch(() => {
          this.$store.dispatch('FIRE_ERROR', this.$t('partie_prenantes.failed_signed'));
        }).finally(() => {
          this.loading = false;
        });
    },
    load() {
      if (this.selectedProject) {
        const params = {
          ...this.options,
          project_id: this.selectedProject.id,
        };
        this.loading = true;
        this.$store.dispatch(
          'document/LOAD_FDBS_TO_SIGN',
          params,
        ).finally(() => {
          this.loading = false;
        });
      }
    },
    async mapFiles(files) {
      const allFiles = await this.getFilesAsync(files);
      const result = {};
      for (let i = 0; i < allFiles.length; i += 1) {
        const elements = this.elements_to_sign.filter((ftoSign) => toLower(allFiles[i].name).includes(toLower(ftoSign.document.name.replace(/(.*)\.(.*?)$/, '$1'))));
        for (const element of elements) {
          result[element.id] = { id: element.id, file: allFiles[i], type: element.contrat ? 'contrat' : 'fdb' };
        }
      }
      this.droppedFiles = result;
    },
    async getFilesAsync(items) {
      const files = [];
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i];
        if (item.kind === 'file') {
          if (typeof item.webkitGetAsEntry === 'function') {
            const entry = item.webkitGetAsEntry();
            // eslint-disable-next-line
            const entryContent = await this.readEntryContentAsync(entry);
            files.push(...entryContent);
            // continue;
          } else {
            const file = item.getAsFile();
            if (file) {
              files.push(file);
            }
          }
        }
      }
      return files;
    },
    readEntryContentAsync(entry) {
      return new Promise((resolve) => {
        let reading = 0;
        const contents = [];
        function readReaderContent(reader) {
          reading += 1;
          reader.readEntries((entries) => {
            reading -= 1;
            for (const entry of entries) {
              // eslint-disable-next-line
              readEntry(entry);
            }
            if (reading === 0) {
              resolve(contents);
            }
          });
        }
        function readEntry(entry) {
          if (entry.isFile) {
            reading += 1;
            entry.file((file) => {
              reading -= 1;
              contents.push(file);
              if (reading === 0) {
                resolve(contents);
              }
            });
          } else if (entry.isDirectory) {
            readReaderContent(entry.createReader());
          }
        }

        readEntry(entry);
      });
    },
  },
};
</script>

<style scoped>

</style>
