const state = {
  missing_list: [],
  total_missing: 0,
  elements_to_sign: [],
  total_elements_to_sign: 0,
};

const getters = {
  missings: (state) => state.missing_list,
  total_missing: (state) => state.total_missing,
  elements_to_sign: (state) => state.elements_to_sign,
  total_elements_to_sign: (state) => state.total_elements_to_sign,
};

const actions = {
  LOAD_MISSING_DOCUMENT(context, params) {
    params.missing = true;
    this.$axios.get(`/api/document/all/${params.project_id}`, { params: params })
      .then((r) => context.commit('SET_MISSING_LIST', r.data));
  },
  LOAD_FDBS_TO_SIGN(context, params) {
    this.$axios.get(`/api/fdbs/all/element-to-sign/${params.project_id}`)
      .then((r) => context.commit('SET_FDBS_TO_SIGN', r.data));
  },
  PUSH_ELEMENT_SIGN_DOCUMENT(context, documents) {
    const promises = documents.map((d) => {
      const formData = new FormData();
      formData.append('file', d.file, d.file.name);
      const action = d.type === 'contrat' ? 'pap/SIGN_CONTRAT' : 'pap/SIGN_FDB';
      return context.dispatch(action, {
        fdbId: d.id, payload: formData,
      }, { root: true });
    });
    return Promise.all(promises).then((r) => {
      context.dispatch('LOAD_FDBS_TO_SIGN', { project_id: context.rootState.app.selectedProject.id });
      return r;
    });
  },
  PUSH_DOCUMENT(context, { document_id, payload }) {
    return this.$axios.post(`/api/document/${document_id}/upload`, payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
    }).then(() => context.dispatch('LOAD_MISSING_DOCUMENT', context.rootState.app.selectedProject));
  },
};

const mutations = {
  SET_MISSING_LIST(state, list) {
    state.missing_list = list.data;
    state.total_missing = list.total;
  },
  SET_FDBS_TO_SIGN(state, list) {
    state.elements_to_sign = list.data;
    state.total_elements_to_sign = list.total;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
