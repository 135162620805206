import { sumBy } from 'lodash';
import Vue from 'vue';

const state = {
  states: [],
  contrats: [],
  paiementsByPpId: {},
};
const getters = {
  paiementsByPp: (state) => (ppId) => state.paiementsByPpId[ppId],
  paidSumByContrat: (state) => state.contrats.reduce((agg, c) => {
    agg[c.id] = sumBy(
      c.paiements.filter((p) => p.state === 'paid'),
      'montant',
    );
    return agg;
  }, {}),
  states: (state) => state.states,
  stateName: (state) => state.states.reduce((a, b) => (((a[b.id] = b.name), a)), {}),
  stateColor: (state) => state.states.reduce((a, b) => (((a[b.id] = b.color), a)), {}),
};

const actions = {
  async INIT(context) {
    const states = [
      {
        id: 'paid', name: 'Payé', color: '#1172af', cumulColor: '#7f18ab',
      },
      {
        id: 'to_be_paid',
        name: 'A payer',
        color: '#c5deed',
        cumulColor: '#e9cef5',
      },
    ];
    context.commit('UPDATE_STATES', states);
  },
  LOAD_PAIMENT_OF_PP(context, ppId) {
    return this.$axios.get(`/api/partiePrenante/data/${ppId}/paiements`)
      .then((r) => context.commit('SET_PP_PAIEMENT', { ppId: ppId, paiements: r.data }));
  },
  ADD_RECEIPT(context, data) {
    const formData = new FormData();
    formData.append('file', data.file);
    return this.$axios.post(
      `/api/paiements/${data.paiement.id}/echeance/${data.echeance.id}/reglement`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    ).then((r) => context.commit('ADD_RECEIPT', r.data));
  },
  // LOAD_CONTRATS(context) {
  //   return this.$axios
  //     .get(`/api/paiement/all/${context.rootState.app.selectedProject.id}`)
  //     .then((r) => {
  //       context.commit('UPDATE_CONTRATS', r.data);
  //     });
  // },
};

const mutations = {
  UPDATE_CONTRATS(state, contrats) {
    state.contrats = contrats;
  },
  SET_PP_PAIEMENT(state, { ppId, paiements }) {
    Vue.set(state.paiementsByPpId, ppId, paiements);
  },
  UPDATE_STATES(state, data) {
    state.states = data;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
