export const donnees_fr = {
  templates: 'Templates',
  nom: 'Nom',
  type: 'Type',
  fichier: 'Fichier',
  default: 'Default',
  afficher_les_prix_unitaires: 'Display unit prices',
  afficher_quantites_indemnisees: 'Afficher les quantités indemnisées',
  formulaires: 'Formulaires',
  ajouter_un_formulaire: 'Ajouter un formulaire',
  creation_de_formulaire: 'Creation de formulaire',
  nom_du_formulaire: 'Nom du formulaire',
  ok: 'OK',
  annuler: 'Annuler',
  type_cree: 'Le type {type} a été créé',
  erreur_creation_type: 'Une erreur est survenue pendant la création du type {type}',
  matrice_de_prix: 'Matrice de prix',
  parcellaire: 'Parcellaire',
  template: 'Template',
  sandbox: 'Sandbox',
  ajouter_une_source: 'AJOUTER UNE SOURCE',
  importer: 'importer',
  exporter: 'Exporter',
  import_parcellaire: 'Import Parcellaire',
  impossible_de_lier_la_source: 'Impossible de lier la source',
  ajouter_un_template: 'Ajouter un template',
  selectionnez_votre_fichier: 'Selectionnez votre fichier',
  type_de_template: 'Type de template',
  fiche_de_bien: 'Fiche de bien',
  contrat: 'Contrat',
  socioeco: 'Analyse',
  user: 'Utilisateur',
  password: 'Mot de passe',
  api_key: 'Clé API',

  nombre_enquetes: "Nombre d'enquêtes",
  importer_enquetes: 'IMPORTER ENQUETES',
  importer_questionnaire: 'IMPORTER QUESTIONNAIRE',
  telecharger_xform: 'TELECHARGER XFORM',
  import_parametres: 'IMPORTER PARAMETRES',
  taux_de_change: 'Taux de change',
  revenu_par_uc: 'Revenu par UC',
  vente_gros_animaux_type_1: "Vente de gros animaux d'élevage (type 1)",
  vente_gros_animaux_type_2: "Vente de gros animaux d'élevage (type 2)",
  vente_gros_animaux_type_3: "Vente de gros animaux d'élevage (type 3)",
  vente_gros_animaux_type_4: "Vente de gros animaux d'élevage (type 4)",
  vente_petits_animaux: "Vente de petits animaux d'élevage",
  nombre_sous_region_geo: 'Nombre de sous-régions géographique',
};
