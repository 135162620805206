const state = {
  fields: [],
};
const getters = {};

const actions = {
  // async INIT() {},
};

const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
