<template>
<v-card flat>
  <v-card-text>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th></th>
          <th class="text-left label-title">Population Totale</th>
          <th class="text-left label-title">Population Vulnerable</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="label-title">Nombre individus</td>
          <td class="label-info">{{ values.n }}</td>
          <td class="label-info">{{ values.q }}</td>
        </tr>
        <tr>
          <td class="label-title">Nombre menages</td>
          <td class="label-info">{{ stats_selected.menages }}</td>
          <td class="label-info">{{ stats_selected.menagesk }}</td>
        </tr>
        <tr>
          <td class="label-title">%</td>
          <td class="label-info">100%</td>
          <td class="label-info">{{ (values.H * 100).toFixed(2) }}%</td>
        </tr>
        <tr>
          <td class="label-title">ISV</td>
          <td class="label-info">{{ values.isvb.toFixed(3) }}</td>
          <td class="label-info">{{ values.A.toFixed(3) }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card-text>
</v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PopulationMainIndicators',
  computed: {
    ...mapGetters('socioeco', ['stats_selected']),
    values() {
      return this.stats_selected.isvb_result;
    },
  },
};
</script>

<style scoped>

</style>
