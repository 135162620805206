export const matrice_fr = {
  bareme_de_compensation: 'Bareme de compensation',
  zone_de_prix: 'Zone de prix',
  recherche: 'Recherche',
  ligne_non_valides: 'Ligne non valides',
  importer_matrice: 'IMPORTER MATRICE',
  code: 'Code',
  type: 'Type',
  libelle: 'Libelle',
  prix: 'Prix',
  unite: 'Unité',
  zone: 'Zone',
  pas_de_donnees_dans_la_matrice_de_prix: 'Il ny a pas de données dans la matrice de prix',
  matrice_de_prix: 'Matrice de prix',
  selectionnez_fichier_excel: 'Selectionnez un fichier excel contenant une définition de la matrice de\n'
    + '        prix.<br />\n'
    + '        Seules les feuilles commençant par les caractères <i>M_</i> seront\n'
    + '        importées.',
  importer: 'Importer',
  import_realise_avec_succes: 'Import réalisé avec succès',
  erreur_lors_de_l_import: "Une erreur s'est produite lors de l'import du fichier",
  fichiers: 'Fichiers',
};
