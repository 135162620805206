export const forgot_your_password_fr = {
  reset_my_password: 'Réinitialiser mon mot de passe',
  there_was_a_problem_validating_your_password_reset_request: 'Un problème est survenu lors de la validation de votre demande de réinitialisation de mot de passe',
  an_email_containing_a_password_reset_link_has_been_sent_to_you: 'Si votre compte existe, un email contenant un lien de réinitialisation de votre mot de passe vous a été envoyé',
  set_my_new_password: 'Définir mon nouveau mot de passe',
  the_link_in_your_email_is_expired_please_try_to_reset_your_password_again: 'Le lien dans votre e-mail est expiré. Veuillez réessayer de réinitialiser votre mot de passe.',
  validation_required: 'Vous devez définir votre nouveau mot de passe',
  validation_same: 'Les mots de passe saisis ne correspondent pas',
  validation_email: 'Vous devez saisir un email valide',
  your_password_has_been_updated_successfully: 'Votre mot de passe a été mis à jour avec succès',
};
