const state = {
  mods: [],
  modsForProject: {},
  modsForUser: {},
  modsForOrganization: [],
};

const getters = {};

const actions = {
  LOAD_MODS(context) {
    return this.$axios.get('/api/mods').then((r) => {
      context.commit('SET_MODS', r.data);
      return r.data;
    });
  },
  LOAD_MODS_FOR_ORGANIZATION(context, id) {
    return this.$axios.get(`/api/mods/organization/${id}`).then((r) => {
      context.commit('SET_MODS_FOR_ORGANIZATION', r.data);
      return r.data;
    });
  },
  LOAD_MODS_FOR_PROJECT(context, id) {
    return this.$axios.get(`/api/mods/project/${id}`).then((r) => {
      context.commit('SET_MODS_FOR_PROJECT', {
        id,
        mods: r.data,
      });
      return {
        id,
        mods: r.data,
      };
    });
  },
  LOAD_MODS_FOR_USER(context, payload) {
    return this.$axios.get(`/api/mods/project_user/${payload.project_id}/${payload.user_id}`).then((r) => {
      context.commit('SET_MODS_FOR_USER', {
        project_id: payload.project_id,
        mods: r.data,
      });
      return {
        project_id: payload.project_id,
        mods: r.data,
      };
    });
  },
  SAVE_MODS_FOR_PROJECT(context, data) {
    return this.$axios
      .put(`/api/mods/project/${data.id}`, data.modsForProject)
      .then((r) => {
        context.commit('SET_MODS_FOR_PROJECT', {
          id: data.id,
          mods: r.data,
        });
        return r.data;
      });
  },
  SAVE_MODS_FOR_USER(context, payload) {
    return this.$axios
      .put(
        `/api/mods/project_user/${payload.project_id}/${payload.user_id}`,
        payload.modsForUser,
      )
      .then((r) => {
        context.commit('SET_MODS_FOR_USER', {
          project_id: payload.project_id,
          mods: r.data,
        });
        return r.data;
      });
  },
};

const mutations = {
  SET_MODS(state, mods) {
    state.mods = mods;
  },
  SET_MODS_FOR_PROJECT(state, modsForProject) {
    state.modsForProject[modsForProject.id] = modsForProject.mods;
  },
  SET_MODS_FOR_USER(state, modsForUser) {
    state.modsForUser[modsForUser.project_id] = modsForUser.mods;
  },
  SET_MODS_FOR_ORGANIZATION(state, modsForOrganization) {
    state.modsForOrganization = modsForOrganization;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
