export const forgot_your_password_en = {
  reset_my_password: 'Reset my password',
  there_was_a_problem_validating_your_password_reset_request: 'There was a problem validating your password reset request',
  an_email_containing_a_password_reset_link_has_been_sent_to_you: 'If your account exists, an email containing a password reset link has been sent to you',
  set_my_new_password: 'Set my new password',
  the_link_in_your_email_is_expired_please_try_to_reset_your_password_again: 'The link in your email is expired. Please try to reset your password again.',
  validation_required: 'You need to set your new password',
  validation_same: 'The passwords entered do not match',
  validation_email: 'You must enter a valid email',
  your_password_has_been_updated_successfully: 'Your password has been updated successfully',
};
