import Vue from 'vue';

const state = {
  projects: [],
  users: [],
  roles: [],
};

const getters = {
  selectableRoles: (state, getters, rootState, rootGetters) => (rootGetters.isSuperAdmin ? state.roles : state.roles.filter((r) => r.id !== 'superadmin')),
};

const actions = {
  CREATE_USER(context, user) {
    return this.$axios
      .post('/api/administration/user/create', { user })
      .then((r) => {
        context.commit('ADD_USER', r.data);
        return r.data;
      });
  },
  GET_USER(context, id) {
    return new Promise((resolve, reject) => {
      const u = state.users.find((u) => u.id === id);
      if (u) {
        resolve(u);
      } else {
        reject(new Error('We cannot get user.'));
      }
    }).catch(() => this.$axios
      .get(`/api/administration/user/${id}`)
      .then((r) => r.data));
  },
  DELETE_USER(context, user) {
    return this.$axios
      .delete(`/api/administration/user/${user.id}`)
      .then(() => context.commit('REMOVE_USER', user));
  },
  SET_USER_PROJECT(context, data) {
    return this.$axios.put('/api/administration/user/project', data)
      .then((r) => context.commit('UPDATE_PROJECT', r.data.project));
  },
  LOAD_USERS_INFO(context) {
    const promise1 = this.$axios.get('/api/administration/projects').then((r) => {
      context.commit('SET_PROJECTS', r.data);
      return r.data;
    });

    const promise2 = this.$axios.get('/api/administration/users').then((r) => {
      context.commit('SET_USERS', r.data);
      return r.data;
    });
    return Promise.all([promise1, promise2]);
  },
  UPDATE_USER(context, user) {
    return this.$axios.put(`/api/administration/user/${user.id}`, {
      user,
    });
  },
};

const mutations = {
  ADD_USER(state, user) {
    state.users.push(user);
  },
  REMOVE_USER(state, user) {
    state.users = state.users.filter((u) => u.id !== user.id);
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_USERS(state, users) {
    state.users = users;
  },
  UPDATE_PROJECT(state, project) {
    const projIndex = state.projects.findIndex((p) => p.id === project.id);
    if (projIndex > -1) {
      Vue.set(state.projects, projIndex, project);
    } else {
      state.projects.push(project);
    }
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
