<template>
  <v-card flat>
    <v-card-title>
      <slot name="title"/>
    </v-card-title>
    <pie :chart-data="graphData" :chart-options="options" />
  </v-card>
</template>

<script>
import 'chart.js/auto';
import { Pie } from 'vue-chartjs/legacy';
import { groupBy } from 'lodash';
import { mapState } from 'vuex';

export default {
  props: ['phi', 'indicators'],
  name: 'IsvPhiChart',
  components: { Pie },
  watch: {
    phi() {
      this.create();
    },
  },
  data: () => ({
    graphData: {},
    options: {},
  }),
  computed: {
    ...mapState({
      defaultcolors: (state) => state.app.defaultcolor,
    }),
  },
  methods: {
    create() {
      const arrayIndicators = [];
      for (const ind in this.indicators) {
        if (this.indicators[ind] === 1) {
          const classe = ind.split('_')[0];
          const subclasse = ind.replace(`${classe}_`, '');
          arrayIndicators.push({ id: ind, classe, subclasse });
        }
      }
      const grouped = groupBy(arrayIndicators, 'classe');
      const classeDs = { labels: [], data: [], backgroundColor: [] };
      const subClasseDs = { labels: [], data: [], backgroundColor: [] };
      const labels = [];
      for (const classe in grouped) {
        let sum = 0;
        const classeColor = this.defaultcolors[
          classeDs.backgroundColor.length % this.defaultcolors.length
        ];
        const index = 0;
        for (const subClasse of grouped[classe]) {
          const alpha = Math.round((240 / (grouped[classe].length)) * (index + 1));
          const subClasseColor = classeColor + (alpha).toString(16);
          subClasseDs.data.push(this.phi[subClasse.id] * 100);
          subClasseDs.labels.push(subClasse.id);
          subClasseDs.backgroundColor.push(subClasseColor);
          sum += this.phi[subClasse.id];
        }
        classeDs.data.push(sum * 100);
        classeDs.backgroundColor.push(classeColor);
        labels.push(classe);
        classeDs.labels.push(classe);
      }
      this.graphData = {
        labels,
        datasets: [subClasseDs, classeDs],
      };
      this.options = {
        responsive: true,
        plugins: {
          tooltip: {
            callbacks: {
              label(tooltipItem) {
                return `${tooltipItem.dataset.labels[tooltipItem.dataIndex]}: ${tooltipItem.formattedValue}%`;
              },
            },
          },
          legend: {
            labels: {
              filter(lItem) {
                const index = labels.indexOf(lItem.text);
                if (index >= 0) {
                  lItem.fillStyle = classeDs.backgroundColor[index];
                  return true;
                }
                return false;
              },
            },
            onClick(e, legendItem) {
              const ci = this.chart;
              const meta = ci.getDatasetMeta(0);
              meta.controller._config.labels.forEach((label, index) => {
                if (label.includes(legendItem.text)) {
                  meta.data[index].hidden = !meta.data[index].hidden;
                }
              });
              const meta2 = ci.getDatasetMeta(1);
              meta2.data[classeDs.labels.indexOf(legendItem.text)].hidden = !meta2
                .data[classeDs.labels.indexOf(legendItem.text)].hidden;
              ci.update();
            },
          },
        },
      };
    },
  },
  mounted() {
    // this.stub = {
    //   phi: {
    //     // influence du critere sur la note
    //     moyens_access: 0,
    //     moyens_revenu: 0.187,
    //     sante_less_meals: 0.187,
    //     conditions_transport: 0,
    //     sante_health_practice: 0.187,
    //     conditions_electricity: 0,
    //     education_noschool_kid: 0.187,
    //     conditions_communication: 0,
    //     conditions_hard_flooring: 0,
    //     education_noschool_adult: 0.187,
    //     conditions_drinking_water: 0,
    //     conditions_sanitary_devices: 0.063
    //   },
    //   phik: {
    //     moyens_access: 0,
    //     moyens_revenu: 0.187,
    //     sante_less_meals: 0.187,
    //     conditions_transport: 0,
    //     sante_health_practice: 0.187,
    //     conditions_electricity: 0,
    //     education_noschool_kid: 0.187,
    //     conditions_communication: 0,
    //     conditions_hard_flooring: 0,
    //     education_noschool_adult: 0.187,
    //     conditions_drinking_water: 0,
    //     conditions_sanitary_devices: 0.063
    //   },
    //   indicators: {
    //     moyens_access: 0,
    //     moyens_revenu: 1,
    //     sante_less_meals: 1,
    //     conditions_transport: 0,
    //     sante_health_practice: 1,
    //     conditions_electricity: 0,
    //     education_noschool_kid: 1,
    //     conditions_communication: 0,
    //     conditions_hard_flooring: 0,
    //     education_noschool_adult: 1,
    //     conditions_drinking_water: 0,
    //     conditions_sanitary_devices: 1
    //   }
    // };
    this.create();
  },
};
</script>

<style scoped></style>
