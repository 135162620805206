export const socio_eco_en = {
  engagement: 'Engagement',
  isvb: 'Synthetic Vulnerability Index (SVI)',
  graphiques_personnalises: 'Custom charts',
  indicateurs_cles: 'Key Indicators',
  socio_eco: 'Analysis',
  partie_prenante: 'Stakeholder',
  categorie: 'Category',
  question: 'Question',
  response: 'Response',
  ajouter_un_graphique: 'Add a graph',
  question_principale: 'Main issue',
  question_secondaire: 'Secondary issue',
  bar_chart: 'Bar chart',
  pie_chart: 'Pie chart',
  tableau: 'Table',
  import_realise_avec_success: 'Import successfully completed',
  erreur_lors_de_l_import: 'An error occurred while importing the file',
  selectionnez_fichier_excel: 'Select a file that contains the required parameters for the socio-eco computation',
  importer: 'Import',
  pyramide_age_title: 'Age pyramid',
  pyramide_age_description: 'Age and gender of individuals',
  rdd_title: 'Demographic Dependency Ratio',
  rdd_description: 'Ratio of the number of individuals - 15 and + 64 to the number of individuals aged between 15 and 64',
  rde_title: 'Economic Dependency Ratio',
  rde_description: 'Ratio of the number of inactive individuals to the number of active individuals',
  rcsi_title: 'Reduced Coping Score Index',
  rcsi_description: 'Synthetic indicator with 5 generic indicators of food vulnerability',
  oxford_title: 'Indicateur Oxford',
  oxford_description: 'Distribution of households by Consumption Units',
  isvb_title: 'Synthetic Vulnerability Index',
  isvb_description: 'Household vulnerability score (based on the Alkire and Foster method for UNDP)',
  emploi_femmes_title: 'Employment rate of women in the household',
  emploi_femmes_description: 'Share of women of working age with an activity that generates income',
  pyramide_age_explanation: 'The age pyramid represents the age distribution of household members affected by the project. The blue bars represent the number of men in each age bracket (0-5 years, 5-15 years, 15-25 years, etc.) and the red bars represent the number of women in each age bracket.',
  rdd_explanation: 'The DDR represents the proportion of household members affected by the project who are dependent because they are too young or too old to work, compared with the proportion of members of working age. The lower the DDR, the fewer people are dependent. Thus, an DDR of 0 means that all the members of the household are of working age and an DDR of 1 means that all the members of the household are too young or too old to work.',
  rde_explanation: 'The EDR represents the proportion of members of households affected by the project who are not working, compared with the proportion of members who are working. The lower the EDR, the fewer people are inactive. Thus, an EDR of 0 means that all the members of the household are working and an EDR of 1 means that all the members of the household are not working.',
  rcsi_explanation: 'The rCSI is an indicator developed by USAID to reflect the food insecurity of households affected by the project. The higher the rCSI, the greater the household\'s food insecurity (with a maximum of 56).',
  oxford_explanation: 'The  Oxford  indicator  (or  Oxford  consumption  unit)  is  a  model  of  the  households  affected  by  the  project according to the household members age and their consumption. The first member of the household is counted as 1 unit, subsequent members aged 14 and over are counted as 0.5 units and members under 14 are counted as 0.3 units.',
  isvb_explanation: 'The ISV is an indicator taken from the Alkire Foster method of multidimensional measurement of "vulnerability to poverty". This indicator is based on an assessment of the education, health, material living conditions and means of subsistence of the households affected by the project to reflect their vulnerability. The closer the SVI is to 0, the less vulnerable the household, and vice versa.',
  emploi_femmes_explanation: 'The  female  employment  rate  represents  the  proportion  of  working  women  in  households  affected  by  the project. The closer it is to 0, the fewer women in the household are working, and vice versa.',
};
