export const dashboard_fr = {
  dashboard: 'Dashboard',
  pick_range: 'Selectionnez la plage',
  mois: 'Mois',
  trimestre: 'Trimestre',
  annee: 'Année',
  inventaires: 'Inventaires',
  compensations: 'Compensations',
  engagement: 'Engagement',
  liberation_des_terres_surface: 'LIBERATION DES TERRES (surface)',
  avancement_de_la_reinstallation: 'AVANCEMENT DE LA REINSTALLATION (en nombres parcelles)',
  evolution_de_la_liberation_des_terres: 'EVOLUTION DE LA LIBERATION DES TERRES (surface)',
  montant_des_contrats_et_etat_des_paiments: 'MONTANT DES CONTRATS ET ETAT DES PAIMENTS',
  montant_des_pertes_et_etat_des_paiments: 'MONTANT DES PERTES ET ETAT DES PAIMENTS',
  categories_de_pap: 'CATEGORIES DE PAP',
  genres: 'GENRES',
  perte_eco_physique: 'Perte eco + physique',
  perte_eco: 'Perte eco',
  perte_physique: 'Perte physique',
  autre: 'Autre',
  engagement_ambiance_title: "Répartition de l'ambiance des réunions",
  engagement_ambiance_description: "Nombre de réunions par qualification d'ambiance",
  engagement_categorie_pp_title: 'Répartition des categories de PP',
  engagement_categorie_pp_description: 'Nombre de réunions relié à une catégorie de parties prenantes',
  engagement_categorie_title: 'Répartition des categories de réunion',
  engagement_categorie_description: 'Nombre de réunions par catégories',
  gender_not_defined: 'Des PAPs ont un genre non présent dans les données',
  activites: 'Activites',
};
