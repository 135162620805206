export const formulaires_en = {
  inventaires: 'Inventories',
  nombre_inventaire: 'Number of surveys',
  nombre_enquete_se: 'Number of economic and social surveys',
  impossible_ouvrir_nouveau_formulaire: 'Unable to open a new form, an unknown error has occurred',
  import_enquetes: 'Import of surveys',
  selectionnez_fichier_enquetes: 'Select a file containing surveys',
  celles_ci_seront: 'These will be',
  ajoutee_si_non_existante: 'added if non-existent',
  mise_a_jour_si_deja_presente: 'update if already present',
  fichiers: 'Files',
  selectionnez_votre_fichier: 'Select your file',
  import_termine: 'Import completed',
  erreur_import_fichier: 'An error occurred while importing the',
  selectionnez_fichier_questionnaire: 'Select the file containing the questionnaire to be imported',
  attention_nommage: 'Beware that changing the naming of variables and certain fields may alter the interpretation of the data',
  importer_donnees: 'Import data',
  importer_questionnaire: 'Import questionnaire',
  telecharger_xform: 'Download Xform',
  preview: 'Preview',
  import: 'Import',

};
