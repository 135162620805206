import axios from 'axios';
import router from '@/router';

const token = localStorage.getItem('id_token');

const instance = axios.create();
if (process.env.VUE_APP_API_URL) {
  instance.defaults.baseURL = process.env.VUE_APP_API_URL;
}

instance.interceptors.response.use(
  (r) => r,
  (err) => {
    if (err.response.status === 401) {
      if (router.currentRoute.path !== '/login') {
        router.push(
          `/login${
            !['', '/'].includes(router.currentRoute.path)
              ? `?redirect=${router.currentRoute.path}`
              : ''}`,
        );
      }
    }
    return Promise.reject(err);
  },
);
instance.defaults.headers.common.Authorization = `Bearer ${token}`;
export default instance;
