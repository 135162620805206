export const activites_fr = {
  activites: 'Activités',
  mes_activites: 'Mes activités',
  en_creation: 'En création',
  total: 'Total',
  en_cours: 'En cours',
  en_retard: 'En retard',
  cloture: 'Cloture',
  mes_objectifs: 'Mes objectifs',
  mon_budget: 'Mon budget',
  activites_en_retard: 'Activites en retard',
  aucune_activite_en_retard: 'Aucune activite en retard',
  plan_action: 'Plan d’action',
  exporter_les_audits: 'Exporter les audits',
  votre_audit_a_ete_cree: 'Votre audit a été créé avec succès',
  echec_creation_audit: 'Echec lors de la création de votre audit',
  audit_cloture: 'Votre audit a été clôturé avec succès',
  echec_cloture_audit: 'Echec lors de la clôture de votre audit',
  activites_a_venir: 'Activites à venir',
  description: 'Description',
  debut: 'debut',
  fin: 'fin',
  import_fichier: 'Import Fichier',
  retour_vue_activite: 'Retour vue activite',
  validation_impossible: 'Validation impossible',
  dates_maquantes: 'des dates ne sont pas renseignées',
  responsables_manquants: 'des responsables ne sont pas renseignés',
  import_success: 'Import realise avec succes',
  creation: 'Creation',
  annuler: 'Annuler',
  creation_faite: 'Creation faite',
  echec_enregistrement: "Echec de l'enregistrement",
  edit_item: 'Edit Item',
  modification_sauvegardee: 'Modification sauvegardée',
  erreur_pendant_la_sauvegarde: 'Erreur pendant la sauvegarde',
  modification_effectuee: 'Modification effectuée',
  equipe: 'Equipe',
  nom: 'Nom',
  fermer: 'Fermer',
  cible: 'Cible',
  oui: 'Oui',
  non: 'Non',
  reponse: 'Réponse',
  la_reponse_doit_etre_de_type_numerique: 'La réponse doit être de type numérique',
  question: 'Question',
  question_pour_audit: "Question pour l'audit",
  type_de_question: 'Type de question',
  ouverte: 'Ouverte',
  fermee: 'Fermée',
  la_question_ne_peut_pas_depasser_255_caracteres: 'La question ne peut pas dépasser 255 caractères',
  ajout_paiement: 'Ajouter un paiement',
  associer_au_budget: 'Associer à un budget',
  montant: 'Montant',

};
