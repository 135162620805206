<template>
  <v-row class="d-inline-flex">
    <v-col
        v-for="(graph, index) in graphList"
        :key="`graph-${index}`"
    >
      <engagement-chart
           style="width: 550px;height: 550px;"
           class="ma-3"
            :item="graph"
           @delete="deleteChart(index)"
           @save="saveChart(index, $event)"
      ></engagement-chart>
    </v-col>
    <v-col>
    <v-card
        class="d-flex ma-3"
        style="width: 550px; height: 550px;"
    >
      <v-card-text class="align-self-center d-flex justify-center">
        <v-btn
            color="primary"
            @click="addGraph()"
        >{{ $t('donnees.engagement.ajouter_un_graphique')}}</v-btn>
      </v-card-text>
    </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import EngagementChart from './EngagementChart';

export default {
  name: 'Engagement',
  components: { EngagementChart },
  data: () => ({
    graphList: [],
  }),
  computed: {
    ...mapGetters('ratings', ['criteria']),
  },
  mounted() {
    const memory = localStorage.getItem('engagement-graph-list');
    if (memory) {
      this.graphList = JSON.parse(memory);
    }
  },
  methods: {
    saveChart(index, value) {
      this.graphList[index] = value;
      localStorage.setItem('engagement-graph-list', JSON.stringify(this.graphList));
    },
    deleteChart(index) {
      this.graphList.splice(index, 1);
      localStorage.setItem('engagement-graph-list', JSON.stringify(this.graphList));
    },
    addGraph() {
      if (this.criteria.length === 0) {
        this.$store.dispatch('ratings/LOAD_RATING');
      }
      this.graphList.push({
        criterion1: null, criterion2: null,
      });
    },
  },
};
</script>

<style scoped>

</style>
