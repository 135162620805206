const state = {
  formulaires: [],
};
const getters = {
  formulaires: (state) => state.formulaires,
  inventaires: (state) => state.formulaires.filter((f) => f.type === 'Inventaire'),
  engagement: (state) => state.formulaires.find((f) => f.type === 'Engagement'),
  displayField: (state, getter, rootState) => (id) => {
    const adm = rootState.app.administrations.find(
      (a) => a.category === 'formulaire'
        && a.parameter_name === 'display_field'
        && a.type_id === id,
    );
    return adm ? JSON.parse(adm.value) : null;
  },
  formulairesByProject: (state, getters, rootState) => state.formulaires.filter(
    (i) => i.project_id === rootState.app.selectedProject.id,
  ),
  formulairesByProjectMenu: (state, getters, rootState) => state.formulaires
    .filter((i) => i.project_id === rootState.app.selectedProject.id)
    .map((i) => ({ title: i.name, linkTo: `/inventaire/${i.id}` })),
};

const actions = {
  LOAD_ALL_FORMULAIRES(context, project) {
    return this.$axios
      .get(`/api/formulaire/all/${project.id}`)
      .then((r) => {
        context.commit('UPDATE_FORMULAIRES', r.data);
      });
  },
  UPDATE_FORMULAIRE(context, formulaire) {
    return this.$axios.put(`/api/formulaire/${formulaire.id}`, formulaire);
  },
  SET_CONNECTOR(context, { formulaire, source }) {
    return this.$axios.put(`/api/formulaire/${formulaire.id}/connector/${source.id}`)
      .then((r) => {
        context.commit('UPDATE_FORMULAIRE', r.data);
        if (!r.data.connector || !r.data.connector.enabled) throw Error('Error to set connector');
        return r.data;
      });
  },
  UNSET_CONNECTOR(context, { formulaire }) {
    return this.$axios.put(`/api/formulaire/${formulaire.id}/disable/connector`)
      .then((r) => {
        context.commit('UPDATE_FORMULAIRE', r.data);
        if (r.data.connector != null || r.data.connector.enabled) throw Error('Error to disable connector');
        return r.data;
      });
  },
  UPLOAD_XFORM(context, data) {
    const project_id = data.project_id ?? context.rootState.app.selectedProject.id;
    const { formulaireId } = data;
    return this.$axios
      .post(`/api/formulaire/import/questionnaire/${project_id}/${formulaireId}`, data.form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((r) => {
        context.commit('UPDATE_FORMULAIRE', r.data);
        return r.data;
      });
  },
  CREATE_FORMULAIRE(context, data) {
    const promise = this.$axios.put('/api/formulaire/create', data).then((r) => {
      context.commit('ADD_FORMULAIRE', r.data);
      return r.data;
    });
    if (data.form) {
      promise.then((formulaire) => {
        data.formulaireId = formulaire.id;
        context.dispatch('UPLOAD_XFORM', data);
        return formulaire;
      });
    }
    return promise;
  },
  IMPORT_SURVEYS(context, { file, formulaire_id, preview }) {
    const formData = new FormData();
    formData.append('file', file, 'import.xlsx');
    formData.append('formulaire_id', formulaire_id);
    formData.append('preview', preview);
    return this.$axios.post(
      `/api/sandbox/import/${context.rootState.app.selectedProject.id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    ).then((r) => {
      context.commit('UPDATE_FORMULAIRE', r.data.formulaire);
      return r;
    });
  },
};

const mutations = {
  UPDATE_FORMULAIRE(state, formulaire) {
    const formulaireIndex = state.formulaires.findIndex(
      (f) => f.id === formulaire.id,
    );
    state.formulaires.splice(formulaireIndex, 1, formulaire);
  },
  UPDATE_FORMULAIRES(state, formulaires) {
    state.formulaires = formulaires;
  },
  ADD_FORMULAIRE(state, formulaire) {
    state.formulaires.push(formulaire);
  },
  REMOVE_FORMULAIRE(state, formulaire) {
    const index = state.formulaires.findIndex((f) => f.id === formulaire.id);
    state.formulaires.splice(index, 1);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
