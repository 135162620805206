export const sandbox_fr = {
  nb_enquete: 'Nb enquete',
  enqueteur: 'Enqueteur',
  sandbox: 'sandbox',
  dates: 'Dates',
  annuler: 'Annuler',
  ok: 'Ok',
  exporter_donnees: 'EXPORTER DONNEES',
  exporter_kml: 'EXPORTER KML',
  export_en_cours: 'Export en cours...',
};
