const state = {
  fields: [],
};
const getters = {};

const actions = {
  LOAD_BIEN(context) {
    return this.$axios
      .get(`/api/bien/${context.rootState.app.selectedProject.id}`)
      .then((r) => r.data);
  },
};

const mutations = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
