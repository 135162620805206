<template>
  <pie :chart-data="graphData" :chart-options="mixOptions" />
</template>
<script>
import { mapState } from 'vuex';
import 'chart.js/auto';
import { Pie } from 'vue-chartjs/legacy';

export default {
  components: { Pie },
  props: {
    options: {
      type: Object,
      required: false,
    },
    source: {
      type: Object,
      required: false,
    },
    colors: {
      type: Array,
      required: false,
    },
  },
  watch: {
    source() {
      this.updateData(this.source);
    },
  },
  data: () => ({
    opt: {},
    graphData: {},
  }),
  computed: {
    ...mapState({
      defaultcolors: (state) => state.app.defaultcolor,
    }),
    mixOptions() {
      return { ...this.options, ...this.opt };
    },
  },
  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.updateData(this.source);
  },

  methods: {
    updateData(values) {
      const d1 = { data: [], labels: [], backgroundColor: [] };
      const d2 = {
        data: [], labels: [], backgroundColor: [], weight: 2,
      };
      const lbls = [];
      for (const key1 in values) {
        let sum = 0;
        for (const key2 in values[key1]) {
          lbls.push(key1);
          sum += values[key1][key2];
          d1.data.push(values[key1][key2]);
          d1.labels.push(key2);
          d1.backgroundColor.push(
            this.defaultcolors[
              d1.backgroundColor.length % this.defaultcolors.length
            ],
          );
        }
        d2.data.push(sum);
        d2.labels.push(key1);
        d2.backgroundColor.push(
          this.defaultcolors[
            d2.backgroundColor.length % this.defaultcolors.length
          ],
        );
        this.opt = {
          responsive: true,
          plugins: {
            tooltips: {
              callbacks: {
                label(tooltipItem) {
                  return `${tooltipItem.dataset.labels[tooltipItem.dataIndex]}: ${tooltipItem.formattedValue}`;
                },
              },
            },
            legend: {
              labels: {
                filter(lItem) {
                  return lbls.indexOf(lItem.text) === lItem.index;
                },
              },
              onClick(e, legendItem) {
                const ci = this.chart;
                const meta = ci.getDatasetMeta(0);
                meta.data.forEach((el) => {
                  if (el._model.label === legendItem.text) {
                    el.hidden = !el.hidden;
                  }
                });
                const meta2 = ci.getDatasetMeta(1);
                meta2.data[d2.labels.indexOf(legendItem.text)].hidden = !meta2
                  .data[d2.labels.indexOf(legendItem.text)].hidden;
                ci.update();
              },
            },
          },
        };
        this.graphData = {
          labels: lbls,
          datasets: [d1, d2],
        };
      }
    },
  },
};
</script>

<style></style>
