export const dashboard_es = {
  dashboard: 'Tablero de mandos',
  pick_range: 'Elige la gama',
  mois: 'Mes',
  trimestre: 'Quarter',
  annee: 'Año',
  inventaires: 'Inventarios',
  genres: 'Géneros',
  categories_de_pap: 'Categorías de PAP',
  perte_eco_physique: 'Eco + pérdida física',
  perte_eco: 'Pérdida ecológica',
  perte_physique: 'Pérdida física',
  autre: 'Otros',
  compensations: 'Compensación',
  engagement: 'Compromiso',
  avancement_de_la_reinstallation: 'AVANCE DE LA REINSTALACIÓN (en número de parcelas)',
  liberation_des_terres_surface: 'LIBERACIÓN DE LA TIERRA (superficie)',
  evolution_de_la_liberation_des_terres: 'EVOLUCIÓN DE LA LIBERACIÓN DE TIERRAS (superficie)',
  montant_des_pertes_et_etat_des_paiments: 'IMPORTE DE LAS PÉRDIDAS Y ESTADO DE LOS PAGOS',
  montant_des_contrats_et_etat_des_paiments: 'IMPORTE DE LOS CONTRATOS Y ESTADO DE LOS PAGOS',
  engagement_ambiance_title: 'Distribución del ambiente de las reuniones',
  engagement_ambiance_description: 'Número de reuniones por calificación de la atmósfera',
  engagement_categorie_pp_title: 'Distribución de las categorías de partes interesadas',
  engagement_categorie_pp_description: 'Número de reuniones relacionadas con una categoría de interesados',
  engagement_categorie_title: 'Distribución de las categorías de reuniones',
  engagement_categorie_description: 'Número de reuniones por categoría',
  activites: '(ES)Activites',
};
