export const budget_fr = {
  name: 'Nom du budget',
  created_at: 'Date de creation',
  amount: 'Montant',
  budget: 'Budget',
  informations: 'Informations',
  summary: 'Resume',
  paiements: 'Paiements',
  add_paiements: 'Ajouter des paiements',
  associate_paiement: 'Ajouter',
  annuler: 'Annuler',
  creer_budget: 'Creer',
  devise: 'Devise',
  total_paiements: 'Total Paiements',
  depassement_budget: 'Depassement du budget',

};
