export const partie_prenantes_en = {
  gravites: 'Gravities',
  categories: 'Categories',
  etapes: 'Steps',
  champs: 'Fields',
  nom: 'Name',
  type: 'Type',
  supprimer: 'Delete',
  creation_registre: 'Creation of a register',
  titre: 'Title',
  ok: 'OK',
  annuler: 'Cancel',
  ajouter_une_gravite: 'Adding gravity',
  gravite: 'Gravity',
  jours: 'Days',
  ajouter_une_categorie: 'Add a category',
  ajouter_une_etape: 'Add a step',
  etats: 'States',
  affichage: ' Columns',
  activer_colonnes_personanlisees: 'Enable custom columns',
  suppression_report_type_warning_message: 'Once you delete a register, there is no going back. Please be certain.',
  warning: 'Warning',
  options: 'Options',
};
