export const matrice_es = {
  bareme_de_compensation: 'Baremo de indemnizaciones',
  zone_de_prix: 'Zona de precios',
  ligne_non_valides: 'Líneas no válidas',
  importer_matrice: 'Matriz de importación',
  matrice_de_prix: 'Matriz de precios',
  selectionnez_fichier_excel: 'Seleccione un archivo Excel que contenga una definición de matriz de precios. Sólo se importarán las hojas que empiecen por los caracteres M_.',
  fichiers: 'Archivos',
  importer: 'Importar',
  code: 'Código',
  type: 'Tipo',
  libelle: 'Redacción',
  prix: 'Premios',
  unite: 'Unidad',
  recherche: 'Buscar en',
  pas_de_donnees_dans_la_matrice_de_prix: 'No hay datos en la matriz de precios',
  import_realise_avec_succes: 'Importación completada con éxito',
  erreur_lors_de_l_import: 'Se ha producido un error al importar el',
  zone: 'Zona',
};
