<template>
  <app-logged>

    <portal to="toolbarTitle">
        <v-icon color="white" large left>mdi-chart-pie</v-icon>
        {{$t('monitoring.dashboard.dashboard')}}
    </portal>

    <v-container fluid>

      <v-card tile>
        <v-toolbar
          color="primary"
          class="d-flex justify-space-between"
          dark
        >
          <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              small
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  dense
                  v-model="dateRangeText"
                  :label="dates == null ? $t('donnees.sandbox.dates') : ''"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" range no-title scrollable>
              <v-btn text color="primary" @click="menu = false">
                {{ $t('donnees.sandbox.ok') }}
              </v-btn>
              <v-btn text color="primary" @click="menu = false">
                {{ $t('donnees.sandbox.annuler') }}
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-radio-group v-model="switchMonthTriYear" row class="ml-6 mt-4">
            <v-radio :label="$t('monitoring.dashboard.mois')" value="month"></v-radio>
            <v-radio :label="$t('monitoring.dashboard.trimestre')" value="quarter"></v-radio>
            <v-radio :label="$t('monitoring.dashboard.annee')" value="year"></v-radio>
          </v-radio-group>

        </v-toolbar>

        <v-toolbar>
          <v-tabs v-model="tab">
            <v-tab to="#inventaires">{{ $t("monitoring.dashboard.inventaires") }}</v-tab>
            <v-tab
                v-if="enabledPAR"
                to="#compensations">{{ $t("monitoring.dashboard.compensations")}}</v-tab>
            <v-tab
                v-if="enabledActivite"
                to="#activites">{{ $t("monitoring.dashboard.activites")}}</v-tab>
            <v-tab
                v-if="enabledEngagement"
                to="#engagement">{{ $t("monitoring.dashboard.engagement")}}</v-tab>
            <v-tab
                v-for="reportType in report_types"
                :key="'dash-'+reportType.type"
                   :to="`#${reportType.type}`">
                   {{ reportType.type }}
          </v-tab>
          </v-tabs>
        </v-toolbar>

        <v-card-text>

          <v-tabs-items v-model="tab">
            <v-tab-item value=""></v-tab-item>
            <v-tab-item value="inventaires">
              <inventaire :switchMonthTriYear="switchMonthTriYear" />
            </v-tab-item>
            <v-tab-item value="engagement">
              <engagement />
            </v-tab-item>
            <v-tab-item
                v-for="reportType in report_types"
                :key="'dash-'+reportType.type"
                :value="reportType.type">
              <report-dashboard
                :type="reportType.type"
                :switchMonthTriYear="switchMonthTriYear"
                :dates="dates"
              />
            </v-tab-item>
            <v-tab-item value="compensations">
              <compensation :switchMonthTriYear="switchMonthTriYear" />
            </v-tab-item>
            <v-tab-item value="activites">
              <activites></activites>
            </v-tab-item>
          </v-tabs-items>

        </v-card-text>

      </v-card>
    </v-container>
  </app-logged>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Inventaire from '@/components/Dashboard/Inventaire';
import moment from 'moment';
import Compensation from '../components/Dashboard/Compensation';
import ReportDashboard from '../components/Dashboard/ReportDashboard';
import Engagement from '../components/Dashboard/Engagment';
import Activites from '../components/Dashboard/Activites';

export default {
  components: {
    Engagement,
    ReportDashboard,
    Compensation,
    Inventaire,
    Activites,
  },
  data: () => ({
    switchMonthTriYear: 'quarter',
    menu: false,
    dates: [],
    tab: '',
    parcelles: [],
  }),
  mounted() {
    this.dates = [
      moment().startOf('year').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ];
  },
  computed: {
    dateRangeText() {
      return this.dates.join('  ~  ');
    },
    ...mapState({
      selectedProject: (state) => state.app.selectedProject,
      report_types: (state) => state.report.report_types,
    }),
    ...mapGetters(['enabledPAR', 'enabledEngagement', 'enabledActivite']),
  },
};
</script>
