<template>
  <v-snackbar color="primary" bottom :value="enabledBatch" :timeout="timeoutBatch">
    <span v-if="batch">{{ batch.name }}</span>
    <v-progress-linear color="white" v-if="batch" :value="batch.progress"></v-progress-linear>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OverlayBatch',
  computed: {
    ...mapGetters(['batch', 'enabledBatch', 'timeoutBatch']),
  },
};
</script>

<style scoped>

</style>
