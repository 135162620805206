export const menu_fr = {
  operation: 'Opération',
  dashboard: 'Dashboard',
  activites: 'Activités',
  gestion_du_par: 'Gestion du PAR',
  fiches_pap: 'Fiches Individuelles',
  budget_previsionel: 'Budget du PAR',
  budget: 'Budget',
  reinstallation: 'Reinstallation',
  partie_prenantes: 'Parties Prenantes',
  monitoring: 'Monitoring',
  socio_eco: 'Analyse',
  engagement: 'Engagement',
  sandbox: 'Sandbox',
  documentation: 'Documentation',
  matrice: 'Matrice',
  formulaires: 'Formulaires',
  donnees: 'Données',
  organisation: 'Organisation',
  administration: 'Administration',
  deconnexion: 'Déconnexion',
  locale_francais: 'Français',
  locale_anglais: 'English',
  locale_espagnol: 'Español',
  engagement_pp: 'Engagement',
  add_usercomment: {
    card_title: 'Envoyez un commentaire',
    title: 'Titre',
    comment: 'Commentaire',
    category: 'Categorie',
    cancel: 'Annuler',
    ok: 'OK',
    success: 'Le commentaire a bien été enregistré',
  },
};
