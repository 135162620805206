import { i18n } from '@/main';
import Vue from 'vue';

const state = {
  reports: [],
  report_types: [],
  default_report_list_header: [
    { value: 'external_id', text: 'partie_prenantes.id' },
    { value: 'level', text: 'partie_prenantes.level' },
    { value: 'categorie', text: 'partie_prenantes.categorie' },
    { value: 'state', text: 'partie_prenantes.status' },
    { value: 'step', text: 'partie_prenantes.etape' },
    { value: 'pp', text: 'partie_prenantes.pap' },
  ],
};

const getters = {
  reportListHeader: (state, getters, rootState, rootGetters) => (type) => {
    const adm = rootGetters.administrations.find((a) => a.parameter_name === 'display_fields'
        && a.category === type);
    const value = adm ? adm.value : state.default_report_list_header;
    return value.map((a) => ({ value: a.value, text: i18n.t(a.text) }));
  },
  reportById: (state) => (id) => {
    const searchId = Number.isInteger(id) ? id : parseInt(id, 10);
    return state.reports.find((r) => r.id === searchId);
  },
  statesByReport: (state) => (type) => {
    const reportType = state.report_types.find((r) => r.type === type);
    if (!reportType) return [];
    return reportType.states;
  },
  levelsByReport: (state) => (type) => {
    const reportType = state.report_types.find((r) => r.type === type);
    if (!reportType) return [];
    return reportType.levels;
  },
  categoriesByReport: (state) => (type) => {
    const reportType = state.report_types.find((r) => r.type === type);
    if (!reportType) return [];
    return reportType.categories;
  },
  categorieNameByReport: (state, getters) => (type) => getters.categoriesByReport(type).reduce((a, b) => (((a[b.id] = b.name), a)), {}),
  levelNameByReport: (state, getters) => (type) => getters.levelsByReport(type).reduce((a, b) => (((a[b.id] = b.name), a)), {}),
  stepByReport: (state) => (type) => {
    const reportType = state.report_types.find((r) => r.type === type);
    if (!reportType) return [];
    return reportType.steps;
  },
  stateColorsByReport: (state, getters) => (type) => getters.statesByReport(type).reduce((a, b) => (((a[b.state] = b.color), a)), {}),
  stepNameByReport: (state, getters) => (type) => getters.stepByReport(type).reduce((a, b) => (((a[b.id] = b.name), a)), {}),
  reportType: (state) => (type) => state.report_types.find((r) => r.type === type),
};

const actions = {
  CREATE_REPORT(context, report) {
    return this.$axios.put('/api/report/create', { report }).then((r) => {
      context.commit('ADD_REPORT_TO_CACHE', r.data);
      return r.data;
    });
  },
  ADD_STATUS(context, data) {
    const formData = new FormData();
    if (data.files && data.files.length) {
      for (const file of data.files) {
        formData.append('files[]', file);
      }
    }
    formData.append('state', JSON.stringify(data.state));
    formData.append('reportId', data.reportId);

    return this.$axios
      .post(`/api/report/${data.reportId}/state`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((r) => context.commit('SET_REPORT', r.data));
  },
  GET_REPORT(context, id) {
    const result = context.getters.reportById(id);
    if (result) {
      return new Promise((resolve) => resolve(result));
    }
    return this.$axios
      .get(`/api/report/${id}`, {
        params: { include: ['state', 'state_history', 'state_history.documents'] },
      })
      .then((r) => {
        context.commit('ADD_REPORT_TO_CACHE', r.data);
        return r.data;
      });
  },
  UPDATE_PP(context, report) {
    let promise = null;
    if (report.pp && report.pp.id) {
      promise = this.$axios.post(
        `/api/report/${report.id}/set_pp/${report.pp.id}`,
      );
    } else {
      promise = this.$axios.post(`/api/report/${report.id}/unset_pp`);
    }
    return promise.then((r) => context.commit('SET_REPORT', r.data));
  },
  LOAD_REPORT_TYPES(context, projectId) {
    return this.$axios.get(`/api/report_type/${projectId}`)
      .then((r) => {
        context.commit('SET_REPORT_TYPES', r.data);
      });
  },
  CREATE_REPORT_TYPE(context, rtype) {
    rtype.project_id = context.rootState.app.selectedProject.id;
    return this.$axios.put('/api/report_type', rtype)
      .then((r) => {
        context.commit('ADD_REPORT_TYPE', r.data);
        return r.data;
      });
  },
  UPDATE_REPORT_TYPE(context, type) {
    this.$axios.post('/api/report_type', type).then((r) => {
      context.commit('UPDATE_REPORT_TYPE', r.data);
      return r.data;
    });
  },
  REMOVE_REPORT(context, report) {
    return this.$axios.delete(`/api/report/${report.id}`);
  },
  REMOVE_REPORT_TYPE(context, type) {
    this.$axios.delete(`/api/report_type/${type.project_id}/${type.type}`)
      .then(() => {
        context.commit('REMOVE_REPORT_TYPE', type);
      });
  },
  IMPORT_REPORTS(context, data) {
    return this.$axios.post(
      '/api/report/import',
      data.form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
  PROCESS_REPORTS(context, data) {
    return this.$axios.post(
      '/api/report/process',
      data,
    );
  },
  ADD_PAIEMENT_TO_NODE(context, { node, paiement }) {
    return this.$axios.post(
      `/api/paiements/report/${node.id}`,
      paiement,
      { params: { includes: ['state', 'state_history'] } },
    )
      .then((r) => context.commit('SET_REPORT', r.data));
  },
  UPDATE_PAIEMENT(context, { paiement }) {
    return this.$axios.put(
      `/api/paiements/${paiement.id}`,
      paiement,
      { params: { includes: ['state', 'state_history'] } },
    )
      .then((r) => context.commit('SET_REPORT', r.data));
  },
  REMOVE_PAIEMENT(context, { paiement }) {
    return this.$axios.delete(
      `/api/paiements/${paiement.id}`,
      { params: { includes: ['state', 'state_history'] } },
    )
      .then((r) => context.commit('SET_REPORT', r.data));
  },
};

const mutations = {
  ADD_REPORT_TYPE(state, rtype) {
    state.report_types.push(rtype);
  },
  SET_REPORT_TYPES(state, types) {
    state.report_types = types;
  },
  ADD_REPORT_TO_CACHE(state, report) {
    state.reports.push(report);
  },
  SET_REPORT(state, report) {
    const index = state.reports.findIndex((r) => r.id === report.id);
    if (index > -1) Vue.set(state.reports, index, report);
    else state.reports.push(report);
  },
  UPDATE_REPORT_TYPE(state, type) {
    const index = state.report_types.findIndex((r) => r.type === type.type && type.project_id === r.project_id);
    if (index > -1) state.report_types[index] = type;
    else state.report_types.push(type);
  },
  REMOVE_REPORT_TYPE(state, type) {
    state.report_types = state.report_types.filter((rt) => rt.type !== type.type);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
