export const partie_prenantes_fr = {
  nouveau_status: 'Nouveau Status',
  status: 'Status',
  etape_de_la: 'Etape de la {type}',
  commentaires: 'Commentaires',
  accepte_par_le_pap: 'Accepte par le PAP',
  fichiers: 'Fichiers',
  selectionnez_vos_fichiers: 'Selectionnez vos fichiers',
  ajouter: 'Ajouter',
  annuler: 'Annuler',
  creation_type: 'Création {type}',
  creer: 'Créer',
  type_cree: '{type} crée',
  code_doleance: 'Code Doleance',
  gravite: 'Gravité',
  categorie: 'Catégorie',
  partie_prenante: 'Partie Prenante',
  doleance_mise_a_jour: 'Doleance mise a jour',
  erreur_mise_a_jour: 'Erreur à la mise a jour',
  nouveau_status_sauvegarde: 'Nouveau status sauvegardé',
  status_non_sauvegarde: 'Status non sauvegardé',
  description: 'Description',
  liste_de_type: 'Liste des {type}',
  import_reports: 'Import reports',
  selectionnez_votre_fichier: 'Selectionnez votre fichier',
  les_definitions_suivantes_seront_ajoutees: 'Les définitions suivantes seront ajoutées',
  donnees: 'Données',
  categories: 'Catégories',
  gravites: 'Gravités',
  etapes: 'Etapes',
  importer: 'IMPORTER',
  confirmer: 'CONFIRMER',
  import_realise_avec_succes: 'Import réalisé avec succès',
  veuillez_confirmer_l_import: "Veuillez confirmer l'import",
  erreur_import_fichier: "Une erreur s'est produite lors de l'import du fichier",
  etat: 'État',
  pap: 'PAP',
  id: 'ID',
  level: 'Gravités',
  etape: 'Étape',
  lignes_par_page: 'Lignes par page',
  aucun_enregistrement_trouve: 'Aucun enregistrement trouvé',
  action: 'Action',
  erreur_durant_chargement_donnees: 'Une erreur est survenue au chargement des données',
  historique: 'Historique',
  date: 'Date',
  location: 'Lieu',
  responsable: 'Responsable',
  categorie_meeting: 'Categorie de la reunion',
  pp_categories: 'Categorie des parties prenantes',
  code: 'Code',
  ambiance: 'Ambiance de la reunion',
  save_success: 'Sauvegardé avec succès',
  undefined: 'Non renseigné',
  state_graph_title: 'Etats des {type}',
  state_graph_evol_title: 'Evolution des etats de {type}',
  categorie_graph_title: 'Catégories des {type}',
  level_graph_title: 'Gravités des {type}',
  edition: 'Edition de {type}',
  allocated_amount: 'Montant alloué',
  success_signed: 'Les documents signés ont été importés',
  failed_signed: 'Une erreur est survenue à l\'import des documents signés',
  paiement: 'Paiement',
};
