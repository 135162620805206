export const engagement_es = {
  pouvoir: 'Poder',
  pouvoir_legitime_par_position: 'Poder legítimo por su posición. Capacidad para tomar decisiones que afecten (o no) las decisiones de la empresa',
  puissance_faible: '1 - El más bajo poder',
  pouvoir_complet: '10 - Poder total y otorgado para toma de decisiones',
  flexibilite: 'Flexibilidad',
  capacite_a_etre_influence: 'Capacidad de ser influenciado por agentes externos o internos para asumir una posición específica',
  impossible_de_l_influencer: '1 - Es casi imposible de influenciar',
  totalement_influencable: '10 - Es totalmente influenciable',
  influence: 'Influencia',
  capacite_a_provoquer_changement: 'Capacidad que posee el actor en generar cambio en la actitud de otros actores',
  aucune_capacite_d_influence: '1 - No tiene capacidad de influenciar',
  capacite_d_influence_totale: '10 - Su capacidad de influencia es total',
  position: 'Posición',
  position_des_pp_vis_a_vis_de_l_organisation: 'Posición del actor frente a la organización y / o sus proyectos',
  fortement_contre: '1 - Totalmente en contra',
  fortement_en_faveur: '10 - Totalmente a favor',
  vulnerabilite: 'Vulnerabilidad',
  vulnerabilite_acteur_aux_decisions: 'Vulnerabilidad del actor frente a las decisiones de la empresa',
  moins_vulnerable: '1 - Menos vulnerable',
  plus_vulnerable: '10 - Más vulnerable',
  cooperation: 'Cooperación',
  degre_collaboration_des_pp_dans_le_developpement_du_projet: 'El grado de colaboración del grupo de interés frente al desarrollo del proyecto',
  il_n_aide_pas_du_tout: '1 - No colabora en lo absoluto',
  est_un_leader: '10 - Es un líder visible frente a los objetivos del proyecto',
  impact_acteur_vis_a_vis_de_l_entreprise: 'Impacto hacia la empresa',
  capacite_a_influencer_les_decisions: 'Capacidad de impactar decisiones de la empresa',
  ses_decisions_n_auront_aucun_impact: '1 - Sus decisiones (así tenga poder) no tendrán impacto',
  ses_decisions_sont_traduites_en_decisions_d_entreprise: '10 - Sus decisiones se trasladan a decisiones de la empresa',
  impact_entreprise_vis_a_vis_de_l_acteur: 'Impacto hacia el actor',
  capacite_a_influencer_les_decisions_des_pp_avec_les_decisions_d_entreprise: 'Capacidad de impactar con las decisiones de la empresa, las decisiones del actor',
  les_decisions_de_l_entreprise_ne_l_impactent_pas: '1 - Las decisiones de la empresa o sus proyectos no lo impactan',
  aajouter_un_graphique: 'Añadir un gráfico',
};
