export const gestion_du_par_fr = {
  choix_du_template: 'Choix du template',
  templates: 'Templates',
  parcelle_n: 'Parcelle N°',
  superficie: 'Superficie',
  compensation: 'Compensation',
  etat_contrat: 'Etat contrat',
  import_parcellaire: 'Import Parcellaire',
  import_parcellaire_explications: 'Selectionnez un fichier à importer contenant la géométrie des parcelles revues par le cartographe. Verifier la présence du champ sycosur_id permettant de faire le lien avec les enquêtes.',
  fichiers: 'Fichiers',
  selectionnez_votre_fichier: 'Selectionnez votre fichier',
  importer: 'IMPORTER',
  import_realise_avec_succes: 'Import réalisé avec succès',
  erreur_lors_de_l_import: "Une erreur s'est produite lors de l'import du fichier",
  creer_section: 'Créer Section',
  affecter_a_une_section: 'Affecter à une section',
  nommer_la_section: 'Nommer la section qui sera crée à partir des parcelles sélectionnées',
  nom_de_la_section: 'Nom de la section',
  ok: 'OK',
  annuler: 'Annuler',
  fiche_de_synthese: 'Fiche de synthese',
  fiche_de_bien: 'Fiche de bien',
  contrat: 'Contrat',
  liste_pap: 'Liste Pap',
  liste_de_paie: 'Liste de paie',
  fichier_cree: 'Fichier crée',
  erreur_creation_fichier: 'Erreur survenue pendant la création du fichier',
  afficher_le_parcellaire: 'Afficher le parcellaire',
  afficher_le_releve_mobile: 'Afficher le relevé mobile',
  afficher_le_centre: 'Afficher le centre',
  afficher_la_parcelle: 'Afficher la parcelle',
  legende: 'Légende',
  sans_section: 'Sans section',
  sans_pk: 'Sans PK',
  sections: 'sections',
  cartographie: 'cartographie',
  voir_les_parcelles_vides: 'Voir les parcelles vides',
  inventaire: 'Inventaire',
  validation: 'Validation',
  negociation: 'Negociation',
  contractualise: 'Contractualisé',
  paye: 'Payé',
  aucun: 'Aucun',
  export_parcellaire: 'Parcellaire',
  supprimer: 'Supprimer',
};
