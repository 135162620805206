<template>
  <div>
    <v-row no-gutters>
      <v-col v-for="(graph, index) in graphBien" :key="'graphB' + index">
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
          <div class="d-flex justify-center">
            <b>{{ graph.title }}</b>
          </div>
            <pie :chart-data="graph.data"></pie>
        </div>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col v-for="(graph, index) in graphPap" :key="'graphP' + index">
        <div class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
          <div class="d-flex justify-center">
            <b>{{ $t(graph.title) }}</b>
          </div>
          <pie
            :chart-data="graph.data"
            :linkUrls="graph.linkUrls"
          ></pie>
          <div
              v-if="graph.errors"
              class="mt-4"
          >
            <span style="font-style: italic; color: darkred; font-size: 0.8em">
              {{ $t(graph.errors) }}
            </span>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import 'chart.js/auto';
import { Pie } from 'vue-chartjs/legacy';

import { mapGetters, mapState } from 'vuex';
import { i18n } from '@/main';
import { addPieGraph } from '../../helper/dashboardHelper';

export default {
  components: {
    Pie,
  },

  props: {
    switchMonthTriYear: {
      type: String,
    },
  },

  data() {
    return {
      graphBien: [],
      graphPap: [],
      byState: {},
    };
  },

  watch: {
    selectedProject() {
      this.recompute();
    },
  },
  mounted() {
    this.recompute();
  },
  computed: {
    ...mapGetters('parcelle', ['stateName', 'stateColor', 'stateCumulColor']),
    ...mapState({
      colors: (state) => state.app.defaultcolor,
      selectedProject: (state) => state.app.selectedProject,
    }),
    ...mapGetters(['enabledPAR']),
  },

  methods: {
    addGraph(graphList, title, values, linkUrls) {
      const dataset = addPieGraph(values, this.colors);
      dataset.datasets[0].links = linkUrls;
      const elt = {
        title,
        data: dataset,
        linkUrls,
      };
      graphList.push(elt);
      return elt;
    },
    recompute() {
      if (this.selectedProject) {
        this.graphBien = [];
        this.graphPap = [];
        if (this.enabledPAR) {
          this.axios
            .get(`/api/dashboard/biens/${this.selectedProject.id}`)
            .then((result) => {
              for (const classeName in result.data) {
                this.addGraph(
                  this.graphBien,
                  classeName,
                  result.data[classeName],
                );
              }
            });
        }
        this.axios
          .get(`/api/dashboard/paps/byperte/${this.selectedProject.id}`)
          .then((r) => {
            const data = {};
            Object.entries(r.data).map((d) => {
              Object.defineProperty(data, i18n.t(d[0]), {
                value: d[1],
                writable: true,
                enumerable: true,
                configurable: true,
              });
            });
            this.addGraph(this.graphPap, 'monitoring.dashboard.categories_de_pap', data);
          });
        this.axios
          .get(`/api/dashboard/paps/genre/${this.selectedProject.id}`)
          .then((r) => {
            const keys = Object.keys(r.data);
            const urls = {};
            const data = {};
            let error = null;
            for (const k of keys) {
              if (k === '') {
                error = 'monitoring.dashboard.gender_not_defined';
              } else {
                data[this.$t(k)] = r.data[k].length;
                const url = {
                  path: '/pp',
                  query: { ids: r.data[k], filterName: `PAP du genre ${k}` },
                };
                urls[k] = url;
              }
            }
            const bygenre = this.addGraph(this.graphPap, 'monitoring.dashboard.genres', data, urls);
            bygenre.errors = error;
          });
      }
    },
  },
};
</script>
