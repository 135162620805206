export const dashboard_en = {
  dashboard: 'Dashboard',
  pick_range: 'Pick the range',
  mois: 'Month',
  trimestre: 'Quarter',
  annee: 'Year',
  inventaires: 'Assets Survey',
  compensations: 'Compensations',
  engagement: 'Engagement',
  liberation_des_terres_surface: 'LAND RELEASE (area)',
  avancement_de_la_reinstallation: 'RESETTLEMENT PROGRESS (in number of plots)',
  evolution_de_la_liberation_des_terres: 'EVOLUTION OF LAND RELEASE (area)',
  montant_des_contrats_et_etat_des_paiments: 'CONTRACTS AMOUNT AND PAYMENT STATUS',
  montant_des_pertes_et_etat_des_paiments: 'LOSSES AMOUNT AND PAYMENT STATUS',
  categories_de_pap: 'PAP categories',
  genres: 'GENDER',
  perte_eco_physique: 'Economic and physical loss',
  perte_eco: 'Economic Loss',
  perte_physique: 'Physical Loss',
  autre: 'Other',
  engagement_ambiance_title: 'Breakdown of the atmosphere of the meetings',
  engagement_ambiance_description: 'Number of meetings by atmosphere classification',
  engagement_categorie_pp_title: 'Breakdown of stakeholder categories',
  engagement_categorie_pp_description: 'Number of meetings related to a stakeholder category',
  engagement_categorie_title: 'Breakdown of meeting categories',
  engagement_categorie_description: 'Number of meetings per category',
  gender_not_defined: 'Some PAPs have their gender not set',
  activites: 'Activities',
};
