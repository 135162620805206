export const partie_prenantes_en = {
  nouveau_status: 'New status',
  status: 'Status',
  etape_de_la: 'Stage of the {type}',
  commentaires: 'Comments',
  accepte_par_le_pap: 'Accepted by the PAP',
  fichiers: 'Files',
  selectionnez_vos_fichiers: 'Select your files',
  ajouter: 'Add',
  annuler: 'Cancel',
  creation_type: 'Creation {type}',
  creer: 'Create',
  type_cree: '{type} creates',
  code_doleance: 'Code Doleance',
  gravite: 'Gravity',
  categorie: 'Category',
  partie_prenante: 'Stakeholder',
  doleance_mise_a_jour: 'Doleance update',
  erreur_mise_a_jour: 'Update error',
  nouveau_status_sauvegarde: 'New status saved',
  status_non_sauvegarde: 'Status not saved',
  description: 'Description',
  liste_de_type: 'List of {type}',
  import_reports: 'Import reports',
  selectionnez_votre_fichier: 'Select your file',
  les_definitions_suivantes_seront_ajoutees: 'The following definitions will be added',
  donnees: 'Data',
  categories: 'Categories',
  gravites: 'Gravities',
  etapes: 'Steps',
  importer: 'Import',
  confirmer: 'CONFIRM',
  import_realise_avec_succes: 'Import successfully completed',
  veuillez_confirmer_l_import: 'Please confirm the import',
  erreur_import_fichier: 'An error occurred while importing the file',
  etat: 'Status',
  pap: 'PAP',
  id: 'ID',
  level: 'Level',
  etape: 'Step',
  lignes_par_page: 'Lines per page',
  aucun_enregistrement_trouve: 'No records found',
  action: 'Action',
  erreur_durant_chargement_donnees: 'An error occurred while loading the data',
  historique: 'History',
  date: 'Date',
  location: 'Location',
  responsable: 'Responsable',
  categorie_meeting: 'Meeting category',
  pp_categories: 'Stakeholder categories',
  code: 'Code',
  ambiance: 'Meeting atmosphere',
  save_success: 'Creado completado',
  undefined: 'Not defined',
  state_graph_title: 'State of {type}',
  state_graph_evol_title: 'State evolution of {type}',
  categorie_graph_title: 'Categories of {type}',
  level_graph_title: 'Levels of {type}',
  edition: 'Edit {type}',
  allocated_amount: 'Allocated amount',
  success_signed: 'Signed documents  have been imported successfully',
  failed_signed: 'An error occurs while importing signed documents',
  paiement: 'Payment',
};
