import Vue from 'vue';

const state = {
  audits: {},
};

const getters = {};

const actions = {
  CREATE_AUDIT(context, node) {
    return this.$axios
      .post('api/audit', {
        node_id: node.id,
      })
      .then((r) => {
        context.commit('SET_AUDIT', r.data);
        context.dispatch('auditQuestion/GET_AUDIT_QUESTIONS', r.data.id, {
          root: true,
        });
      });
  },
  CLOSE_AUDIT(context, id) {
    return this.$axios.put(`/api/audit/${id}/close`).then((r) => {
      context.commit('UNSET_AUDIT', r.data);
      context.dispatch('auditQuestion/CLEAN_BY_AUDIT', id, { root: true });
      context.dispatch('auditResponse/CLEAN_BY_AUDIT', id, { root: true });
    });
  },
  LOAD_CURRENT_AUDITS(context) {
    if (context.rootState.app.selectedProject) {
      const projectId = context.rootState.app.selectedProject;
      return this.$axios
        .get(`/api/audit/current/project/${projectId.id}`)
        .then((r) => {
          r.data.forEach((audit) => {
            context.commit('SET_AUDIT', audit);
            context
              .dispatch('auditQuestion/GET_AUDIT_QUESTIONS', audit.id, {
                root: true,
              })
              .then(() => {
                context.dispatch(
                  'auditResponse/GET_AUDIT_RESPONSES',
                  audit.id,
                  {
                    root: true,
                  },
                );
              });
          });
        });
    }
  },
};

const mutations = {
  SET_AUDIT(state, audit) {
    const entries = new Map([[audit.node_id, audit]]);
    const entry = Object.fromEntries(entries);
    state.audits = { ...state.audits, ...entry };
  },
  UNSET_AUDIT(state, audit) {
    Vue.delete(state.audits, audit.node_id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
