<template>
  <v-row no-gutters class="d-flex justify-space-between">
    <v-card flat class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
      <v-card-title class="label-title">{{ $t('monitoring.dashboard.engagement_ambiance_title') }}</v-card-title>
      <v-card-subtitle class="label-info"><i></i>{{ $t('monitoring.dashboard.engagement_ambiance_description') }}</v-card-subtitle>
      <pie :chart-data="chartAmbiance"></pie>
    </v-card>
    <v-card flat class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
      <v-card-title class="label-title">{{ $t('monitoring.dashboard.engagement_categorie_pp_title')}}</v-card-title>
      <v-card-subtitle class="label-info"><i>{{ $t('monitoring.dashboard.engagement_categorie_pp_description')}}</i></v-card-subtitle>
      <bar :chart-data="chartPP" :chart-options="chartPP.options"></bar>
    </v-card>
    <v-card flat class="flex-grow-1 pa-3 ma-3" style="max-width: 450px">
      <v-card-title class="label-title">{{ $t('monitoring.dashboard.engagement_categorie_title')}}</v-card-title>
      <v-card-subtitle class="label-info"><i>{{ $t('monitoring.dashboard.engagement_categorie_description')}}</i></v-card-subtitle>
      <bar :chart-data="chartCat" :chart-options="chartCat.options"></bar>
    </v-card>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import 'chart.js/auto';
import { Bar, Pie } from 'vue-chartjs/legacy';
import { orderBy } from 'lodash';

export default {
  name: 'Engagment',
  components: {
    Bar,
    Pie,
  },
  mounted() {
    this.$store.dispatch('engagment/LOAD_ENGAGMENT');
  },
  watch: {
    items() {
      this.create();
    },
  },
  data: () => ({
    chartPP: {},
    chartCat: {},
    chartAmbiance: {},
  }),
  computed: {
    ...mapState({
      selectedProject: (state) => state.app.selectedProject,
      items: (state) => state.engagment.engagments,
      defaultcolor: (state) => state.app.defaultcolor,
    }),
    ...mapGetters({
      ppCategories: 'engagment/ppCategories',
      categories: 'engagment/categories',
      ambiances: 'engagment/ambiances',
      ambianceColors: 'engagment/ambianceColors',
    }),
  },
  methods: {
    create() {
      let keys = this.ppCategories.reduce((a, b) => (((a[b.id] = { id: b.id, value: 0 }), a)), {});
      let byAmbiance = this.ambiances.reduce((a, b) => (((a[b.id] = { id: b.id, value: 0 }), a)), {});
      let byCategories = this.categories.reduce((a, b) => (((a[b.id] = { id: b.id, value: 0 }), a)), {});

      for (const item of this.items) {
        byAmbiance[item.ambience].value += 1;
        byCategories[item.meeting_category].value += 1;
        for (const cat of item.pp_categories) {
          keys[cat].value += 1;
        }
      }
      keys = orderBy(keys, 'value', 'desc');
      this.chartPP = {
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        labels: keys.map((k) => k.id),
        datasets: [
          {
            data: keys.map((k) => k.value),
            backgroundColor: this.defaultcolor,
          },
        ],
      };
      byAmbiance = Object.values(byAmbiance);
      this.chartAmbiance = {
        labels: byAmbiance.map((a) => a.id),
        datasets: [
          {
            data: byAmbiance.map((a) => a.value),
            backgroundColor: (item) => this.ambianceColors[byAmbiance[item.index].id],
          },
        ],
      };
      byCategories = Object.values(byCategories);
      this.chartCat = {
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        labels: byCategories.map((a) => a.id),
        datasets: [
          {
            data: byCategories.map((a) => a.value),
            backgroundColor: this.defaultcolor,
          },
        ],
      };
    },

  },
};
</script>

<style scoped>

</style>
