const state = {
  engagments: [],
  ppCategories: [
    { id: 'Communautés', name: 'Communautés', color: '#3853A1' },
    { id: 'Collectivités territoriales', name: 'Collectivités territoriales', color: '#28A179' },
    { id: 'Etat central', name: 'Etat central', color: '#ED7B5F' },
    { id: 'Etat deconcentré', name: 'Etat deconcentré', color: '#EDB82F' },
    { id: 'ONG', name: 'ONG', color: '#114654' },
    { id: 'Religieux', name: 'Religieux', color: '#5FCEED' },
    { id: 'Coutumiers', name: 'Coutumiers', color: '#be90ef' },
    { id: 'Entreprises', name: 'Entreprises', color: '#f280d7' },
  ],
  ambiances: [
    { id: 'Constructive', name: 'Constructive', color: '#2cba00aa' },
    { id: 'Neutre', name: 'Neutre', color: '#a3ff00aa' },
    { id: 'Tendue', name: 'Tendue', color: '#ffa700aa' },
    { id: 'Bloquée', name: 'Bloquée', color: '#ff0000aa' },
  ],
  categories: [
    { id: 'Information', name: 'Information' },
    { id: 'Sensibilisation', name: 'Sensibilisation' },
    { id: 'Consultation', name: 'Consultation' },
    { id: 'Négociation', name: 'Négociation' },
    { id: 'Concertation', name: 'Concertation' },
  ],
};

const getters = {
  ambiances: (state) => state.ambiances,
  ppCategories: (state) => state.ppCategories,
  categories: (state) => state.categories,
  ambianceColors: (state) => state.ambiances.reduce((a, b) => (((a[b.id] = b.color), a)), {}),
  ppCategoriesColors: (state) => state.ppCategories.reduce((a, b) => (((a[b.id] = b.color), a)), {}),
  engagmentByCode: (state) => (code) => state.engagments.find((e) => e.data.code === code),
};

const actions = {
  LOAD_ENGAGMENT(context, params) {
    this.$axios.get('/api/engagment/all', { params: params })
      .then((r) => context.commit('SET_ENGAGMENTS', r.data.data));
  },
  GET_ENGAGMENT(context, code) {
    const result = context.state.engagments.find((r) => r.data.code === code);
    if (result) {
      return new Promise((resolve) => resolve(result));
    }
    return this.$axios
      .get(`/api/engagment/${code}`)
      .then((r) => {
        context.commit('ADD_ENGAGMENT', r.data);
        return r.data;
      });
  },
  CREATE(context, formData) {
    return this.$axios.post(
      '/api/engagment/create',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },
};

const mutations = {
  SET_ENGAGMENTS(state, values) {
    state.engagments = values;
  },
  ADD_ENGAGMENT(state, engagment) {
    state.engagments.push(engagment);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
