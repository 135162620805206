export const engagement_fr = {
  pouvoir: 'Pouvoir',
  pouvoir_legitime_par_position: "Pouvoir légitime par position. Capacité à prendre des décisions qui affectent (ou non) les décisions de l'entreprise",
  puissance_faible: '1 - La puissance la plus faible',
  pouvoir_complet: '10 - Un pouvoir décisionnel complet et dévolu',
  flexibilite: 'Flexibilité',
  capacite_a_etre_influence: 'Capacité à être influencé par des agents externes ou internes pour assumer une position spécifique',
  impossible_de_l_influencer: "1 - Il est presque impossible de l'influencer",
  totalement_influencable: '10 - Est totalement influençable',
  influence: 'Influence',
  capacite_a_provoquer_changement: "Capacité de l'acteur à provoquer un changement dans l'attitude des autres acteurs",
  aucune_capacite_d_influence: "1 - Aucune capacité d'influence",
  capacite_d_influence_totale: "10 - Sa capacité d'influence est totale",
  position: 'Position',
  position_des_pp_vis_a_vis_de_l_organisation: "Position des parties prenantes vis-à-vis de l'organisation et/ou de ses projets",
  fortement_contre: '1 - Fortement contre',
  fortement_en_faveur: '10 - Fortement en faveur',
  vulnerabilite: 'Vulnéralité',
  vulnerabilite_acteur_aux_decisions: "Vulnérabilité de l'acteur aux décisions de l'entreprise",
  moins_vulnerable: '1 - Moins vulnérable',
  plus_vulnerable: '10 - Plus vulnérable',
  cooperation: 'Coopération',
  degre_collaboration_des_pp_dans_le_developpement_du_projet: 'Le degré de collaboration des parties prenantes dans le développement du projet',
  il_n_aide_pas_du_tout: "1 - Il n'aide pas du tout",
  est_un_leader: '10 - Est un leader visible par rapport aux objectifs du projet',
  impact_acteur_vis_a_vis_de_l_entreprise: "Impact de l'acteur vis-à-vis de l'entreprise",
  capacite_a_influencer_les_decisions: 'Capacité à influencer les décisions commerciales',
  ses_decisions_n_auront_aucun_impact: "1 - Ses décisions (même si elle a du pouvoir) n'auront aucun impact",
  ses_decisions_sont_traduites_en_decisions_d_entreprise: "10 - Ses décisions sont traduites en décisions d'entreprise",
  impact_entreprise_vis_a_vis_de_l_acteur: "Impact de l'entreprise vis-à-vis de l'acteur",
  capacite_a_influencer_les_decisions_des_pp_avec_les_decisions_d_entreprise: "Capacité à influencer les décisions des parties prenantes avec les décisions de l'entreprise",
  les_decisions_de_l_entreprise_ne_l_impactent_pas: "1 - Les décisions de l'entreprise ou les projets ne l'impactent pas",
  ajouter_un_graphique: 'Ajouter un graphique',
};
