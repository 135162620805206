const state = {
  auditQuestions: [],
};

const getters = {};

const actions = {
  CREATE_AUDIT_QUESTION(context, data) {
    return this.$axios.post('api/audit-questions', data);
  },
  GET_AUDIT_QUESTIONS(context, auditId) {
    return this.$axios.get(`/api/audit-questions/audit/${auditId}`).then((r) => {
      r.data.forEach((auditQuestion) => {
        context.commit('SET_AUDIT_QUESTION', auditQuestion);
      });
    });
  },
  UPDATE_AUDIT_QUESTION(context, auditQuestion) {
    return this.$axios.put(
      `/api/audit-questions/${auditQuestion.id}`,
      auditQuestion,
    );
  },
  GET_AUDIT_QUESTION_BY_NODE(context, nodeId) {
    return this.$axios.get(`/api/audit-questions/node/${nodeId}`);
  },
  CLEAN_BY_AUDIT(context, auditId) {
    context.commit('CLEAN_BY_AUDIT', auditId);
  },
};

const mutations = {
  SET_AUDIT_QUESTION(state, auditQuestion) {
    const entries = new Map([[auditQuestion.node_id, auditQuestion]]);
    const entry = Object.fromEntries(entries);
    state.auditQuestions = { ...state.auditQuestions, ...entry };
  },
  CLEAN_BY_AUDIT(state, auditId) {
    let auditQuestionsAsArray = Object.entries(state.auditQuestions);
    auditQuestionsAsArray = auditQuestionsAsArray.filter((element) => element[1].audit_id !== auditId);
    state.auditQuestions = Object.fromEntries(auditQuestionsAsArray);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
