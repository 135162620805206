export const partie_prenantes_es = {
  creer: 'Crear',
  creation_type: 'Creación',
  gravite: 'Gravedad',
  categorie: 'Categoría',
  importer: 'Importar',
  etat: 'Estatus',
  categories: 'Categorías',
  gravites: 'Gravedades',
  description: 'Descripción',
  historique: 'Historia',
  nouveau_status: 'Nueva situación',
  etape_de_la: 'Etapa del {type}',
  commentaires: 'Comentarios',
  accepte_par_le_pap: 'Aceptado por el PAP',
  fichiers: 'Archivos',
  ajouter: 'Añadir',
  annuler: 'Cancelar',
  status: 'Estatus',
  selectionnez_vos_fichiers: 'Seleccione sus archivos',
  type_cree: '{type} crea',
  code_doleance: 'Código Doleance',
  partie_prenante: 'Partes interesadas',
  doleance_mise_a_jour: 'Actualización de Doleance',
  erreur_mise_a_jour: 'Error de actualización',
  nouveau_status_sauvegarde: 'Nuevo estado guardado',
  status_non_sauvegarde: 'Estado no guardado',
  import_reports: 'Importar informes',
  selectionnez_votre_fichier: 'Seleccione su archivo',
  les_definitions_suivantes_seront_ajoutees: 'Se añadirán las siguientes definiciones',
  donnees: 'Datos',
  etapes: 'Pasos',
  confirmer: 'CONFIRMAR',
  import_realise_avec_succes: 'Importación completada con éxito',
  veuillez_confirmer_l_import: 'Por favor, confirme la importación',
  erreur_import_fichier: 'Se ha producido un error al importar el',
  pap: 'PAP',
  id: 'ID',
  level: 'Nivel',
  etape: 'Paso',
  lignes_par_page: 'Líneas por página',
  aucun_enregistrement_trouve: 'No se han encontrado registros',
  action: 'Acción',
  erreur_durant_chargement_donnees: 'Se ha producido un error al cargar los datos',
  liste_de_type: 'Lista de {type}',
  date: 'Fecha',
  location: 'Ubicación',
  responsable: 'Responsable',
  categorie_meeting: 'Categoría de la reunión',
  pp_categories: 'Categoria del partes interesadas',
  code: 'Code',
  ambiance: 'Atmósfera de la reunión',
  undefined: 'No definida',
  state_graph_title: 'Estatus del {type}',
  state_graph_evol_title: 'Evolución des estatus del {type}',
  categorie_graph_title: 'Categorias del {type}',
  level_graph_title: 'Nivel del {type}',
  edition: '(ES)Edition de {type}',
  allocated_amount: '(ES)Montant alloué',
  success_signed: '(ES)Les documents signés ont été importés',
  failed_signed: '(ES)Une erreur est survenue à l\'import des documents signés',
  paiement: '(ES)Paiement',
};
