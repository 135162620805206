export const menu_en = {
  operation: 'Operation',
  dashboard: 'Dashboard',
  activites: 'Activities',
  gestion_du_par: 'RAP management',
  fiches_pap: 'Individual Sheets',
  budget_previsionel: 'RAP Budget',
  budget: 'Budget',
  reinstallation: 'Relocation',
  partie_prenantes: 'Stakeholders',
  monitoring: 'Monitoring',
  socio_eco: 'Analysis',
  engagement: 'Engagement',
  sandbox: 'Sandbox',
  documentation: 'Documentation',
  matrice: 'Matrix',
  formulaires: 'Forms',
  donnees: 'Data',
  organisation: 'Organization',
  administration: 'Administration',
  deconnexion: 'Disconnection',
  locale_francais: 'Français',
  locale_anglais: 'English',
  locale_espagnol: 'Español',
  engagement_pp: 'Engagement',
  add_usercomment: {
    card_title: 'Send a comment',
    title: 'Title',
    comment: 'Comment',
    category: 'Category',
    ok: 'OK',
    cancel: 'Cancel',
    success: 'The message has been successfully recorded',
  },
};
