export const budget_es = {
  name: '(ES)Nom du budget',
  created_at: '(ES)Date de creation',
  amount: '(ES)Montant',
  budget: '(ES)Budget',
  informations: '(ES)Informations',
  summary: '(ES)Resume',
  paiements: '(ES)Paiements',
  add_paiements: '(ES)Ajouter des paiements',
  associate_paiement: '(ES)Ajouter',
  annuler: '(ES)Annuler',
  creer_budget: '(ES)Creer',
  devise: '(ES)Devise',
  total_paiements: '(ES)Total Paiements',
  depassement_budget: '(ES)Depassement du budget',
};
