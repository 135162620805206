export const gestion_du_par_es = {
  sections: 'Secciones',
  voir_les_parcelles_vides: 'Ver las parcelas vacías',
  sans_section: 'Sin sección',
  sans_pk: 'Sin PK',
  cartographie: 'Cartografía',
  afficher_le_parcellaire: 'Mostrar el paquete',
  afficher_le_releve_mobile: 'Ver el extracto del móvil',
  afficher_le_centre: 'Ver el centro',
  afficher_la_parcelle: 'Visualizar la parcela',
  legende: 'Leyenda',
  inventaire: 'Inventario',
  validation: 'Validación',
  negociation: 'Negociación',
  contractualise: 'Contratado',
  paye: 'Pagado',
  aucun: 'No',
  parcelle_n: 'Parcela nº.',
  superficie: 'Área',
  compensation: 'Compensación',
  creer_section: 'Crear sección',
  import_parcellaire: 'Importación de paquetes',
  fiche_de_synthese: 'Hoja de resumen',
  liste_pap: 'Lista PAP',
  fiche_de_bien: 'Ficha de la propiedad',
  choix_du_template: 'Elección de la plantilla',
  contrat: 'Contrato',
  fichier_cree: 'Archivo creado',
  erreur_creation_fichier: 'Error durante la creación del archivo',
  templates: 'Plantillas',
  etat_contrat: 'Contrato estatal',
  import_parcellaire_explications: 'Seleccione un archivo para importar que contenga la geometría de las parcelas revisadas por el cartógrafo. Compruebe la presencia del campo sycosur_id para realizar el enlace con las encuestas.',
  fichiers: 'Archivos',
  selectionnez_votre_fichier: 'Seleccione su archivo',
  importer: 'IMPORTAR',
  erreur_lors_de_l_import: 'Se ha producido un error al importar el',
  affecter_a_une_section: 'Asignar a una sección',
  nommer_la_section: 'Nombre de la sección que se creará a partir de las parcelas seleccionadas',
  ok: 'Ok',
  annuler: 'Cancelar',
  nom_de_la_section: 'Nombre de la sección',
  import_realise_avec_succes: 'Importación completada con éxito',
  liste_de_paie: 'Nómina',
  export_parcellaire: 'Parcelas',
  supprimer: 'Borrar',
};
