<template>
<v-dialog
  v-model="show"
  max-width="600px"
  persistents
  >
  <template v-slot:activator="{ on, attrs }">
    <v-btn
        v-bind="attrs"
        v-on="on"
        tile
        dark
        color="secondary"
        elevation="0"
        class="mr-0 white--text"
        height="64"
        @click="show = true"
    >
      <v-icon>
        mdi-help-circle-outline
      </v-icon>
    </v-btn>
  </template>
  <v-card>
    <v-toolbar color="secondary" dark dense class="font-weight-bold">
      {{ $t('menu.add_usercomment.card_title')}}
    </v-toolbar>
    <v-card-text>
      <v-form>
        <v-select
            :label="$t('menu.add_usercomment.category')"
            v-model="uc.category"
            :items="categories"
        ></v-select>
        <v-text-field :label="$t('menu.add_usercomment.title')" v-model="uc.title"></v-text-field>
       <v-textarea :label="$t('menu.add_usercomment.comment')" v-model="uc.comment"></v-textarea>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
          color="primary"
          tile
          elevation="0"
          @click="send">{{$t('menu.add_usercomment.ok')}}
      </v-btn>

      <v-btn
          color="error"
          tile
          elevation="0"
          @click="close">{{$t('menu.add_usercomment.cancel')}}</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
import { i18n } from '@/main';
import { mapState } from 'vuex';

export default {
  name: 'AddUsercomment',
  data: () => ({
    show: false,
    uc: { comment: '', category: '', title: '' },
  }),
  computed: {
    ...mapState({
      menuList: (state) => state.app.menu,
    }),
    categories() {
      const result = [];
      for (const menu of this.menuList) {
        result.push({ id: menu.label, text: `==== ${i18n.t(menu.label)}` });
        for (const submenu of menu.mods) {
          result.push({ id: submenu.label, text: i18n.t(submenu.label) });
        }
      }
      return result;
    },
  },
  methods: {
    send() {
      this.$store.dispatch('SEND_USER_COMMENT', this.uc)
        .then(() => this.$store.dispatch('FIRE_SUCCESS', i18n.t('menu.add_usercomment.success')))
        .then(() => this.close());
    },
    close() {
      this.show = false;
      this.uc = { comment: '', title: '' };
    },
  },
};
</script>

<style scoped>

</style>
