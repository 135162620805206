export const sandbox_en = {
  nb_enquete: 'No. of surveys',
  enqueteur: 'Investigator',
  sandbox: 'Sandbox',
  dates: 'Dates',
  annuler: 'Cancel',
  ok: 'Ok',
  exporter_donnees: 'Export data',
  exporter_kml: 'Export KML',
  export_en_cours: 'Export in progress...',
};
