export const formulaires_es = {
  inventaires: 'Inventarios',
  importer_donnees: 'Importar datos',
  importer_questionnaire: 'Importar el cuestionario',
  impossible_ouvrir_nouveau_formulaire: 'No se puede abrir un nuevo formulario, se ha producido un error desconocido',
  import_enquetes: 'Importación de encuestas',
  selectionnez_fichier_enquetes: 'Seleccione un archivo que contenga encuestas',
  celles_ci_seront: 'Estos serán',
  ajoutee_si_non_existante: 'añadido si no existe',
  mise_a_jour_si_deja_presente: 'actualizar si ya está presente',
  fichiers: 'Archivos',
  selectionnez_votre_fichier: 'Seleccione su archivo',
  import_termine: 'Importación completada',
  erreur_import_fichier: 'Se ha producido un error al importar el',
  selectionnez_fichier_questionnaire: 'Seleccione el archivo que contiene el cuestionario a importar',
  attention_nommage: 'Tenga en cuenta que cambiar la denominación de las variables y de ciertos campos puede alterar la interpretación de los datos',
  telecharger_xform: 'Descargar Xform',
  preview: '(ES)Previsualisation',
  import: '(ES)Import',

};
