export const socio_eco_fr = {
  engagement: 'Engagement',
  socio_eco: 'Analyse',
  partie_prenante: 'Partie Prenante',
  categorie: 'Catégorie',
  question: 'Question',
  response: 'Réponse',
  ajouter_un_graphique: 'Ajouter un graphique',
  question_principale: 'question principale',
  question_secondaire: 'question secondaire',
  isvb: 'ISVB',
  graphiques_personnalises: 'Graphiques personnalisés',
  indicateurs_cles: 'Indicateurs Clés',
  bar_chart: 'Bar chart',
  pie_chart: 'Pie chart',
  tableau: 'Tableau',
  import_realise_avec_success: 'Import réalisé avec succès',
  erreur_lors_de_l_import: "Une erreur s'est produite lors de l'import du fichier",
  selectionnez_fichier_excel: 'Selectionnez un fichier excel contenant les parametres necéssaire à la socio-eco',
  importer: 'Importer',
  pyramide_age_title: 'Pyramide des ages',
  pyramide_age_description: 'Age et genres des individus',
  pyramide_age_explanation: 'La pyramide des âges représente la répartition par âge des membres des ménages affectés par le projet. Les barres bleues représentent le nombre d’hommes de chaque tranche d’âge (0 à 5 ans, 5 à 15 ans, 15 à 25 ans, etc.) et les barres rouges représentent le nombre de femmes de chaque tranche d’âge.',
  rdd_title: 'Rapport de Dépendance Demographique',
  rdd_description: "Ratio du nombre d'individus  < 15 ans & > 64 ans sur le nombre d'individus ayant entre 15 et 64 ans",
  rdd_explanation: 'Le RDD représente la proportion de membres des ménages affectés par le projet dépendants car trop jeunes ou  trop  vieux  pour  travailler,  par  rapport  à  la  proportion  de  membres  en  âge  de  travailler.  Plus  le  RDD  est faible, moins il y a de personnes dépendantes. Ainsi un RDD de 0 signifie que l’ensemble des membres du ménage sont en âge de travailler et un RDD de 1 signifie que l’ensemble des membres du ménages sont trop jeunes ou trop vieux pour travailler.',
  rde_title: 'Rapport de Dependance Economique',
  rde_description: "Ratio du nombre d'individus inactifs sur le nombre d'individus actifs",
  rde_explanation: 'Le RDE représente la proportion de membres des ménages affectés par le projet ne travaillant pas, par rapport à la proportion de membres qui travaillent. Plus le RDD est faible, moins il y a de personnes inactives. Ainsi un  RDD  de  0  signifie  que  l’ensemble  des  membres  du  ménage  travaillent  et  un  RDD  de  1  signifie  que l’ensemble des membres du ménages ne travaille pas.',
  rcsi_title: 'Reduced Coping Score Index',
  rcsi_description: 'Indicateur synthetique reprenant 5 indicateurs génériques de vulnérabilité alimentaire',
  rcsi_explanation: 'Le rCSI est un indicateur développé par l’USAID pour traduire l’insécurité alimentaire des ménages affectés par le projet. Plus le rCSI est fort, plus l’insécurité alimentaire du ménage est importante (avec un maximum de 56).',
  oxford_title: 'Indicateur Oxford',
  oxford_description: "Répartition des menage par tranches d'unités de consomation",
  oxford_explanation: 'L’indicateur  Oxford  (ou  unité  de  consommation  Oxford)  est  une  modélisation  des  ménages  affectés  par  le projet en fonction de l’âge des membres du ménage et de leur consommation. Le premier membre du ménage est compté comme 1 unité, les membres de 14 ans et plus suivants sont comptés comme 0.5 et les membres de moins de 14 ans sont comptés comme 0.3 unités.',
  isvb_title: 'Indice Synthétique de Vulnérabilité',
  isvb_description: 'Score de vulnérabilité des ménages (élaboré à partir de la méthode Alkire et Foster pour le PNUD)',
  isvb_explanation: 'L’ISV est un indicateur tiré de la méthode Alkire Foster de mesure multidimensionnelle de la « vulnérabilité à la pauvreté ». Cet indicateur s’appuie sur l’évaluation de l’éducation, la santé, les conditions de vie matérielles et les moyens de subsistance des ménages affectés par le projet pour traduire leur vulnérabilité. Plus l’ISV est proche de 0, moins il est vulnérable et inversement.',
  emploi_femmes_title: "Taux d'emplois des femmes dans le menage",
  emploi_femmes_description: 'Part des femmes en age de travailler et ayant une activité génératrice de revenu',
  emploi_femmes_explanation: 'Le taux d’emplois des femmes représente la part des femmes ayant une activité dans les ménages affectés par le projet. Plus il est proche de 0, moins les femmes du ménage travaillent et inversement.',

};
